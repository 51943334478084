import Dexie, { Table } from "dexie";

export interface KanjiVGSVG {
  id: string;
  kid: string;
  kanji: string;
  svg: string;
}

export interface Dictionary {
  id?: number;
  common: boolean;
  expression: string;
  hiragana: string;
  reading: string;
  gems: string[];
  kanjis: string[];
  senses: object[];
}

export interface Vocabulary {
  id?: number;
  expression: string;
  reading: string;
  meaning: string;
  tags: string[];
}

export interface Sentence {
  id?: number;
  expression: string;
  reading: string;
  meaning: string;
  cloze: string;
  words: string[];
  readings: string[];
  type: string;
}

export class KanjiSamaDatabase extends Dexie {
  kanjis!: Table<KanjiVGSVG>;
  dictionary!: Table<Dictionary>;
  vocabulary!: Table<Vocabulary>;
  sentences!: Table<Sentence>;

  constructor() {
    super("KanjiSamaDatabase");

    this.version(1).stores({
      kanjis: "id, kid, kanji, svg",
      dictionary: "++id, expression, hiragana, reading, common, *gems, *kanjis",
      vocabulary: "++id, expression, reading, meaning, *tags",
      sentences:
        "++id, expression, reading, meaning, cloze, type, *words, *readings",
    });
  }
}

export const db = new KanjiSamaDatabase();
