import {
  Card,
  createStyles,
  Divider,
  Group,
  ScrollArea,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { vocabulary } from "framework/data/loader";
import {
  DictionaryWord,
  DictionaryWordSense,
} from "framework/types/dictionary";
import _ from "lodash";
import React, { FC } from "react";
import { Link } from "react-router-dom";

const POS_INDEX = vocabulary.jmdict.pos;

const useStyles = createStyles((theme) => ({
  word: {
    boxShadow: theme.shadows.xs,
    border: "1px solid",
    borderRadius: theme.radius.md,
    borderColor: theme.fn.rgba(theme.colors.violet[1], 0.75),
    backgroundColor: theme.fn.rgba(theme.colors.violet[0], 0.2),
  },
  expression: {
    fontSize: 22,
    marginBottom: -4,
    fontFamily: "heisei-maru-gothic-std",
    color: theme.colors.violet[8],
    textShadow: theme.shadows.xs,
  },
  hiragana: {
    fontSize: 14,
    marginLeft: 5,
    fontWeight: "bold",
    fontFamily: "a-otf-ud-shin-maru-go-pr6n",
    color: theme.colors.gray[7],
    textShadow: theme.shadows.xs,
  },
  pos: {
    fontSize: 10,
    color: theme.colors.gray[5],
    marginBottom: -6,
  },
  meanings: {
    fontSize: 12,
    fontWeight: "bold",
    color: theme.colors.gray[6],
  },
}));

interface SenseProps extends DictionaryWordSense {}

const Sense = ({ meanings, pos }: SenseProps) => {
  const { classes } = useStyles();

  const meaningsString = meanings.join("; ");
  const posString = _.chain(pos)
    .map((x) => _.get(POS_INDEX, x, ""))
    .compact()
    .join(", ")
    .value();

  return (
    <Group>
      <SimpleGrid spacing={5}>
        <Text className={classes.pos}>{posString}</Text>
        <Text className={classes.meanings}>{meaningsString}</Text>
      </SimpleGrid>
    </Group>
  );
};

type WordCardProps = {
  word: DictionaryWord;
  maxMeanings?: number;
  noLink?: boolean; // Cannot use Link in a modal
};

const WordCard: FC<WordCardProps> = ({
  word,
  maxMeanings,
  noLink,
  children,
}) => {
  const { classes } = useStyles();
  const senses = maxMeanings ? _.take(word.senses, maxMeanings) : word.senses;

  return (
    <Card className={classes.word} p={0}>
      <Card.Section mb={-10}>
        <Group spacing={2} p={10}>
          {noLink && (
            <Text className={classes.expression}>{word.expression}</Text>
          )}
          {!noLink && (
            <Text
              component={Link}
              to={`/jisho/word/${word.reading}`}
              className={classes.expression}
            >
              {word.expression}
            </Text>
          )}
          <Text className={classes.hiragana}>[{word.hiragana}]</Text>
        </Group>
      </Card.Section>
      <Divider mx={0} my={5} />
      <Card.Section>
        <ScrollArea
          style={{ height: 100 }}
          offsetScrollbars
          scrollbarSize={5}
          scrollHideDelay={100}
        >
          <SimpleGrid p={10} spacing="xs">
            {senses.map((x, idx) => (
              <Sense key={idx} {...x} />
            ))}
          </SimpleGrid>
        </ScrollArea>
      </Card.Section>
      {children && (
        <>
          <Divider mx={0} my={5} />
          <Card.Section>{children}</Card.Section>
        </>
      )}
    </Card>
  );
};

export default WordCard;
