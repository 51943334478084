import {
  Blockquote,
  Box,
  Button,
  Center,
  Container,
  Group,
  Stack,
  Text,
} from "@mantine/core";
import { IconEye, IconRefresh } from "@tabler/icons";
import TokenSentence from "components/sentence/TokenSentence";
import KanjiTile from "components/tile/KanjiTile";
import VocabularyBar from "components/vocabulary/VocabularyBar";
import WordCard from "components/vocabulary/WordCard";
import { DictionaryWord } from "framework/types/dictionary";
import { useAtomValue } from "jotai";
import { extractKanjiReadings } from "libs/japanese";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { db, Sentence } from "state/dexie/db";
import knowledgeAtom from "state/jotai/knowledge";
import jlptLevelsAtom from "state/jotai/transient/jlpt-level";

const SentenceGym = () => {
  const jlptLevels = useAtomValue(jlptLevelsAtom);
  const knowledge = useAtomValue(knowledgeAtom);

  const [searchParams, setSearchParams] = useSearchParams();
  const [reveal, setReveal] = useState(true);
  const [sentence, setSentence] = useState<Sentence>();
  const [sentences, setSentences] = useState<Sentence[]>([]);
  const [words, setWords] = useState<DictionaryWord[]>([]);
  const [kanjis, setKanjis] = useState<string[]>([]);

  const sentenceId = useMemo(() => {
    return _.toSafeInteger(searchParams.get("sentenceId"));
  }, [searchParams]);

  // Setup all the sentences based on the levels selected
  useEffect(() => {
    (async () => {
      const vocabulary = await db.vocabulary
        .where("tags")
        .anyOf(jlptLevels || ["n5"])
        .toArray();

      const readings = _.chain(vocabulary).map("reading").value();

      const results = await db.sentences
        .where("type")
        .anyOf(["reading", "cloze"])
        .and((x) => !_.isEmpty(x.readings))
        .and((x) => _.isEmpty(_.difference(x.readings, readings)))
        .toArray();

      setSentences(results);
    })();
  }, [jlptLevels]);

  const getWords = useCallback(
    (s: Sentence, words: string[]) => {
      return (async () => {
        const knowledgeWords = knowledge?.words || [];

        const dictionaryWords = await Promise.all(
          _.chain(s.readings)
            .filter((x) => !knowledgeWords.includes(x))
            .map((x) => {
              return db.dictionary
                .where({ reading: x })
                .and((x) => x.common)
                .toArray();
            })
            .value()
        );

        return _.chain(dictionaryWords)
          .flatten()
          .map((x) => x as DictionaryWord)
          .value();
      })();
    },
    [knowledge?.words]
  );

  const showSentence = useCallback(
    (id: number) => {
      (async () => {
        setReveal(true);

        const result = await db.sentences.get({ id: id });
        if (result) {
          const sentenceWords = await getWords(result, knowledge?.words || []);
          setKanjis(extractKanjiReadings(result.reading));
          setWords(sentenceWords);
          setSentence(result);
          setSearchParams(`?sentenceId=${result.id}`);
        }
      })();
    },
    [knowledge?.words, setSearchParams, getWords]
  );

  // Vend a sentence!
  const vendSentence = async () => {
    setReveal(false);

    const knowledgeWords = knowledge?.words || [];

    const result = _.chain(sentences)
      .shuffle()
      .filter((x) => {
        return !_.isEmpty(_.difference(x.words, knowledgeWords));
      })
      .head()
      .value();

    const dictionaryWords = await Promise.all(
      _.chain(result?.readings || [])
        .filter((x) => !knowledgeWords.includes(x))
        .map((x) => {
          return db.dictionary
            .where({ reading: x })
            .and((x) => x.common)
            .toArray();
        })
        .value()
    );

    setKanjis(extractKanjiReadings(result.reading));
    setWords(
      _.chain(dictionaryWords)
        .flatten()
        .map((x) => x as DictionaryWord)
        .value()
    );
    setSentence(result);
    setSearchParams(`?sentenceId=${result.id}`);
  };

  useEffect(() => {
    if (!sentence && sentenceId) {
      showSentence(sentenceId);
    }
  }, [showSentence, sentenceId, sentence]);

  return (
    <Box style={{ width: "100%", minHeight: 500 }} mt={10}>
      <Stack spacing={10}>
        {!sentence && (
          <Center>
            <Blockquote color="violet">
              Ready to learn?
              <Text size="xs">
                Click the button below and lets get this learning started!
              </Text>
            </Blockquote>
          </Center>
        )}
        {sentence && (
          <Center>
            <Blockquote color="violet">
              Alright! Let's see if you can read this <b>sentence</b>!
              <Text size="xs">
                If you can't recognize the words or don't know the meaning,
                review and add them to your vocabulary list.
              </Text>
              <Text size="xs">
                If you are able to recall the words and the meaning with ease,
                click the memorized button and I won't bother showing it to you
                again!
              </Text>
            </Blockquote>
          </Center>
        )}
        <Center>
          <Group>
            <Button
              onClick={vendSentence}
              leftIcon={<IconRefresh />}
              size="xs"
              variant="light"
              color="green"
              loading={_.isEmpty(sentences)}
            >
              Vend Sentence
            </Button>
            {!reveal && (
              <Button
                onClick={() => setReveal(true)}
                leftIcon={<IconEye />}
                size="xs"
                variant="light"
                color="violet"
              >
                Did I get it right?
              </Button>
            )}
          </Group>
        </Center>
      </Stack>
      {sentence && (
        <Stack spacing={0} mt={20}>
          <Container mt={20} style={{ width: "100%" }}>
            <Center>
              <TokenSentence
                style={{ fontSize: 24 }}
                sentence={sentence.expression}
                reading={sentence.reading}
                highlight={sentence.words}
                hideFurigana={!reveal}
              />
            </Center>
            {reveal && (
              <Center mt={10}>
                <Text
                  sx={(theme) => ({
                    fontSize: 12,
                    fontWeight: "bold",
                    color: theme.colors.gray[6],
                  })}
                >
                  {sentence.meaning}
                </Text>
              </Center>
            )}
          </Container>
          {reveal && (
            <Container mb={25} style={{ width: "100%" }}>
              <Group mt={30} position="center">
                {kanjis.map((kanji, idx) => {
                  const [k, r] = kanji.split(":");
                  return <KanjiTile key={idx} kanji={k} reading={r} />;
                })}
              </Group>
              <Group mt={30} position="center">
                {words.map((word, idx) => {
                  return (
                    <Box key={idx} style={{ width: 250 }}>
                      <WordCard word={word} maxMeanings={5}>
                        <VocabularyBar word={word} />
                      </WordCard>
                    </Box>
                  );
                })}
              </Group>
            </Container>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default SentenceGym;
