import { getFunctions, httpsCallable } from "firebase/functions";

interface Translation {
  detected_source_language: string;
  text: string;
}

interface TranslationData {
  translations: Translation[];
}

export interface TranslationResult {
  data: TranslationData;
  sentence: string;
}

export const translate = async (sentence: string) => {
  const functions = getFunctions();
  const translateSentence = httpsCallable(functions, "translateSentence");
  const result = await translateSentence({
    sentence,
  });

  return result.data as TranslationResult;
};
