import { fit } from "furigana";
import { Furigana } from "gem-furigana";
import _ from "lodash";
import Typo from "typo-js";
import * as wk from "wanakana";

const dictionary = new Typo("en_US", false, false, {
  dictionaryPath: "/data/typojs",
});

const hasKanji = (data: string) => {
  return !_.isEmpty(extractKanji(data));
};

const onlyKanjis = (words: string[]) => {
  return _.chain(words)
    .uniq()
    .filter((x) => !_.isEmpty(extractKanji(x)))
    .value();
};

const onlyKana = (value: string) => {
  return _.chain(value)
    .filter((x) => wk.isKana(x))
    .join("")
    .value();
};

const onlyJapanese = (value: string) => {
  return _.chain(value)
    .filter((x) => wk.isJapanese(x))
    .join("")
    .value();
};

const onlyKanaKanji = (value: string) => {
  return _.chain(value)
    .filter((x) => wk.isKana(x) || wk.isKanji(x))
    .join("")
    .value();
};

const extractKanjis = (words: string[]) => {
  return _.chain(words).flatMap(extractKanji).compact().uniq().value();
};

const extractKanji = (data: string) => {
  return _.chain(data).split("").filter(wk.isKanji).value();
};

const extractReading = (value: string) => {
  return _.chain(value)
    .split(".")
    .head()
    .map(onlyKana)
    .map(wk.toHiragana)
    .join("")
    .value();
};

const extractGems = (reading: string) => {
  const furigana = new Furigana(reading);

  const data = _.chain(furigana.ReadingHtml)
    .replace(/<\/rb><rt>/g, ":")
    .replace(/<ruby><rb>/g, "/[")
    .replace(/<\/rt><\/ruby>/g, "]")
    .value();

  const matches = data.match(/\[(.*?)\]/g);
  const gems = _.chain(matches)
    .map((x) => x.split(":"))
    .map((xs) => xs.map(onlyKanaKanji))
    .fromPairs()
    .value();

  return gems;
};

const extractKanjiReadings = (reading: string) => {
  try {
    const furigana = new Furigana(reading);
    const entries = fit(
      onlyKanaKanji(furigana.Expression),
      onlyKanaKanji(furigana.Hiragana),
      {
        type: "object",
      }
    );
    return _.chain(entries)
      .filter((x) => _.size(x.w) === 1 && wk.isKanji(x.w))
      .map((x) => `${x.w}:${x.r}`)
      .value();
  } catch {
    return [];
  }
};

const searchTranslation = (value: string) => {
  if (wk.isJapanese(value)) {
    return value;
  }

  if (dictionary.check(value)) {
    return value;
  }

  const kanaString = wk.toKana(value);
  if (wk.isJapanese(kanaString)) {
    return kanaString;
  } else {
    return value;
  }
};

export {
  hasKanji,
  extractKanji,
  extractKanjis,
  extractReading,
  onlyKanjis,
  onlyKana,
  onlyJapanese,
  onlyKanaKanji,
  extractGems,
  extractKanjiReadings,
  searchTranslation,
};
