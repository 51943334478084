import { Container, Highlight, Space, Title } from "@mantine/core";
import React from "react";

const Profile = () => {
  return (
    <Container>
      <Title order={2}>User Profile</Title>
      <Space h={20} />
      <Highlight size="sm" highlight="Google">
        User logged in using Google
      </Highlight>
    </Container>
  );
};

export default Profile;
