import { createStyles, Text, TextProps } from "@mantine/core";
import _ from "lodash";
import { loremIpsum } from "lorem-ipsum";
import React from "react";

interface RandomTextProps {
  count: number;
  props?: TextProps<"div">;
}

const componentStyles = createStyles((theme) => ({
  text: {
    marginBottom: 15,
  },
}));

const RandomText = ({ count, props }: RandomTextProps) => {
  const { classes } = componentStyles();

  return (
    <>
      {_.range(0, count).map((idx) => {
        return (
          <Text key={idx} className={classes.text} {...props}>
            {loremIpsum({
              count: 6,
              units: "sentences",
            })}
          </Text>
        );
      })}
    </>
  );
};

export default RandomText;
