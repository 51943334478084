import {
  Anchor,
  Badge,
  Box,
  Button,
  Center,
  Container,
  createStyles,
  Grid,
  Group,
  SimpleGrid,
  Tabs,
  Text,
} from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { useModals } from "@mantine/modals";
import { IconArrowLeft, IconCirclePlus } from "@tabler/icons";
import SymbolForm from "components/symbol/forms/SymbolForm";
import KanjiTile from "components/tile/KanjiTile";
import kanjiDB from "data/kanji/all.json";
import { addSymbol } from "database/actions/symbol";
import { AddSymbolProps } from "database/models/symbol";
import { kanji as kanjiData } from "framework/data/loader";
import { extractReading } from "libs/japanese";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useFirestore } from "reactfire";
import { toKatakana } from "wanakana";
import KanjiGraph from "./KanjiGraph";
import KanjiSymbols from "./KanjiSymbols";

const useStyles = createStyles((theme) => ({
  tabs: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  tabsList: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
  },

  tabControl: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "capitana",
    height: 40,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[3]
          : theme.fn.rgba(theme.colors.violet[0], 0.3),
    },
  },

  tabActive: {
    fontWeight: 700,
    color: theme.colors.violet,

    borderTopLeftRadius: theme.radius.sm,
    borderTopRightRadius: theme.radius.sm,

    border: "1px solid",
    borderColor: `${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    borderBottomColor: "white",
    marginBottom: -2,

    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  kanjiLogo: {
    textAlign: "center",
    verticalAlign: "top",
    fontFamily: "heisei-maru-gothic-std",
    fontSize: 200,
    marginBottom: -50,
    cursor: "pointer",
  },

  reading: {
    color: theme.colors.violet[8],
    fontWeight: "bold",
    fontFamily: "a-otf-ud-shin-maru-go-pr6n",
    fontSize: 14,
    padding: "0 5px 0 5px",
    boxShadow: theme.shadows.xs,
    border: "1px solid",
    borderRadius: theme.radius.sm,
    borderColor: theme.fn.rgba(theme.colors.violet[1], 0.75),
    backgroundColor: theme.fn.rgba(theme.colors.violet[1], 0.5),
  },

  meaning: {
    color: theme.colors.orange[9],
    fontSize: 11,
    fontWeight: "bold",
    padding: "0 5px 0 5px",
    boxShadow: theme.shadows.xs,
    border: "1px solid",
    borderRadius: theme.radius.sm,
    borderColor: theme.fn.rgba(theme.colors.orange[1], 0.75),
    backgroundColor: theme.fn.rgba(theme.colors.orange[1], 0.5),
  },
}));

const Kanji = () => {
  const { classes } = useStyles();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { kanji: kanjiX } = useParams();
  const kanji = useMemo(
    () => _.chain(kanjiX).split("").head().value(),
    [kanjiX]
  );

  const modals = useModals();
  const firestore = useFirestore();
  const clipboard = useClipboard();

  const tabs = useMemo(() => ["symbols", "graph"], []);
  const tabPage = _.chain(pathname).split("/").compact().last().value();
  const tabIndex = _.indexOf(tabs, tabPage || tabs[0]);
  const [activeTab, setActiveTab] = useState(tabIndex);

  useEffect(() => {
    setActiveTab(tabIndex);
  }, [tabIndex]);

  const onChange = (active: number) => {
    navigate(`/kanji/info/${kanji}/${tabs[active] || tabs[0]}`);
  };

  const onSubmit = async (data: AddSymbolProps) => {
    const filtered: any = _.omitBy(data, _.isUndefined);
    await addSymbol(firestore, filtered);
  };

  const tags = useMemo(() => {
    if (kanji) {
      return _.chain(kanjiData.jlpt)
        .pickBy((xs) => xs.includes(kanji))
        .keys()
        .concat(kanjiData.rrtk.includes(kanji) ? ["rrtk"] : [])
        .concat(kanjiData.jouyou.includes(kanji) ? ["joyo"] : [])
        .value();
    } else {
      return [];
    }
  }, [kanji]);

  // Modals
  const modalOptions = {
    size: 900,
    styles: {
      modal: {
        margin: 0,
        padding: `0 !important`,
      },
    },
    withCloseButton: false,
  };

  const openSymbolModal = () => {
    modals.openModal({
      ...modalOptions,
      id: "add-symbol-modal",
      children: (
        <>
          {kanji && (
            <SymbolForm
              kanji={kanji}
              element={kanji}
              onSubmit={(data) => {
                modals.closeModal("add-symbol-modal");
                onSubmit(data);
              }}
              segmentsDisabled={true}
              onCancel={() => modals.closeModal("add-symbol-modal", true)}
            />
          )}
        </>
      ),
    });
  };

  const openComponentModal = () => {
    modals.openModal({
      ...modalOptions,
      id: "add-component-modal",
      children: (
        <>
          {kanji && (
            <SymbolForm
              kanji={kanji}
              onSubmit={(data) => {
                modals.closeModal("add-component-modal");
                onSubmit(data);
              }}
              onCancel={() => modals.closeModal("add-component-modal", true)}
            />
          )}
        </>
      ),
    });
  };

  const rtk_keyword = _.chain(kanjiDB)
    .get(
      `${kanji}.rtk_keyword`,
      _.chain(kanjiDB).get(`${kanji}.meanings.0`).value()
    )
    .value();

  const ons: string[] = _.chain(kanjiDB)
    .get(`${kanji}.readings_on`, [])
    .value()
    .map(toKatakana);

  const kuns: string[] = _.chain(kanjiDB)
    .get(`${kanji}.readings_kun`, [])
    .value();

  const meanings: string[] = _.chain(kanjiDB)
    .get(`${kanji}.meanings`, [])
    .value();

  const lookalikes: string[] = _.chain(kanjiData)
    .get(`lookalikes.${kanji}`, [])
    .value();

  return (
    <Container>
      <SimpleGrid cols={1} spacing={5}>
        <Grid>
          <Grid.Col span={3}>
            <Text
              onClick={() => clipboard.copy(kanji)}
              className={classes.kanjiLogo}
            >
              {kanji}
            </Text>
            <Center>
              <Anchor
                variant="gradient"
                mt={-20}
                mb={20}
                style={{ fontSize: 12, fontWeight: "bold" }}
                target="_blank"
                href={`https://hochanh.github.io/rtk/${kanji}/index.html`}
              >
                RTK Index
              </Anchor>
            </Center>
          </Grid.Col>
          <Grid.Col span={9}>
            {kanji && (
              <SimpleGrid mt={20} spacing={0}>
                <Anchor onClick={() => navigate(-1)} size="xs" mb={10}>
                  <Group spacing={5}>
                    <IconArrowLeft size={16} />
                    Back
                  </Group>
                </Anchor>
                <Text
                  mb={5}
                  style={{
                    fontSize: 30,
                    fontWeight: 800,
                  }}
                >
                  {rtk_keyword || "Kanji Radical"}
                </Text>
                <Group mb={8}>
                  {tags.map((x, idx) => (
                    <Badge key={idx} size="sm" radius="sm" variant="dot">
                      {x}
                    </Badge>
                  ))}
                </Group>
                {!_.isEmpty(ons) && (
                  <Group my={5} spacing="xs">
                    <Text size="xs" color="gray" style={{ fontWeight: "bold" }}>
                      Onyomi
                    </Text>
                    {ons.map((x, idx) => (
                      <Text
                        component={Link}
                        to={`/jisho/reading/${kanji}:${extractReading(x)}`}
                        key={idx}
                        className={classes.reading}
                      >
                        {x}
                      </Text>
                    ))}
                  </Group>
                )}
                {!_.isEmpty(kuns) && (
                  <Group my={5} spacing="xs">
                    <Text size="xs" color="gray" style={{ fontWeight: "bold" }}>
                      Kunyomi
                    </Text>
                    {kuns.map((x, idx) => (
                      <Text
                        component={Link}
                        to={`/jisho/reading/${kanji}:${extractReading(x)}`}
                        key={idx}
                        className={classes.reading}
                      >
                        {x}
                      </Text>
                    ))}
                  </Group>
                )}
                {!_.isEmpty(meanings) && (
                  <Group my={5} spacing="xs">
                    <Text size="xs" color="gray" style={{ fontWeight: "bold" }}>
                      Meanings
                    </Text>
                    {meanings.map((x, idx) => (
                      <Text className={classes.meaning} key={idx}>
                        {x}
                      </Text>
                    ))}
                  </Group>
                )}
                <Group mt={15}>
                  <Button
                    leftIcon={<IconCirclePlus />}
                    size="xs"
                    variant="light"
                    onClick={openSymbolModal}
                  >
                    Symbol
                  </Button>
                  <Button
                    leftIcon={<IconCirclePlus />}
                    size="xs"
                    variant="light"
                    onClick={openComponentModal}
                  >
                    Component
                  </Button>
                </Group>

                {!_.isEmpty(lookalikes) && (
                  <Group mt={15} spacing={10}>
                    <Box
                      py={10}
                      px={5}
                      sx={(theme) => ({
                        height: "100%",
                        display: "flex",
                        justifyItems: "baseline",
                        borderRadius: theme.radius.sm,
                        background: theme.fn.rgba(theme.colors.violet[0], 0.75),
                      })}
                    >
                      <Text
                        sx={(theme) => ({
                          fontSize: 15,
                          fontWeight: "bold",
                          color: theme.colors.violet[5],
                          writingMode: "vertical-rl",
                          transform: "rotate(180deg)",
                          textOrientation: "mixed",
                        })}
                      >
                        Lookalikes &nbsp; ⭐
                      </Text>
                    </Box>
                    {lookalikes.map((x, idx) => {
                      return <KanjiTile key={idx} kanji={x} />;
                    })}
                  </Group>
                )}
              </SimpleGrid>
            )}
          </Grid.Col>
        </Grid>
      </SimpleGrid>
      <Container mt={15} mb={20}>
        <Tabs
          active={activeTab}
          onTabChange={onChange}
          variant="unstyled"
          tabPadding="sm"
          classNames={{
            root: classes.tabs,
            tabsListWrapper: classes.tabsList,
            tabControl: classes.tabControl,
            tabActive: classes.tabActive,
          }}
        >
          <Tabs.Tab label="Symbols" title="Kanji Symbols" tabKey="symbols">
            {kanji && <KanjiSymbols kanji={kanji} />}
          </Tabs.Tab>
          <Tabs.Tab label="Graph" title="Kanji Graph" tabKey="graph">
            {kanji && <KanjiGraph kanji={kanji} />}
          </Tabs.Tab>
        </Tabs>
      </Container>
    </Container>
  );
};

export default Kanji;
