import {
  ActionIcon,
  AppShell,
  Avatar,
  Button,
  Center,
  Container,
  createStyles,
  Drawer,
  Group,
  Header,
  Menu,
  SimpleGrid,
  Text,
  Title,
  UnstyledButton,
} from "@mantine/core";
import { useDisclosure, useHotkeys } from "@mantine/hooks";
import {
  IconChevronDown,
  IconLogout,
  IconSearch,
  IconSettings,
  IconUserCircle,
} from "@tabler/icons";
import RequireAdmin from "components/routing/RequireAdmin";
import SearchBox from "components/search/SearchBox";
import { App as DashboardApp } from "features/dashboard/components";
import { App as GymApp } from "features/gym/components";
import { App as JishoApp } from "features/jisho/components";
import { App as KanjiApp } from "features/kanji/components";
import { App as LabApp } from "features/lab/components";
import { App as NotesApp } from "features/notes/components";
import { App as ProfileApp } from "features/profile/components";
import { App as UserProfileApp } from "features/user/components";
import { App as VocabularyApp } from "features/vocabulary/components";
import { getAuth, IdTokenResult } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useMatch, useNavigate } from "react-router-dom";
import Data from "./Data";

const HEADER_HEIGHT = 60;

const layoutStyles = createStyles((theme) => ({
  header: {
    backgroundColor: theme.fn.rgba(theme.colors.violet[1], 0.2),
  },

  headerButton: {
    fontFamily: "capitana",
    fontSize: 12,
  },

  inner: {
    height: HEADER_HEIGHT,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  menuItem: {
    fontFamily: "capitana",
    fontSize: 11,
  },

  adminAvatar: {
    border: `2px solid ${theme.colors.red[5]}`,
  },
}));

type CustomLinkProps = {
  title: string;
  to: string;
};

const CustomLink = ({ title, to }: CustomLinkProps) => {
  const { classes } = layoutStyles();

  const matched = useMatch(`${to}/*`);
  const variant = matched ? "outline" : "subtle";

  return (
    <Button
      className={classes.headerButton}
      color="violet"
      component={Link}
      to={to}
      variant={variant}
    >
      {title}
    </Button>
  );
};

const Links = [
  { title: "Dashboard", to: "/dashboard" },
  { title: "Profile", to: "/profile" },
  { title: "Gym", to: "/gym" },
  { title: "Vocabulary", to: "/vocabulary" },
  { title: "Kanji", to: "/kanji" },
  { title: "Jisho", to: "/jisho" },
  { title: "Lab", to: "/lab" },
  { title: "Notes", to: "/notes" },
];

const Layout = () => {
  const auth = getAuth();
  const { classes, theme, cx } = layoutStyles();
  const navigate = useNavigate();

  const [token, setToken] = useState<IdTokenResult>();

  const [sidebarOpened, sidebarHandlers] = useDisclosure(false);
  useHotkeys([["Alt+J", () => sidebarHandlers.toggle()]]);

  useEffect(() => {
    (async () => {
      const idTokenResult = await auth.currentUser?.getIdTokenResult(true);
      setToken(idTokenResult);
    })();
  }, [auth, setToken]);

  const signOut = () => {
    navigate("/");
    auth.signOut();
  };

  const HeaderComponent = (
    <Header className={classes.header} height={60}>
      <Container className={classes.inner}>
        <Group px="md">
          <Title order={3} style={{ fontFamily: "a-otf-ud-shin-maru-go-pr6n" }}>
            漢字様
          </Title>
        </Group>
        <Group spacing={5}>
          {Links.map((x, idx) => (
            <CustomLink key={idx} {...x} />
          ))}
        </Group>
        <Group position="apart">
          <ActionIcon
            color="violet"
            size="sm"
            onClick={() => sidebarHandlers.toggle()}
          >
            <IconSearch color={theme.colors.violet[6]} />
          </ActionIcon>
          <Menu
            withArrow
            placement="end"
            control={
              <UnstyledButton>
                <Group spacing="sm">
                  <Center mt={5}>
                    <Avatar
                      src={auth.currentUser?.photoURL}
                      radius="lg"
                      size={32}
                      className={cx({
                        [classes.adminAvatar]: token?.claims.admin,
                      })}
                      alt="User avatar"
                    />
                    <SimpleGrid spacing={0} cols={1} ml={10} mr={4}>
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontSize: 11,
                          color: "#666666",
                        }}
                      >
                        {auth.currentUser?.displayName}
                      </Text>
                      {token?.claims.admin && (
                        <Text
                          mt={-3}
                          style={{
                            textAlign: "right",
                            fontSize: 10,
                            color: "#A0A0A0",
                          }}
                        >
                          Administrator
                        </Text>
                      )}
                    </SimpleGrid>
                    <IconChevronDown size={12} color={theme.colors.gray[6]} />
                  </Center>
                </Group>
              </UnstyledButton>
            }
          >
            <Menu.Label>User</Menu.Label>
            <Menu.Item
              className={classes.menuItem}
              component={Link}
              to="/user/profile"
              icon={<IconUserCircle size={14} color={theme.colors.violet[6]} />}
            >
              Profile
            </Menu.Item>
            <Menu.Item
              className={classes.menuItem}
              component={Link}
              to="/user/settings"
              icon={<IconSettings size={14} color={theme.colors.violet[6]} />}
            >
              Settings
            </Menu.Item>
            <Menu.Label>Application</Menu.Label>
            <Menu.Item
              className={classes.menuItem}
              onClick={signOut}
              icon={<IconLogout size={14} color={theme.colors.red[6]} />}
            >
              Logout
            </Menu.Item>
          </Menu>
        </Group>
      </Container>
    </Header>
  );

  return (
    <>
      <AppShell header={HeaderComponent}>
        <Routes>
          <Route path="user/*" element={<UserProfileApp />} />
          <Route path="dashboard/*" element={<DashboardApp />} />
          <Route
            path="profile/*"
            element={
              <RequireAdmin redirectTo="/dashboard">
                <ProfileApp />
              </RequireAdmin>
            }
          />
          <Route
            path="gym/*"
            element={
              <RequireAdmin redirectTo="/dashboard">
                <GymApp />
              </RequireAdmin>
            }
          />
          <Route
            path="vocabulary/*"
            element={
              <RequireAdmin redirectTo="/dashboard">
                <VocabularyApp />
              </RequireAdmin>
            }
          />
          <Route
            path="kanji/*"
            element={
              <RequireAdmin redirectTo="/dashboard">
                <KanjiApp />
              </RequireAdmin>
            }
          />
          <Route
            path="jisho/*"
            element={
              <RequireAdmin redirectTo="/dashboard">
                <JishoApp />
              </RequireAdmin>
            }
          />
          <Route
            path="lab/*"
            element={
              <RequireAdmin redirectTo="/dashboard">
                <LabApp />
              </RequireAdmin>
            }
          />
          <Route
            path="notes/*"
            element={
              <RequireAdmin redirectTo="/dashboard">
                <NotesApp />
              </RequireAdmin>
            }
          />
        </Routes>
      </AppShell>
      <Data />
      <Drawer
        position="right"
        size={620}
        padding="xs"
        opened={sidebarOpened}
        onClose={() => {
          sidebarHandlers.close();
        }}
        withCloseButton={false}
        closeOnClickOutside
        zIndex={2}
      >
        <SearchBox />
      </Drawer>
    </>
  );
};

export default Layout;
