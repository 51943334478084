import { collection, DocumentData, query, where } from "firebase/firestore";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

const useFireStoreKanjiOnlyData = (kanji: string | undefined) => {
  const [symbolsData, setSymbolsData] = useState<DocumentData[]>([]);

  const firestore = useFirestore();
  const symbolsCollection = collection(firestore, "symbols");
  const kanjiSymbolsQuery = query(
    symbolsCollection,
    where("kanji", "==", kanji)
  );

  const { data: symbols } = useFirestoreCollectionData(kanjiSymbolsQuery, {
    idField: "id",
  });

  useEffect(() => {
    setSymbolsData(
      _.chain(symbols)
        .filter((x) => _.isEmpty(x.segments))
        .value()
    );
  }, [symbols]);

  return { symbolsData };
};

export default useFireStoreKanjiOnlyData;
