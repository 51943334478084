// SVG Utilities

const xmlns = "http://www.w3.org/2000/xmlns/";
const xlinkns = "http://www.w3.org/1999/xlink";
const svgns = "http://www.w3.org/2000/svg";

/**
 * Serialize an SVG to Blob
 *
 * @param svg SVG Element
 * @returns Blob
 */
const serialize = (svg: any) => {
  svg = svg.cloneNode(true);
  const fragment = window.location.href + "#";
  const walker = document.createTreeWalker(svg, NodeFilter.SHOW_ELEMENT);
  while (walker.nextNode()) {
    for (const attr of (walker.currentNode as any).attributes) {
      if (attr.value.includes(fragment)) {
        attr.value = attr.value.replace(fragment, "#");
      }
    }
  }
  svg.setAttributeNS(xmlns, "xmlns", svgns);
  svg.setAttributeNS(xmlns, "xmlns:xlink", xlinkns);
  const serializer = new window.XMLSerializer();
  const string = serializer.serializeToString(svg);
  return new Blob([string], { type: "image/svg+xml" });
};

/**
 * Serialize an SVG to String
 *
 * @param element SVG Element
 * @returns String
 */
const serializeSVG = async (element: any) => {
  const serializedElement = serialize(element);
  const text = await serializedElement.text();
  return text;
};

export { serialize, serializeSVG };
