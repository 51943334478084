import React from "react";
import { Route, Routes } from "react-router-dom";
import Reading from "./Reading";
import Search from "./Search";
import Word from "./Word";

const App = () => {
  return (
    <Routes>
      <Route path="/search/" element={<Search />} />
      <Route path="/word/:reading/*" element={<Word />} />
      <Route path="/reading/:reading/*" element={<Reading />} />
      <Route path="/*" element={<Search />} />
    </Routes>
  );
};

export default App;
