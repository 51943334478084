import { createStyles, Group, SimpleGrid, Text } from "@mantine/core";
import { vocabulary } from "framework/data/loader";
import {
  DictionaryWord,
  DictionaryWordSense,
} from "framework/types/dictionary";
import _ from "lodash";
import React from "react";

const POS_INDEX = vocabulary.jmdict.pos;

const useStyles = createStyles((theme) => ({
  pos: {
    fontSize: 12,
    color: theme.colors.gray[6],
    marginBottom: -6,
  },
  meanings: {
    fontSize: 14.5,
    fontWeight: "bold",
    color: theme.colors.gray[8],
  },
}));

interface SenseProps extends DictionaryWordSense {}

const Sense = ({ meanings, pos }: SenseProps) => {
  const { classes } = useStyles();

  const meaningsString = meanings.join("; ");
  const posString = _.chain(pos)
    .map((x) => _.get(POS_INDEX, x, ""))
    .compact()
    .join(", ")
    .value();

  return (
    <Group>
      <SimpleGrid spacing={5}>
        <Text className={classes.pos}>{posString}</Text>
        <Text className={classes.meanings}>{meaningsString}</Text>
      </SimpleGrid>
    </Group>
  );
};

interface WordDefinitionProps {
  entry: DictionaryWord;
}

const WordDefinition = ({ entry }: WordDefinitionProps) => {
  return (
    <SimpleGrid py={10}>
      {entry.senses.map((x, idx) => (
        <Sense key={idx} {...x} />
      ))}
    </SimpleGrid>
  );
};

export default WordDefinition;
