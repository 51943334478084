import {
  Anchor,
  Badge,
  Container,
  createStyles,
  Group,
  SimpleGrid,
  Tabs,
} from "@mantine/core";
import { IconExternalLink } from "@tabler/icons";
import VocabularyActionIcon from "components/vocabulary/VocabularyActionIcon";
import VocabularyKnowledgeIcon from "components/vocabulary/VocabularyKnowledgeIcon";
import WordTile from "components/vocabulary/WordTile";
import { DictionaryWord } from "framework/types/dictionary";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { db, Vocabulary } from "state/dexie/db";
import WordDefinition from "./WordDefinition";
import WordSentences from "./WordSentences";

const appStyles = createStyles((theme) => ({
  tabs: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  tabsList: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
  },

  tabControl: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "capitana",
    height: 40,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[3]
          : theme.fn.rgba(theme.colors.violet[0], 0.3),
    },
  },

  tabActive: {
    fontWeight: 700,
    color: theme.colors.violet,

    borderTopLeftRadius: theme.radius.sm,
    borderTopRightRadius: theme.radius.sm,

    border: "1px solid",
    borderColor: `${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    borderBottomColor: "white",
    marginBottom: -2,

    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const Word = () => {
  const { classes } = appStyles();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { reading } = useParams();
  const [entry, setEntry] = useState<DictionaryWord>();
  const [tagsEntry, setTagsEntry] = useState<Vocabulary>();

  useEffect(() => {
    (async () => {
      if (reading) {
        const result = await db.dictionary.get({ reading: reading });
        const tagResult = await db.vocabulary.get({
          expression: result?.expression,
        });
        setTagsEntry(tagResult);
        setEntry(result as DictionaryWord);
      }
    })();
  }, [reading]);

  const tabs = useMemo(() => ["definition", "sentences"], []);
  const tabPage = _.chain(pathname).split("/").compact().last().value();
  const tabIndex = _.indexOf(tabs, tabPage || tabs[0]);
  const [activeTab, setActiveTab] = useState(tabIndex);

  useEffect(() => {
    setActiveTab(tabIndex);
  }, [tabIndex]);

  const onChange = (active: number) => {
    navigate(`/jisho/word/${reading}/${tabs[active] || tabs[0]}`);
  };

  return (
    <Container>
      <Container py="sm" style={{ minHeight: 100 }}>
        <SimpleGrid cols={1} spacing={5}>
          {entry?.expression && (
            <WordTile expression={entry?.expression} reading={entry?.reading} />
          )}
          <Container>
            <Group mt={10}>
              {entry?.common && (
                <Badge
                  variant="gradient"
                  gradient={{ from: "teal", to: "lime" }}
                  size="sm"
                  radius="sm"
                >
                  Common
                </Badge>
              )}
              {tagsEntry?.tags.map((x, idx) => (
                <Badge key={idx} size="sm" radius="sm" variant="dot">
                  {x}
                </Badge>
              ))}
            </Group>
          </Container>
          {entry && (
            <Container mt={20}>
              <Group spacing="xs">
                <VocabularyActionIcon word={entry} />
                <VocabularyKnowledgeIcon word={entry} />
                <Anchor
                  href={`https://jisho.org/search/${entry.expression}`}
                  target="_blank"
                >
                  <IconExternalLink size={20} style={{ paddingTop: 5 }} />
                </Anchor>
              </Group>
            </Container>
          )}
        </SimpleGrid>
      </Container>
      <Container mt={20}>
        <Tabs
          active={activeTab}
          onTabChange={onChange}
          variant="unstyled"
          tabPadding="sm"
          classNames={{
            root: classes.tabs,
            tabsListWrapper: classes.tabsList,
            tabControl: classes.tabControl,
            tabActive: classes.tabActive,
          }}
        >
          <Tabs.Tab label="Meanings" title="Word Meanings" tabKey="definition">
            {entry && <WordDefinition entry={entry} />}
          </Tabs.Tab>
          <Tabs.Tab label="Sentences" title="Sentences" tabKey="sentences">
            {entry && <WordSentences word={entry.expression} />}
          </Tabs.Tab>
        </Tabs>
      </Container>
    </Container>
  );
};

export default Word;
