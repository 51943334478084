import { useMantineTheme } from "@mantine/core";

const usePos = () => {
  const theme = useMantineTheme();

  const color = {
    名詞: theme.colors.violet[6],
    助詞: theme.colors.orange[8],
    動詞: theme.colors.grape[6],
    形容詞: theme.colors.blue[6],
    接続詞: theme.colors.red[5],
  };

  const pos = {
    名詞: "noun",
    助詞: "particle",
    動詞: "verb",
    形容詞: "adjective",
    接続詞: "conjunction",
  };

  return { color, pos };
};

export default usePos;
