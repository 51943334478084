import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

const jlptLevels = atomWithStorage<string[]>("jlpt-levels", ["n5"]);

const jlptLevelsAtom = atom(
  (get) => get(jlptLevels),
  (get, set, levels: string[]) => {
    set(jlptLevels, levels);
  }
);

export default jlptLevelsAtom;
