import {
  AddVocabularyProps,
  AddVocabularyWordProps,
  RemoveVocabularyWordProps,
  UpdateVocabularyProps,
} from "database/models/vocabulary";
import { User } from "firebase/auth";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  Firestore,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";

const COLLECTION = "vocabulary";

const addVocabulary = async (
  firestore: Firestore,
  user: User,
  data: AddVocabularyProps
) => {
  const docRef = await addDoc(collection(firestore, COLLECTION), {
    ...data,
    roles: {
      [user.uid]: "owner",
    },
    created: serverTimestamp(),
    modified: serverTimestamp(),
  });
  return docRef.id;
};

const updateVocabulary = async (
  firestore: Firestore,
  data: UpdateVocabularyProps
) => {
  const docRef = doc(firestore, COLLECTION, data.id);
  await updateDoc(docRef, {
    title: data.title,
    description: data.description,
    modified: serverTimestamp(),
  });
};

const addVocabularyWord = async (
  firestore: Firestore,
  data: AddVocabularyWordProps
) => {
  const docRef = doc(firestore, COLLECTION, data.id);
  await updateDoc(docRef, {
    words: arrayUnion(data.word),
    modified: serverTimestamp(),
  });
};

const removeVocabularyWord = async (
  firestore: Firestore,
  data: RemoveVocabularyWordProps
) => {
  const docRef = doc(firestore, COLLECTION, data.id);
  await updateDoc(docRef, {
    words: arrayRemove(data.word),
    modified: serverTimestamp(),
  });
};

const deleteVocabulary = async (firestore: Firestore, id: string) => {
  const docRef = doc(firestore, COLLECTION, id);
  await deleteDoc(docRef);
};

export {
  addVocabulary,
  updateVocabulary,
  addVocabularyWord,
  removeVocabularyWord,
  deleteVocabulary,
};
