import {
  Button,
  Container,
  Divider,
  Group,
  List,
  SimpleGrid,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { Prism } from "@mantine/prism";
import {
  IconChecklist,
  IconCirclePlus,
  IconDownload,
  IconMathFunction,
} from "@tabler/icons";
import { BlobReader, TextWriter, ZipReader } from "@zip.js/zip.js";
import KanjiFlow from "components/d3/kanjiflow/KanjiFlow";
import { collection, doc, getDocs, writeBatch } from "firebase/firestore";
import { getBlob, ref } from "firebase/storage";
import { generateSymbolGraph, getSymbolDataRecursive } from "libs/symbol";
import _ from "lodash";
import React, { useState } from "react";
import { useFirestore, useStorage } from "reactfire";

const DataLab = () => {
  const firestore = useFirestore();
  const storage = useStorage();
  const symbolsCollection = collection(firestore, "symbols");

  const [graph, setGraph] = useState<any>(null);
  const [entry, setEntry] = useState<string>("");

  const downloadSymbolsRecursive = async () => {
    const symbols = await getSymbolDataRecursive(firestore, [
      "時",
      "読",
      "器",
      "活",
      "朝",
    ]);

    const symbolGraph = await generateSymbolGraph(symbols);
    setGraph(symbolGraph);
  };

  const downloadKanjiVG = async () => {
    const notesRef = ref(
      storage,
      `application/data/kanjivg/kanji-svg-path-data.zip`
    );
    console.log("Downloading...");
    const blob = await getBlob(notesRef);
    console.log((blob.size / 1024).toFixed(2), "KB");

    const zipReader = new ZipReader(new BlobReader(blob));
    const entries = await zipReader.getEntries();

    if (entries[0].getData) {
      const data = await entries[0].getData(new TextWriter());
      const json = JSON.parse(data);
      setEntry(JSON.stringify(_.take(json, 2), null, 2));
    }
  };

  const updateSymbolData = async () => {
    const querySnapshot = await getDocs(symbolsCollection);
    const symbols = _.chain(querySnapshot.docs).map((x) => x.data());

    const kanjis = symbols.map("kanji").uniq().value();
    const keywords = symbols.map("keyword").uniq().value();

    const consolidateKanji = async () => {
      const batch = writeBatch(firestore);
      _.chain(kanjis)
        .each((x) => {
          const kanjiRef = doc(firestore, "application", "data", "kanji", x);
          batch.set(kanjiRef, { kanji: x });
        })
        .value();

      await batch.commit();
    };

    const consolidateKeywords = async () => {
      const batch = writeBatch(firestore);
      _.chain(keywords)
        .each((x) => {
          const keywordRef = doc(
            firestore,
            "application",
            "data",
            "keyword",
            x
          );
          batch.set(keywordRef, { keyword: x });
        })
        .value();

      await batch.commit();
    };

    // Firebase documentation
    // A batched write can contain up to 500 operations.
    if (_.size(kanjis) < 450) {
      await consolidateKanji();
    } else {
      showNotification({
        styles: {
          title: {
            fontWeight: "bold",
          },
          description: {
            fontSize: 12,
          },
        },
        title: "Firebase limitation",
        message: `Kanji batch writes cannot exceed 450 => ${_.size(kanjis)}`,
      });
    }

    if (_.size(keywords) < 450) {
      await consolidateKeywords();
    } else {
      showNotification({
        styles: {
          title: {
            fontWeight: "bold",
          },
          description: {
            fontSize: 12,
          },
        },
        title: "Firebase limitation",
        message: `Keyword batch writes cannot exceed 450 => ${_.size(
          keywords
        )}`,
      });
    }
  };

  const getApplicationData = async () => {
    const kanjiSnapshot = await getDocs(
      collection(firestore, "application", "data", "kanji")
    );
    const keywordSnapshot = await getDocs(
      collection(firestore, "application", "data", "keyword")
    );
    console.log("Kanji Count :: ", kanjiSnapshot.size);
    console.log("Keyword Count :: ", keywordSnapshot.size);
  };

  return (
    <Container>
      <Title
        my={15}
        order={6}
        sx={(theme) => ({
          color: theme.colors.violet[8],
          textDecoration: "underline",
        })}
      >
        Add Kanji and Keyword Data
      </Title>
      <List
        size="xs"
        spacing="xs"
        center
        mb={20}
        icon={
          <ThemeIcon color="blue" radius="sm">
            <IconChecklist size={18} />
          </ThemeIcon>
        }
      >
        <List.Item>Get all the symbols in the database</List.Item>
        <List.Item>Collect all the Kanjis and Keywords</List.Item>
        <List.Item>
          Update the application settings with the information
        </List.Item>
      </List>
      <SimpleGrid>
        <Group>
          <Button
            size="xs"
            variant="light"
            color="red"
            leftIcon={<IconCirclePlus />}
            onClick={updateSymbolData}
          >
            Symbol Data
          </Button>
          <Button
            size="xs"
            variant="light"
            color="green"
            leftIcon={<IconCirclePlus />}
            onClick={getApplicationData}
          >
            Verify Data
          </Button>
        </Group>
      </SimpleGrid>
      <Divider my={25} />
      <Title
        my={15}
        order={6}
        sx={(theme) => ({
          color: theme.colors.violet[8],
          textDecoration: "underline",
        })}
      >
        Download KanjiVG Compressed
      </Title>
      <SimpleGrid>
        <Group>
          <Button
            size="xs"
            variant="light"
            color="grape"
            leftIcon={<IconDownload />}
            onClick={downloadKanjiVG}
          >
            Download Data
          </Button>
          <Prism
            style={{ overflow: "auto" }}
            scrollAreaComponent="div"
            colorScheme="dark"
            noCopy
            withLineNumbers
            language="json"
          >
            {entry}
          </Prism>
        </Group>
      </SimpleGrid>
      <Divider my={25} />
      <Title
        my={15}
        order={6}
        sx={(theme) => ({
          color: theme.colors.violet[8],
          textDecoration: "underline",
        })}
      >
        Recursive Symbols Generation ⭐
      </Title>
      <SimpleGrid>
        <Group>
          <Button
            size="xs"
            variant="light"
            color="grape"
            leftIcon={<IconMathFunction />}
            onClick={downloadSymbolsRecursive}
          >
            Download Symbols
          </Button>
        </Group>
        {graph && <KanjiFlow graph={graph} useGlyphs />}
      </SimpleGrid>
    </Container>
  );
};

export default DataLab;
