// Firebase storage related functions

import { BlobReader, TextWriter, ZipReader } from "@zip.js/zip.js";
import { FirebaseStorage, getBlob, ref } from "firebase/storage";

const downloadZip = async (storage: FirebaseStorage, bucket: string) => {
  const bucketRef = ref(storage, bucket);

  const blob = await getBlob(bucketRef);
  const zipReader = new ZipReader(new BlobReader(blob));
  const entries = await zipReader.getEntries();

  if (entries[0].getData) {
    const data = await entries[0].getData(new TextWriter());
    const json = JSON.parse(data);
    return json;
  }

  return {};
};

export { downloadZip };
