import { Badge, Container, Group, Stack } from "@mantine/core";
import VocabularyActionIcon from "components/vocabulary/VocabularyActionIcon";
import VocabularyKnowledgeIcon from "components/vocabulary/VocabularyKnowledgeIcon";
import WordCard from "components/vocabulary/WordCard";
import WordTile from "components/vocabulary/WordTile";
import { DictionaryWord } from "framework/types/dictionary";
import React, { useEffect, useState } from "react";
import { db, Vocabulary } from "state/dexie/db";

interface WordGymProps {
  reading: string;
}

const WordGym = ({ reading }: WordGymProps) => {
  const [entry, setEntry] = useState<DictionaryWord>();
  const [tagsEntry, setTagsEntry] = useState<Vocabulary>();

  useEffect(() => {
    (async () => {
      if (reading) {
        const result = await db.dictionary.get({ reading: reading });
        const tagResult = await db.vocabulary.get({
          expression: result?.expression,
        });
        setTagsEntry(tagResult);
        setEntry(result as DictionaryWord);
      }
    })();
  }, [reading]);

  return (
    <Container>
      <Stack spacing={0}>
        {entry?.expression && (
          <WordTile expression={entry?.expression} reading={entry?.reading} />
        )}
        <Container>
          <Group mt={10}>
            {entry?.common && (
              <Badge
                variant="gradient"
                gradient={{ from: "teal", to: "lime" }}
                size="sm"
                radius="sm"
              >
                Common
              </Badge>
            )}
            {tagsEntry?.tags.map((x, idx) => (
              <Badge key={idx} size="sm" radius="sm" variant="dot">
                {x}
              </Badge>
            ))}
          </Group>
        </Container>
        {entry && (
          <Container mt={20}>
            <Group spacing="xs">
              <VocabularyActionIcon word={entry} />
              <VocabularyKnowledgeIcon word={entry} />
            </Group>
          </Container>
        )}
      </Stack>

      <Container style={{ width: "80%" }} mt={25}>
        {entry && <WordCard word={entry} maxMeanings={5} />}
      </Container>
    </Container>
  );
};

export default WordGym;
