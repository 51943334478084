import { Box } from "@mantine/core";
import KanjiFlow from "components/d3/kanjiflow/KanjiFlow";
import { generateSymbolGraph, getSymbolDataRecursive } from "libs/symbol";
import { useEffect, useState } from "react";
import { useFirestore } from "reactfire";

interface KanjiGraphProps {
  kanji: string;
}

const KanjiGraph = ({ kanji }: KanjiGraphProps) => {
  const firestore = useFirestore();
  const [graph, setGraph] = useState<any>(null);

  useEffect(() => {
    (async () => {
      const symbols = await getSymbolDataRecursive(firestore, [kanji]);
      const symbolGraph = await generateSymbolGraph(symbols);
      setGraph(symbolGraph);
    })();
  }, [firestore, kanji]);

  return <Box>{graph && <KanjiFlow graph={graph} useGlyphs />}</Box>;
};

export default KanjiGraph;
