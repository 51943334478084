import { User } from "firebase/auth";
import {
  arrayRemove,
  arrayUnion,
  doc,
  Firestore,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";

const COLLECTION = "knowledge";

const addWord = async (firestore: Firestore, user: User, value: string) => {
  const docRef = doc(firestore, COLLECTION, user.uid);
  await updateDoc(docRef, {
    words: arrayUnion(value),
    modified: serverTimestamp(),
  });
};

const removeWord = async (firestore: Firestore, user: User, value: string) => {
  const docRef = doc(firestore, COLLECTION, user.uid);
  await updateDoc(docRef, {
    words: arrayRemove(value),
    modified: serverTimestamp(),
  });
};

const addKeyword = async (firestore: Firestore, user: User, value: string) => {
  const docRef = doc(firestore, COLLECTION, user.uid);
  await updateDoc(docRef, {
    keywords: arrayUnion(value),
    modified: serverTimestamp(),
  });
};

const removeKeyword = async (
  firestore: Firestore,
  user: User,
  value: string
) => {
  const docRef = doc(firestore, COLLECTION, user.uid);
  await updateDoc(docRef, {
    keywords: arrayRemove(value),
    modified: serverTimestamp(),
  });
};

const addSymbol = async (firestore: Firestore, user: User, value: string) => {
  const docRef = doc(firestore, COLLECTION, user.uid);
  await updateDoc(docRef, {
    symbols: arrayUnion(value),
    modified: serverTimestamp(),
  });
};

const removeSymbol = async (
  firestore: Firestore,
  user: User,
  value: string
) => {
  const docRef = doc(firestore, COLLECTION, user.uid);
  await updateDoc(docRef, {
    symbols: arrayRemove(value),
    modified: serverTimestamp(),
  });
};

const resetKnowledge = async (firestore: Firestore, user: User) => {
  const docRef = doc(firestore, COLLECTION, user.uid);
  await setDoc(docRef, {
    symbols: [],
    keywords: [],
    words: [],
    modified: serverTimestamp(),
  });
};

export {
  addWord,
  removeWord,
  addKeyword,
  removeKeyword,
  addSymbol,
  removeSymbol,
  resetKnowledge,
};
