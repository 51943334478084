import { atom } from "jotai";

const kanjiIndex = atom<string[]>([]);

const kanjiIndexAtom = atom(
  (get) => get(kanjiIndex),
  (get, set, newIndex: string[]) => {
    set(kanjiIndex, newIndex);
  }
);

export default kanjiIndexAtom;
