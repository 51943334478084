import {
  collection,
  DocumentData,
  Firestore,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import _ from "lodash";
import { getKanjiVGSVG } from "./kanjivg";
const { nanoid } = require("nanoid");

type IDDocumentData = DocumentData & { id: string };

const getSymbolDataByKanjis = async (
  firestore: Firestore,
  kanjis: string[]
) => {
  const symbolsCollection = collection(firestore, "symbols");
  const kanjiChunks = _.chunk(kanjis, 10);

  const symbols = await Promise.all(
    _.chain(kanjiChunks)
      .map(async (chunks) => {
        const kanjiSymbolsQuery = query(
          symbolsCollection,
          where("kanji", "in", chunks)
        );

        return await getDocs(kanjiSymbolsQuery);
      })
      .value()
  );

  return _.chain(symbols)
    .flatMap((x) => x.docs)
    .map((x) => _.merge({}, { id: x.id }, x.data()))
    .uniqBy((x) => [x.keyword, x.kanji, x.segments, x.element])
    .value();
};

const getSymbolDataByKeywords = async (
  firestore: Firestore,
  keywords: string[]
) => {
  const symbolsCollection = collection(firestore, "symbols");
  const keywordChunks = _.chunk(keywords, 10);

  const symbols = await Promise.all(
    _.chain(keywordChunks)
      .map(async (chunks) => {
        const keywordSymbolsQuery = query(
          symbolsCollection,
          where("keyword", "in", chunks)
        );

        return await getDocs(keywordSymbolsQuery);
      })
      .value()
  );

  return _.chain(symbols)
    .flatMap((x) => x.docs)
    .map((x) => _.merge({}, { id: x.id }, x.data()))
    .uniqBy((x) => [x.keyword, x.kanji, x.segments, x.element])
    .value();
};

const getSymbolDataRecursive = async (
  firestore: Firestore,
  kanjis: string[],
  keywords: string[] = [],
  symbols: IDDocumentData[] = [],
  searchedKanjis: string[] = [],
  searchedKeywords: string[] = []
): Promise<IDDocumentData[]> => {
  if (_.every([_.isEmpty(kanjis), _.isEmpty(keywords)])) {
    return symbols;
  } else {
    const newKanjiSymbols = await getSymbolDataByKanjis(firestore, kanjis);
    const newKeywordSymbols = await getSymbolDataByKeywords(
      firestore,
      keywords
    );

    const updatedSearchedKanjis = _.concat(searchedKanjis, kanjis);
    const updatedSearchedKeywords = _.concat(searchedKeywords, keywords);

    const updatedSymbols = _.chain(symbols)
      .concat(newKanjiSymbols, newKeywordSymbols)
      .uniqBy((x) => [x.keyword, x.kanji, x.segments, x.element])
      .value();

    const [kanjisPending, keywordsPending] = _.chain(updatedSymbols)
      .partition((x) => !_.isEmpty(x.element))
      .value();

    // Already acquired kanjis and keywords
    const kanjisX = _.chain(updatedSymbols)
      .map("kanji")
      .compact()
      .uniq()
      .value();

    const keywordsX = _.chain(updatedSymbols)
      .map("keyword")
      .compact()
      .uniq()
      .value();

    // Create the lookup lists
    // Note: Exclude already queried kanjis and keywords
    const lookupKanjis = _.chain(kanjisPending)
      .map("element")
      .uniq()
      .compact()
      .difference(kanjisX)
      .difference(updatedSearchedKanjis)
      .value();

    const lookupKeywords = _.chain(keywordsPending)
      .map("keyword")
      .uniq()
      .compact()
      .difference(keywordsX)
      .difference(updatedSearchedKeywords)
      .value();

    return getSymbolDataRecursive(
      firestore,
      lookupKanjis,
      lookupKeywords,
      updatedSymbols,
      updatedSearchedKanjis,
      updatedSearchedKeywords
    );
  }
};

const getSymbolData = async (firestore: Firestore, kanji: string) => {
  const symbolsCollection = collection(firestore, "symbols");

  const kanjiSymbolsQuery = query(
    symbolsCollection,
    where("kanji", "==", kanji)
  );

  const kanjiElementsQuery = query(
    symbolsCollection,
    where("element", "==", kanji)
  );

  // Get all the symbols and elements
  const kanjiSymbols = await getDocs(kanjiSymbolsQuery);
  const elementSymbols = await getDocs(kanjiElementsQuery);

  const allSymbols = _.chain([kanjiSymbols, elementSymbols])
    .flatMap((x) => x.docs)
    .map((x) => _.merge({}, { id: x.id }, x.data()))
    .uniqBy("id")
    .value();

  return allSymbols;
};

const getIdBy = (collection: any) => (value: string, kanji: string) => {
  const entries = _.get(collection, value);

  if (_.size(entries) > 1) {
    const entry = _.find(entries, { kanji: kanji });
    return _.get(entry, "id");
  } else {
    return _.get(entries, [0, "id"]);
  }
};

const generateSymbolGraph = async (
  nodes: ({
    id: string;
  } & DocumentData)[]
) => {
  const nodeListGlyphs = await Promise.all(
    _.chain(nodes)
      .map(async (x) => {
        const source = await getKanjiVGSVG(x.kid, x.segments);
        return { ...x, glyph: source };
      })
      .value()
  );

  const nodeList = _.chain(nodeListGlyphs)
    .map((x: IDDocumentData) => ({
      id: x.id,
      kid: x.kid,
      kanji: x.kanji,
      element: x.element,
      keyword: x.keyword,
      glyph: x.glyph,
      segments: x.segments,
    }))
    .uniqBy((x) => `${x.element}:${x.keyword}`)
    .value();

  const elementGroups = _.chain(nodeList).groupBy("element").value();
  const keywordGroups = _.chain(nodeList).groupBy("keyword").value();

  const getIdByElement = getIdBy(elementGroups);
  const getIdByKeyword = getIdBy(keywordGroups);

  const linkList = _.chain(nodes)
    .flatMap((x) => {
      if (x.kanji !== x.element) {
        if (x.element) {
          const source = getIdByElement(x.element, x.kanji);
          const target = getIdByElement(x.kanji, x.kanji);

          if (source && target) {
            return {
              source: source,
              target: target,
            };
          }
        } else {
          const source = getIdByKeyword(x.keyword, x.kanji);
          const target = getIdByElement(x.kanji, x.kanji);

          if (source && target) {
            return {
              source: source,
              target: target,
            };
          }
        }
      }

      return [];
    })
    .uniqWith(_.isEqual)
    .map((x) => ({ ...x, id: nanoid(12) }))
    .value();

  return { nodes: nodeList, links: linkList };
};

export {
  getSymbolData,
  generateSymbolGraph,
  getSymbolDataByKanjis,
  getSymbolDataByKeywords,
  getSymbolDataRecursive,
};
