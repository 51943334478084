import {
  Box,
  Button,
  Center,
  createStyles,
  Group,
  InputWrapper,
  Loader,
  Select,
  SimpleGrid,
  Text,
  Textarea,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconPuzzle } from "@tabler/icons";
import KanjiVGSelect from "components/kanjivg/KanjiVGSelect";
import { kanjivg } from "framework/data/loader";
import useKanjiVG from "hooks/kanjivg/use-kanjivg";
import { useAtomValue } from "jotai";
import _ from "lodash";
import React, { forwardRef, useMemo, useState } from "react";
import keywordIndexAtom from "state/jotai/keyword-index";

interface ElementItemProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  value: string;
}

const ElementItem = forwardRef<HTMLDivElement, ElementItemProps>(
  ({ label, value, ...others }: ElementItemProps, ref) => (
    <div ref={ref} {...others}>
      <Text
        size="sm"
        style={{
          fontFamily: "heisei-maru-gothic-std",
          fontSize: 16,
        }}
      >
        {value}
      </Text>
    </div>
  )
);

const symbolFormStyles = createStyles((theme) => ({
  widget: {
    border: `1px solid ${theme.colors.violet[2]}`,
  },

  form: {
    padding: theme.spacing.md,
  },

  header: {
    padding: theme.spacing.sm,
    background: theme.fn.rgba(theme.colors.violet[1], 0.25),
  },
}));

interface SymbolFormProps {
  id?: string;
  kid?: string;
  segments?: string[];
  keyword?: string;
  kanji: string;
  element?: string;
  story?: string;
  segmentsDisabled?: boolean;
  onSubmit: (value: any) => void;
  onCancel?: () => void;
}

const MAX_STORY_SIZE = 1024;

const SymbolForm = ({
  id,
  segments,
  keyword,
  kanji,
  element,
  story,
  segmentsDisabled,
  onSubmit,
  onCancel,
}: SymbolFormProps) => {
  const { classes } = symbolFormStyles();

  const keywordIndex = useAtomValue(keywordIndexAtom);
  const [keywordData, setKeywordData] = useState("");
  const [elementData, setElementData] = useState("");

  const { status, source: sourceSVG } = useKanjiVG(kanji);
  const kanjiVG = _.chain(kanjivg).get(kanji).value();

  const elements = _.chain(_.get(kanjiVG, "groups"))
    .map("element")
    .concat(kanji)
    .compact()
    .uniq()
    .value();

  const form = useForm({
    initialValues: {
      id: id || undefined,
      kid: _.get(kanjiVG, "kid"),
      segments: segments || [],
      keyword: keyword || "",
      kanji: kanji,
      element: element || "",
      story: story || "",
    },
    validate: {
      keyword: (value) => (_.isEmpty(value) ? "Please enter a keyword" : null),
      story: (value) =>
        _.size(value) > MAX_STORY_SIZE
          ? `Story cannot exceed ${MAX_STORY_SIZE} characters`
          : null,
    },
  });

  // Setup the keywords data
  const keywordSelectData = useMemo(() => {
    return _.concat(keywordIndex, _.isEmpty(keywordData) ? [] : [keywordData]);
  }, [keywordIndex, keywordData]);

  // Setup the elements data
  const elementSelectData = useMemo(() => {
    return _.concat(elements, _.isEmpty(elementData) ? [] : [elementData]);
  }, [elements, elementData]);

  if (status === "loading") {
    return (
      <Center style={{ height: 250, width: "100%" }}>
        <SimpleGrid cols={1}>
          <Center>
            <Loader size="xl" variant="bars" />
          </Center>
          <Text>Loading, please wait...</Text>
        </SimpleGrid>
      </Center>
    );
  }

  return (
    <SimpleGrid spacing={0} cols={1} className={classes.widget}>
      <Box className={classes.header}>
        <Group>
          <IconPuzzle size={38} style={{ color: "gray" }} />
          <Box>
            <Title order={5}>Symbol</Title>
            <Text style={{ fontSize: 11, color: "gray" }}>
              Manage Symbols for the application
            </Text>
          </Box>
        </Group>
      </Box>
      <Box className={classes.form}>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <SimpleGrid cols={2}>
            <Select
              clearable
              creatable
              searchable
              size="xs"
              label="Keyword"
              description="Enter a keyword that best describes this symbol"
              initiallyOpened={false}
              getCreateLabel={(query) => `+ Create ${query}`}
              onCreate={(value) => {
                setKeywordData(value);
                form.setFieldValue("keyword", value);
              }}
              {...form.getInputProps("keyword")}
              data={keywordSelectData.map((x) => {
                return {
                  value: x,
                  label: x,
                };
              })}
            />

            <Select
              clearable
              creatable
              searchable
              size="xs"
              label="Element"
              description="Select a matching element for this symbol (optional)"
              itemComponent={ElementItem}
              getCreateLabel={(query) => `+ Create ${query}`}
              onCreate={(value) => {
                setElementData(value);
                form.setFieldValue("element", value);
              }}
              {...form.getInputProps("element")}
              data={elementSelectData.map((x) => {
                return {
                  value: x,
                  label: x,
                };
              })}
            />
          </SimpleGrid>

          <Textarea
            size="xs"
            my={10}
            autosize
            minRows={2}
            maxLength={MAX_STORY_SIZE}
            description={`Create a good story for this symbol, try using all the sub-components (Max ${MAX_STORY_SIZE} characters)`}
            placeholder="Ex. Two drops of rice in a tree, simple"
            label="Story"
            {...form.getInputProps("story")}
          ></Textarea>

          <InputWrapper
            my={10}
            size="xs"
            label="Segments"
            description="Select all the segments that combine to form this symbol"
          >
            <Box my={20}>
              <KanjiVGSelect
                source={sourceSVG}
                character={kanji}
                disabled={segmentsDisabled || false}
                values={form.values.segments}
                onChange={(values) => form.setFieldValue("segments", values)}
              />
            </Box>
          </InputWrapper>

          <Group position="left" mt="md" pt={10} pb={5}>
            <Button color="violet" variant="outline" size="xs" type="submit">
              {_.isUndefined(form.values.id) ? "Create Symbol" : "Edit Symbol"}
            </Button>
            {!_.isUndefined(onCancel) && (
              <Button
                color="red"
                variant="outline"
                size="xs"
                onClick={onCancel}
              >
                Cancel
              </Button>
            )}
          </Group>
        </form>
      </Box>
    </SimpleGrid>
  );
};

export default SymbolForm;
