import * as d3 from "d3";
import _ from "lodash";
import { db as kdb } from "state/dexie/db";
import { serializeSVG } from "utils/svg";

const getKanjiVGSVG = async (kid: string, segments: string[]) => {
  const kObject = await kdb.kanjis.get({ kid: kid });
  let source = "";

  if (kObject) {
    source = kObject.svg;
  } else {
    source = await d3.text(`${process.env.PUBLIC_URL}/data/kanjivg/${kid}.svg`);
  }

  const parser = new DOMParser();
  const element = parser.parseFromString(source, "text/html");
  const svg = d3.select(element);

  // Remove the stroke element
  svg.select("svg>g:nth-child(2)").remove();

  // Nullify the styles
  const svgStrokePath = svg.select("svg>g:nth-child(1)");
  svgStrokePath.attr("style", "");

  // Apply the custom styling
  svgStrokePath.classed("frame", true);

  if (_.isEmpty(segments)) {
    svgStrokePath.classed("frame-highlight", true);
  } else {
    // Apply the highlight styling
    _.chain(segments)
      .each((x) => {
        const id = x.split(":").join("\\:");
        svgStrokePath.select(`#${id}`).classed("highlight", true);
      })
      .value();
  }

  return await serializeSVG(svg.select("svg").node());
};

export { getKanjiVGSVG };
