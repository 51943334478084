import { Button, Container, Group, SimpleGrid, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { IconCirclePlus } from "@tabler/icons";
import {
  addVocabulary,
  deleteVocabulary,
  updateVocabulary,
} from "database/actions/vocabulary";
import { AddVocabularyProps } from "database/models/vocabulary";
import { useAtomValue } from "jotai";
import _ from "lodash";
import React from "react";
import { useAuth, useFirestore } from "reactfire";
import vocabularyListsAtom from "state/jotai/vocabulary-lists";
import VocabularyForm from "./VocabularyForm";
import VocabularyListCard from "./VocabularyListCard";

const VocabularyLists = () => {
  const auth = useAuth();
  const modals = useModals();
  const firestore = useFirestore();
  const vocabularyLists = useAtomValue(vocabularyListsAtom);

  const onCreate = async (data: AddVocabularyProps) => {
    const filtered: any = _.omitBy(data, _.isUndefined);
    if (auth.currentUser) {
      await addVocabulary(firestore, auth.currentUser, filtered);
    } else {
      console.warn("User is not authenticated!");
    }
  };

  const onEdit = async (data: AddVocabularyProps) => {
    const filtered: any = _.omitBy(data, _.isUndefined);
    await updateVocabulary(firestore, filtered);
  };

  // Modals
  const modalOptions = {
    size: 500,
    styles: {
      modal: {
        margin: 0,
        padding: `0 !important`,
      },
    },
    withCloseButton: false,
  };

  const openVocabularyModal = (entry?: any) => {
    modals.openModal({
      ...modalOptions,
      id: "vocabulary-modal",
      children: (
        <VocabularyForm
          {...entry}
          onSubmit={(data) => {
            modals.closeModal("vocabulary-modal");
            data.id ? onEdit(data) : onCreate(data);
          }}
          onCancel={() => modals.closeModal("vocabulary-modal", true)}
        />
      ),
    });
  };

  const deleteVocabularyConfirmation = (data: any) =>
    modals.openConfirmModal({
      title: "Delete Vocabulary List",
      centered: true,
      children: (
        <Text size="xs">
          Are you sure you want to delete Vocabulary list <b>{data.title}</b>?
        </Text>
      ),
      labels: {
        confirm: "Delete",
        cancel: "Cancel",
      },
      confirmProps: { color: "red", style: { fontSize: 12 } },
      cancelProps: { style: { fontSize: 12 } },
      onConfirm: () => deleteVocabulary(firestore, data.id),
    });

  return (
    <Container>
      <Container py="sm" style={{ minHeight: 100 }}>
        <SimpleGrid cols={1} spacing={5}>
          <Text
            style={{
              fontSize: 30,
              fontWeight: 800,
            }}
          >
            Vocabulary
          </Text>
          <Text
            style={{
              fontSize: 12,
            }}
          >
            Create word lists to track your learning
          </Text>
          <Group mt={20}>
            <Button
              leftIcon={<IconCirclePlus />}
              variant="light"
              size="xs"
              onClick={openVocabularyModal}
            >
              Word List
            </Button>
          </Group>
        </SimpleGrid>
      </Container>
      <Container py={25} px={0}>
        <SimpleGrid cols={3}>
          {_.chain(vocabularyLists || [])
            .orderBy(["title"], ["asc"])
            .map((x, idx) => (
              <VocabularyListCard
                key={idx}
                vocabulary={x}
                editVocabulary={openVocabularyModal}
                deleteVocabulary={deleteVocabularyConfirmation}
              />
            ))
            .value()}
        </SimpleGrid>
      </Container>
    </Container>
  );
};

export default VocabularyLists;
