import { Group, TextProps } from "@mantine/core";
import { Token } from "framework/types/sentence";
import { extractGems } from "libs/japanese";
import _ from "lodash";
import { useMemo } from "react";
import PosMarker from "../pos/PosMarker";
import "./styles.scss";

interface SentenceMarkupProps extends TextProps<"div"> {
  sentence: string;
  tokens: Token[];
  hide?: string[];
  show?: string[];
  cloze?: string[];
  highlight?: string[];
  reading?: string;
  hideFurigana?: boolean;
}

const SentenceMarkup = ({
  sentence,
  reading,
  tokens,
  hide,
  show,
  cloze,
  highlight,
  hideFurigana,
  ...props
}: SentenceMarkupProps) => {
  const gemTokens = useMemo(() => {
    if (reading) {
      return extractGems(reading);
    }
  }, [reading]);

  const readingTokens = useMemo(() => {
    if (gemTokens) {
      return _.chain(tokens)
        .map((x) => {
          return _.set(x, "reading", _.get(gemTokens, x.surface, x.reading));
        })
        .value();
    }
    return tokens;
  }, [gemTokens, tokens]);

  return (
    <Group
      spacing={2}
      className="sentence-markup"
      style={{ alignItems: "flex-end" }}
    >
      {readingTokens?.map((token, idx) => {
        return (
          <PosMarker
            key={idx}
            idx={idx}
            token={token}
            hide={hide}
            show={show}
            cloze={cloze}
            highlight={highlight}
            hideFurigana={hideFurigana}
            {...props}
          />
        );
      })}
    </Group>
  );
};

export default SentenceMarkup;
