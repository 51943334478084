import axios, { AxiosRequestConfig } from "axios";
import { getFunctions, httpsCallable } from "firebase/functions";
import { TokenizeResult } from "framework/types/sentence";

export const tokenize = async (sentence: string) => {
  const functions = getFunctions();
  const tokenizeSentence = httpsCallable(functions, "tokenizeSentence");
  const result = await tokenizeSentence({
    sentence,
  });

  return result.data as TokenizeResult;
};

export const tokenizeHTTP = async (sentence: string) => {
  const axiosData = JSON.stringify({
    sentence: sentence,
    mode: "normal",
  });

  const axiosConfig: AxiosRequestConfig = {
    method: "put",
    url: "https://tokenizer.kanjisama.com/tokenize",
    headers: {
      "Content-Type": "application/json",
    },
    data: axiosData,
  };

  const response = await axios(axiosConfig);

  return {
    sentence,
    data: response.data,
  };
};
