import { Badge, Group } from "@mantine/core";
import { DictionaryWord } from "framework/types/dictionary";
import useVocabularyTagColors from "hooks/tags/use-vocabulary-tag-colors";
import { getWordTags } from "libs/vocabulary";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import VocabularyActionIcon from "./VocabularyActionIcon";
import VocabularyKnowledgeIcon from "./VocabularyKnowledgeIcon";

type VocabularyBarProps = {
  word: DictionaryWord;
  noAction?: boolean;
  noKnowledge?: boolean;
};

const VocabularyBar: FC<VocabularyBarProps> = ({
  word,
  noAction,
  noKnowledge,
  children,
}) => {
  const [tags, setTags] = useState<string[]>([]);
  const tagColors = useVocabularyTagColors();

  useEffect(() => {
    (async () => {
      const wordTags = await getWordTags(word.reading);
      if (wordTags) {
        const jlptTags = _.chain(wordTags)
          .filter((x) => _.keys(tagColors).includes(x))
          .value();
        setTags(jlptTags);
      }
    })();
  }, [setTags, tagColors, word.reading]);

  return (
    <Group position="apart" p="xs">
      <Group spacing="xs">
        {children}
        {!noAction && <VocabularyActionIcon word={word} />}
        {!noKnowledge && <VocabularyKnowledgeIcon word={word} />}
      </Group>
      {word.common && (
        <Group>
          {tags.map((x, idx) => {
            const color = _.get(tagColors, x);
            return (
              <Badge
                key={idx}
                variant="light"
                size="xs"
                radius="sm"
                sx={(theme) => ({
                  color: "white",
                  fontWeight: "normal",
                  backgroundColor: color,
                })}
              >
                {_.capitalize(x)}
              </Badge>
            );
          })}

          <Badge
            variant="gradient"
            gradient={{ from: "teal", to: "lime" }}
            size="xs"
            radius="sm"
          >
            Common
          </Badge>
        </Group>
      )}
    </Group>
  );
};

export default VocabularyBar;
