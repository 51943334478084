import { ActionIcon, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import {
  IconCircleCheck,
  IconCircleMinus,
  IconPuzzle,
  IconShieldCheck,
} from "@tabler/icons";
import confetti from "canvas-confetti";
import { addKeyword, removeKeyword } from "database/actions/knowledge";
import { useAtomValue } from "jotai";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { useAuth, useFirestore } from "reactfire";
import knowledgeAtom from "state/jotai/knowledge";

const celebrate = () => {
  confetti({
    particleCount: 225,
    spread: 120,
    origin: { y: 0.6 },
  });
};

const notify = (icon: ReactNode, color: string, message: ReactNode) => {
  showNotification({
    icon: icon,
    color: color,
    styles: {
      title: {
        fontWeight: "bold",
      },
      description: {
        fontSize: 12,
      },
    },
    title: "Knowledge Base",
    message: message,
  });
};

interface KeywordKnowledgeIconProps {
  keyword: string;
}

const KeywordKnowledgeIcon = ({ keyword }: KeywordKnowledgeIconProps) => {
  const { currentUser } = useAuth();
  const firestore = useFirestore();
  const knowledge = useAtomValue(knowledgeAtom);

  const isIncluded = useMemo(() => {
    return _.chain(knowledge?.keywords).includes(keyword).value();
  }, [knowledge, keyword]);

  const onClick = () => {
    if (currentUser) {
      if (isIncluded) {
        removeKeyword(firestore, currentUser, keyword);
        notify(
          <IconCircleMinus />,
          "orange",
          <Text size="xs">{keyword} removed from your knowledge bank!</Text>
        );
      } else {
        addKeyword(firestore, currentUser, keyword);
        notify(
          <IconCircleCheck />,
          "green",
          <Text size="xs">{keyword} added to your knowledge bank!</Text>
        );
        celebrate();
      }
    } else {
      console.warn(`You need to be signed-in to perform this action!`);
    }
  };

  return (
    <ActionIcon
      color={isIncluded ? "green" : "violet"}
      title={isIncluded ? "I know this!" : "Add to my knowledge"}
      variant="light"
      onClick={onClick}
    >
      {!isIncluded && <IconPuzzle size={20} />}
      {isIncluded && <IconShieldCheck size={20} />}
    </ActionIcon>
  );
};

export default KeywordKnowledgeIcon;
