import {
  Box,
  Center,
  Container,
  createStyles,
  SimpleGrid,
  Text,
} from "@mantine/core";
import SentenceMarkup from "components/markup/sentence/SentenceMarkup";
import LoadingScreen from "components/ui/LoadingScreen";
import { TokenizeResult } from "framework/types/sentence";
import { massifSentences } from "libs/massif";
import { tokenize } from "libs/sentence";
import { translate, TranslationResult } from "libs/translate";
import _ from "lodash";
import { useEffect, useState } from "react";

interface WordSentencesProps {
  word: string;
}

const useStyles = createStyles((theme) => ({
  meaning: {
    fontSize: 12,
    fontWeight: 800,
    color: theme.colors.gray[7],
    textAlign: "center",
  },
}));

const WordSentences = ({ word }: WordSentencesProps) => {
  const { classes } = useStyles();
  const [tokenizeResults, setTokenizeResults] = useState<
    [TokenizeResult, TranslationResult][]
  >([]);

  useEffect(() => {
    (async () => {
      const massifResults = await massifSentences(word);
      const selectedSentences = _.take(massifResults, 10);
      const tokenizedSentences = await Promise.all(
        _.map(selectedSentences, (x) =>
          Promise.all([tokenize(x.text), translate(x.text)])
        )
      );
      setTokenizeResults(tokenizedSentences);
    })();
  }, [word]);

  return (
    <Container>
      <SimpleGrid my={25}>
        {_.isEmpty(tokenizeResults) && <LoadingScreen />}
        {tokenizeResults && (
          <>
            {tokenizeResults.map(([x, y], idx) => {
              const translation = _.chain(y.data.translations)
                .first()
                .get("text")
                .value();

              return (
                <Box key={idx} mb={10}>
                  <Center>
                    <SentenceMarkup
                      sentence={x.sentence}
                      tokens={x.data.tokens}
                      style={{ fontSize: 20 }}
                      highlight={[word]}
                      hideFurigana={false}
                    />
                  </Center>
                  <Center>
                    <Text className={classes.meaning}>{translation}</Text>
                  </Center>
                </Box>
              );
            })}
          </>
        )}
      </SimpleGrid>
    </Container>
  );
};

export default WordSentences;
