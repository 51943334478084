import { Text, TextProps } from "@mantine/core";
import React from "react";
import { RenderProps } from "./types";

type WithoutRubyProps = RenderProps & TextProps<"div">;

const WithoutRuby = ({
  token,
  color,
  underline,
  ...textProps
}: WithoutRubyProps) => {
  return (
    <Text
      component="span"
      sx={(theme) => ({
        color: color ? color : theme.colors.gray[6],
        border: 0,
        borderBottom: underline ? 1 : 0,
        borderStyle: "solid",
        borderBottomColor: "inherit",
        fontWeight: 800,
        fontFamily: "a-otf-ud-shin-maru-go-pr6n",
      })}
      {...textProps}
    >
      {token.surface}
    </Text>
  );
};

export default WithoutRuby;
