import {
  Container,
  Kbd,
  SimpleGrid,
  Text,
  useMantineTheme,
} from "@mantine/core";
import React from "react";
import Notes from "./Notes";

const Layout = () => {
  const theme = useMantineTheme();

  return (
    <Container>
      <Container py="sm" style={{ minHeight: 100 }}>
        <SimpleGrid cols={1} spacing={5}>
          <Text
            style={{
              fontSize: 30,
              fontWeight: 800,
            }}
          >
            Notes
          </Text>
          <Text
            style={{
              fontSize: 12,
            }}
          >
            Capture Notes and Comments during development and testing
          </Text>
          <Text
            style={{
              marginTop: 5,
              fontSize: 10,
              color: theme.colors.gray[6],
            }}
          >
            Press <Kbd>Ctrl</Kbd> + <Kbd>S</Kbd> to save your notes
          </Text>
        </SimpleGrid>
      </Container>
      <Container mt={20}>
        <Notes />
      </Container>
    </Container>
  );
};

export default Layout;
