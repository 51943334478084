import { Preferences } from "database/models/preferences";
import { atom } from "jotai";

const preferences = atom<Preferences | undefined>(undefined);

const preferencesAtom = atom(
  (get) => get(preferences),
  (get, set, xs: Preferences) => {
    set(preferences, xs);
  }
);

export default preferencesAtom;
