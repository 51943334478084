import {
  Anchor,
  Container,
  createStyles,
  Group,
  SimpleGrid,
  Tabs,
  Text,
} from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons";
import VocabularyTags from "components/vocabulary/VocabularyTags";
import { DictionaryWord } from "framework/types/dictionary";
import { useAtomValue } from "jotai";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { db } from "state/dexie/db";
import vocabularyListsAtom from "state/jotai/vocabulary-lists";
import { formatTimestamp } from "utils/date";
import VocabularyGym from "./VocabularyGym";
import VocabularyKanjiIndex from "./VocabularyKanjiIndex";
import VocabularyWords from "./VocabularyWords";

const appStyles = createStyles((theme) => ({
  tabs: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  tabsList: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
  },

  tabControl: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "capitana",
    height: 40,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[3]
          : theme.fn.rgba(theme.colors.violet[0], 0.3),
    },
  },

  tabActive: {
    fontWeight: 700,
    color: theme.colors.violet,

    borderTopLeftRadius: theme.radius.sm,
    borderTopRightRadius: theme.radius.sm,

    border: "1px solid",
    borderColor: `${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    borderBottomColor: "white",
    marginBottom: -1,

    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const VocabularyList = () => {
  const { id } = useParams();
  const { classes } = appStyles();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const pageNumber = useMemo(() => {
    const pageInt = _.toSafeInteger(searchParams.get("page"));
    return pageInt > 0 ? pageInt : 1;
  }, [searchParams]);

  const [words, setWords] = useState<DictionaryWord[]>([]);

  const vocabularyLists = useAtomValue(vocabularyListsAtom);
  const data = useMemo(() => {
    if (id && vocabularyLists) {
      return _.chain(vocabularyLists).keyBy("id").get(id).value();
    } else {
      return null;
    }
  }, [vocabularyLists, id]);

  const tabs = useMemo(() => ["vocabulary", "kanji", "gym"], []);
  const tabPage = _.chain(pathname).split("/").compact().last().value();
  const tabIndex = _.indexOf(tabs, tabPage || tabs[0]);
  const [activeTab, setActiveTab] = useState(tabIndex);

  useEffect(() => {
    setActiveTab(tabIndex);
  }, [tabIndex]);

  useEffect(() => {
    (async () => {
      const dictionaryWords = await Promise.all(
        _.chain(data?.words)
          .map(async (x) => {
            return await db.dictionary.get({ reading: x });
          })
          .value()
      );

      setWords(_.chain(dictionaryWords).compact().value() as any);
    })();
  }, [data]);

  const gems = useMemo(() => {
    return _.chain(words).flatMap("gems").uniq().value();
  }, [words]);

  const onChange = (active: number) => {
    navigate(`/vocabulary/${id}/${tabs[active] || tabs[0]}`);
  };

  return (
    <Container>
      <Container py="sm" style={{ minHeight: 150 }}>
        {data && (
          <SimpleGrid cols={1} spacing={5}>
            <Group>
              <Anchor onClick={() => navigate("/vocabulary")} size="xs">
                <Group spacing={5}>
                  <IconArrowLeft size={16} />
                  Back
                </Group>
              </Anchor>
            </Group>
            <Text
              lineClamp={1}
              style={{
                fontSize: 24,
                fontWeight: 800,
              }}
            >
              {data.title}
            </Text>
            <Text
              lineClamp={2}
              style={{
                fontSize: 12,
              }}
            >
              {data.description}
            </Text>
            <Text
              mt={5}
              style={{
                fontSize: 9,
                color: "gray",
              }}
            >
              Last modified {formatTimestamp(data?.modified?.toDate())}
            </Text>
            <VocabularyTags readings={data.words} my={10} />
          </SimpleGrid>
        )}
      </Container>
      <Container mb={20}>
        <Tabs
          active={activeTab}
          onTabChange={onChange}
          variant="unstyled"
          tabPadding="sm"
          classNames={{
            root: classes.tabs,
            tabsListWrapper: classes.tabsList,
            tabControl: classes.tabControl,
            tabActive: classes.tabActive,
          }}
        >
          <Tabs.Tab label="Vocabulary" title="Vocabulary" tabKey="vocabulary">
            {data && (
              <VocabularyWords
                words={words}
                vocabulary={data}
                page={pageNumber}
              />
            )}
          </Tabs.Tab>
          <Tabs.Tab label="Kanji" title="Kanji" tabKey="kanji">
            <VocabularyKanjiIndex gems={gems} page={pageNumber} />
          </Tabs.Tab>
          <Tabs.Tab label="Gym" title="Gym" tabKey="gym">
            <VocabularyGym words={words} />
          </Tabs.Tab>
        </Tabs>
      </Container>
    </Container>
  );
};

export default VocabularyList;
