import { Box, Container, Divider, SimpleGrid, Title } from "@mantine/core";
import KanjiVG from "components/kanjivg/KanjiVG";
import KanjiVGSelect from "components/kanjivg/KanjiVGSelect";
import { kanjivg } from "framework/data/loader";
import useKanjiVG from "hooks/kanjivg/use-kanjivg";
import _ from "lodash";
import React, { useMemo } from "react";

const KanjiVGLab = () => {
  const { status, source } = useKanjiVG("等");

  const kids = useMemo(() => {
    return _.chain(kanjivg)
      .get("等")
      .get("paths")
      .groupBy("group")
      .mapValues((x) => _.map(x, "id"))
      .toPairs()
      .flattenDeep()
      .value();
  }, []);

  return (
    <Container>
      <Title
        my={15}
        order={6}
        sx={(theme) => ({
          color: theme.colors.violet[8],
          textDecoration: "underline",
        })}
      >
        KanjiVG Various Styles
      </Title>
      <SimpleGrid cols={4}>
        {status !== "loading" && (
          <>
            <KanjiVG
              width={75}
              height={75}
              source={source}
              ids={["kvg:07b49-g2", "kvg:07b49-s10"]}
            />
            <KanjiVG source={source} ids={["kvg:07b49-g2", "kvg:07b49-s10"]} />
            <KanjiVG
              width={150}
              height={150}
              source={source}
              ids={["kvg:07b49-g2", "kvg:07b49-s10"]}
            />
            <KanjiVG width={200} height={200} source={source} />
          </>
        )}
      </SimpleGrid>
      <Divider my={25} />
      <Title
        my={15}
        order={6}
        sx={(theme) => ({
          color: theme.colors.violet[8],
          textDecoration: "underline",
        })}
      >
        KanjiVG All Paths
      </Title>
      <SimpleGrid cols={12}>
        {status !== "loading" &&
          kids.map((kid, idx) => {
            return (
              <KanjiVG
                width={60}
                height={60}
                key={idx}
                source={source}
                ids={[kid]}
              />
            );
          })}
      </SimpleGrid>
      <Divider my={25} />
      <Title
        my={15}
        order={6}
        sx={(theme) => ({
          color: theme.colors.violet[8],
          textDecoration: "underline",
        })}
      >
        KanjiVGSelect Widget
      </Title>
      <Box>
        {status !== "loading" && (
          <KanjiVGSelect
            source={source}
            character="等"
            values={["kvg:07b49-g2", "kvg:07b49-s10"]}
            disabled={false}
            disabledValues={["kvg:07b49-g6"]}
            onChange={(values) => console.log(values)}
          />
        )}
      </Box>
    </Container>
  );
};

export default KanjiVGLab;
