import { Text, TextProps } from "@mantine/core";
import { getFurigana } from "libs/token";
import React from "react";
import { RenderProps } from "./types";

type WithRubyProps = RenderProps & TextProps<"div">;

const WithRuby = ({ token, color, underline, ...textProps }: WithRubyProps) => {
  const furigana = getFurigana(token);

  return (
    <Text
      component="span"
      sx={(theme) => ({
        textAlign: "center",
        color: color ? color : theme.colors.gray[6],
        border: 0,
        borderBottom: underline ? 1 : 0,
        borderStyle: "solid",
        borderBottomColor: "inherit",
        fontWeight: 800,
        fontFamily: "a-otf-ud-shin-maru-go-pr6n",
      })}
      dangerouslySetInnerHTML={{ __html: furigana.ReadingHtml }}
      {...textProps}
    ></Text>
  );
};

export default WithRuby;
