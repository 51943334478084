import { collection, DocumentData, query, where } from "firebase/firestore";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

const useFireStoreKeywordData = (keyword: string) => {
  const [symbolsData, setSymbolsData] = useState<DocumentData[]>([]);
  const [subSymbolsData, setSubSymbolsData] = useState<DocumentData[]>([]);

  const firestore = useFirestore();
  const symbolsCollection = collection(firestore, "symbols");

  const keywordSymbolsQuery = query(
    symbolsCollection,
    where("keyword", "==", keyword)
  );

  const { data: symbols } = useFirestoreCollectionData(keywordSymbolsQuery, {
    idField: "id",
  });

  useEffect(() => {
    setSymbolsData(
      _.chain(symbols)
        .filter((x) => _.isEmpty(x.segments))
        .value()
    );
    setSubSymbolsData(
      _.chain(symbols)
        .filter((x) => !_.isEmpty(x.segments))
        .value()
    );
  }, [symbols]);

  return { symbolsData, subSymbolsData };
};

export default useFireStoreKeywordData;
