import { Vocabulary } from "database/models/vocabulary";
import { atom } from "jotai";

const vocabularyLists = atom<Vocabulary[]>([]);

const vocabularyListsAtom = atom(
  (get) => get(vocabularyLists),
  (get, set, xs: Vocabulary[]) => {
    set(vocabularyLists, xs);
  }
);

export default vocabularyListsAtom;
