import { Container, createStyles, SimpleGrid, Tabs, Text } from "@mantine/core";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Metrics from "./Metrics";
import Overview from "./Overview";

const appStyles = createStyles((theme) => ({
  tabs: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  tabsList: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
  },

  tabControl: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "capitana",
    height: 40,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[3]
          : theme.fn.rgba(theme.colors.violet[0], 0.3),
    },
  },

  tabActive: {
    fontWeight: 700,
    color: theme.colors.violet,

    borderTopLeftRadius: theme.radius.sm,
    borderTopRightRadius: theme.radius.sm,

    border: "1px solid",
    borderColor: `${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    borderBottomColor: "white",
    marginBottom: -1,

    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const Layout = () => {
  const { classes } = appStyles();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const tabs = useMemo(() => ["overview", "metrics"], []);
  const tabPage = _.chain(pathname).split("/").compact().drop(1).head().value();
  const tabIndex = _.indexOf(tabs, tabPage || tabs[0]);

  const [activeTab, setActiveTab] = useState(tabIndex);

  useEffect(() => {
    setActiveTab(tabIndex);
  }, [tabIndex]);

  const onChange = (active: number) => {
    navigate(`/dashboard/${tabs[active] || tabs[0]}`);
  };

  return (
    <Container>
      <Container py="sm" style={{ minHeight: 100 }}>
        <SimpleGrid cols={1} spacing={5}>
          <Text
            style={{
              fontSize: 30,
              fontWeight: 800,
            }}
          >
            Dashboard
          </Text>
          <Text
            style={{
              fontSize: 12,
            }}
          >
            Welcome to your Dashboard
          </Text>
        </SimpleGrid>
      </Container>
      <Container>
        <Tabs
          active={activeTab}
          onTabChange={onChange}
          variant="unstyled"
          tabPadding="sm"
          classNames={{
            root: classes.tabs,
            tabsListWrapper: classes.tabsList,
            tabControl: classes.tabControl,
            tabActive: classes.tabActive,
          }}
        >
          <Tabs.Tab
            label="Overview"
            title="Dashboard Overview"
            tabKey="overview"
          >
            <Overview />
          </Tabs.Tab>
          <Tabs.Tab
            label="Metrics"
            title="Metrics Application"
            tabKey="metrics"
          >
            <Metrics />
          </Tabs.Tab>
        </Tabs>
      </Container>
    </Container>
  );
};

export default Layout;
