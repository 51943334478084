import axios, { AxiosRequestConfig } from "axios";
import _ from "lodash";

export type MassifSource = {
  publish_date: string;
  title: string;
  url: string;
};

export type MassifSentence = {
  highlighted_html: string;
  sample_source: MassifSource;
  source_count: number;
  text: string;
};

export const massifSentences = async (query: string) => {
  const axiosConfig: AxiosRequestConfig = {
    method: "get",
    url: `https://massif.la/ja/search?q=${query}&fmt=json`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios(axiosConfig);

  return _.chain(response).get("data.results").value() as MassifSentence[];
};
