import { Container, Group } from "@mantine/core";
import KanaTile from "components/tile/KanaTile";
import KanjiTile from "components/tile/KanjiTile";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { db } from "state/dexie/db";
import * as wk from "wanakana";

interface WordTileProps {
  expression: string;
  reading?: string;
}

const WordTile = ({ expression, reading }: WordTileProps) => {
  const [gems, setGems] = useState({});

  useEffect(() => {
    (async () => {
      if (expression) {
        const query = reading ? { reading } : { expression };
        const result = await db.dictionary.get(query);
        const gems = _.chain(result?.gems)
          .map((x) => _.split(x, ":"))
          .fromPairs()
          .value();
        setGems(gems);
      }
    })();
  }, [expression, reading]);

  const tiles = useMemo(
    () => _.chain(expression).split("").map(_.trim).compact().value(),
    [expression]
  );

  return (
    <Container>
      <Group
        spacing={10}
        sx={(theme) => ({
          padding: 5,
        })}
      >
        {tiles.map((tile, idx) => {
          return wk.isKanji(tile) ? (
            <KanjiTile key={idx} kanji={tile} reading={_.get(gems, tile)} />
          ) : (
            <KanaTile key={idx} kana={tile} />
          );
        })}
      </Group>
    </Container>
  );
};

export default WordTile;
