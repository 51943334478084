import { useHotkeys } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { RichTextEditor } from "@mantine/rte";
import { getAuth } from "firebase/auth";
import { getBlob, ref, uploadString } from "firebase/storage";
import React, { useEffect, useRef, useState } from "react";
import { useStorage } from "reactfire";

const Notes = () => {
  const auth = getAuth();
  const storage = useStorage();
  const editorRef = useRef<any>();
  const [value, onChange] = useState("");

  useEffect(() => {
    editorRef?.current.focus();
  }, []);

  useEffect(() => {
    (async () => {
      const notesRef = ref(storage, `notes/${auth.currentUser?.uid}`);
      const blob = await getBlob(notesRef);
      const text = await blob.text();
      onChange(text);
    })();
  }, [auth.currentUser?.uid, storage]);

  const uploadFile = async () => {
    const notesRef = ref(storage, `notes/${auth.currentUser?.uid}`);
    const result = await uploadString(notesRef, value);
    showNotification({
      id: "notes-notification",
      styles: {
        title: {
          fontWeight: "bold",
        },
        description: {
          fontSize: 12,
        },
      },
      title: "Notes saved!",
      message: `The total size of the notes saved is ${(
        result.metadata.size / 1024
      ).toFixed(2)} KB`,
    });
  };

  useHotkeys([
    [
      "ctrl+S",
      () => {
        uploadFile();
      },
    ],
  ]);

  return (
    <RichTextEditor
      ref={editorRef}
      controls={[
        ["bold", "italic", "underline", "strike", "clean"],
        ["h1", "h2", "h3"],
        ["alignLeft", "alignCenter", "alignRight"],
        ["unorderedList", "orderedList"],
        ["code", "codeBlock"],
        ["link", "video", "image", "blockquote"],
        ["sup", "sub"],
      ]}
      value={value}
      onChange={onChange}
    />
  );
};

export default Notes;
