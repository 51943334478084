import _ from "lodash";
import { db } from "state/dexie/db";

const getWordTags = async (reading: string) => {
  const word = await db.dictionary.get({ reading: reading });
  const result = await db.vocabulary.get({
    expression: word?.expression,
  });
  return result?.tags;
};

const getWordsTags = async (readings: string[]) => {
  const allTags = await Promise.all(_.chain(readings).map(getWordTags).value());
  return _.chain(allTags).compact().flatten().countBy(_.identity).value();
};

export { getWordTags, getWordsTags };
