import { atom } from "jotai";

const keywordIndex = atom<string[]>([]);

const keywordIndexAtom = atom(
  (get) => get(keywordIndex),
  (get, set, newIndex: string[]) => {
    set(keywordIndex, newIndex);
  }
);

export default keywordIndexAtom;
