import top_777 from "data/kanji/777.json";
import frequency from "data/kanji/frequency.json";
import jouyou from "data/kanji/jouyou.json";
import lookalikes from "data/kanji/lookalikes.json";
import mastery from "data/kanji/mastery.json";
import n1 from "data/kanji/n1.json";
import n2 from "data/kanji/n2.json";
import n3 from "data/kanji/n3.json";
import n4 from "data/kanji/n4.json";
import n5 from "data/kanji/n5.json";
import rrtk from "data/kanji/rrtk.json";
import tobira from "data/kanji/tobira.json";
import tobira_pre from "data/kanji/tobira_pre.json";
import kanjivg from "data/kanjivg/kanji-svg-data.json";
import pos from "data/vocabulary/pos.json";
import _ from "lodash";

const kanji = {
  lookalikes,
  frequency,
  jouyou,
  rrtk,
  tobira,
  tobira_pre,
  top_777,
  mastery,
  jlptInclusive: {
    n1: _.concat(n1, n2, n3, n4, n5),
    n2: _.concat(n2, n3, n4, n5),
    n3: _.concat(n3, n4, n5),
    n4: _.concat(n4, n5),
    n5,
  },
  jlpt: {
    n1,
    n2,
    n3,
    n4,
    n5,
  },
};

const jlptTags = {
  all: ["n5", "n4", "n3", "n2", "n1"],
  n1: ["n5", "n4", "n3", "n2", "n1"],
  n2: ["n5", "n4", "n3", "n2"],
  n3: ["n5", "n4", "n3"],
  n4: ["n5", "n4"],
  n5: ["n5"],
};

const vocabulary = {
  jmdict: {
    pos: pos,
  },
};

export { kanjivg, kanji, vocabulary, jlptTags };
