import {
  Box,
  Card,
  Center,
  Container,
  Group,
  SimpleGrid,
  Text,
} from "@mantine/core";
import KanjiVG from "components/kanjivg/KanjiVG";
import SymbolKnowledgeIcon from "components/knowledge/SymbolKnowledgeIcon";
import { Symbol } from "database/models/symbol";
import useKanjiVG from "hooks/kanjivg/use-kanjivg";
import React from "react";
import { Link } from "react-router-dom";

interface SymbolGymProps {
  symbol: Symbol;
}

const SymbolGym = ({ symbol: data }: SymbolGymProps) => {
  const { status, source } = useKanjiVG(data.kanji);

  return (
    <Center>
      <Container style={{ width: 200 }}>
        <Card withBorder radius="md">
          <Card.Section
            sx={(theme) => ({
              background: theme.fn.rgba(theme.colors.violet[1], 0.25),
            })}
          >
            <Center>
              <Text
                component={Link}
                to={`/kanji/keyword/${data.keyword}`}
                px={10}
                pt={10}
                style={{
                  color: "#707070",
                  fontWeight: 700,
                  fontSize: 12,
                  textAlign: "center",
                }}
              >
                {data.keyword}
              </Text>
            </Center>
            <Group position="center" spacing="xs">
              {data.element && (
                <Text
                  component={Link}
                  to={`/kanji/info/${data.element}`}
                  pb={10}
                  style={{
                    color: "#707070",
                    fontWeight: 700,
                    fontFamily: "uddigikyokasho-pro",
                  }}
                >
                  {data.element}
                </Text>
              )}
              <Text
                component={Link}
                to={`/kanji/info/${data.kanji}`}
                pb={10}
                style={{
                  color: "#909090",
                  fontWeight: 700,
                  fontFamily: "uddigikyokasho-pro",
                }}
              >
                [{data.kanji}]
              </Text>
            </Group>
          </Card.Section>
          <Card.Section>
            <Center
              sx={(theme) => ({
                borderTop: `1px solid ${theme.colors.violet[1]}`,
              })}
            >
              <SimpleGrid cols={1}>
                {status === "done" && (
                  <KanjiVG
                    width={125}
                    height={125}
                    source={source}
                    ids={data.segments}
                  />
                )}
              </SimpleGrid>
            </Center>
          </Card.Section>
          <Card.Section
            sx={(theme) => ({
              background: theme.fn.rgba(theme.colors.violet[0], 0.25),
            })}
          >
            <Box
              sx={(theme) => ({
                padding: 10,
                borderTop: `1px solid ${theme.colors.violet[1]}`,
              })}
            >
              <Center>
                <SymbolKnowledgeIcon symbol={data} />
              </Center>
            </Box>
          </Card.Section>
        </Card>
      </Container>
    </Center>
  );
};

export default SymbolGym;
