import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";
import { App } from "features/app/components";
import { firebaseConfig } from "framework/config/firebase";
import { Provider } from "jotai";
import React from "react";
import ReactDOM from "react-dom";
import { FirebaseAppProvider } from "reactfire";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

const applicationTheme = {
  fontFamily: "Capitana",
  headings: { fontFamily: "Capitana" },
};

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <MantineProvider theme={applicationTheme}>
          <ModalsProvider>
            <NotificationsProvider>
              <App />
            </NotificationsProvider>
          </ModalsProvider>
        </MantineProvider>
      </FirebaseAppProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
