import { Button, Container, Group, SimpleGrid, Title } from "@mantine/core";
import { IconChartCircles } from "@tabler/icons";
import KanjiFlow from "components/d3/kanjiflow/KanjiFlow";
import { createVocabularyGraph } from "libs/graph/vocabulary.model";
import { extractKanjis } from "libs/japanese";
import { generateSymbolGraph, getSymbolDataRecursive } from "libs/symbol";
import _ from "lodash";
import React, { useState } from "react";
import { useFirestore } from "reactfire";

const GraphLab = () => {
  const firestore = useFirestore();
  const [graph, setGraph] = useState<any>(null);

  const graphCheck = async () => {
    const words = ["時計", "時間", "計画", "今朝", "毎月"];
    const wordKanjis = extractKanjis(words);

    const symbols = await getSymbolDataRecursive(firestore, wordKanjis);
    const symbolGraph = await generateSymbolGraph(symbols);
    setGraph(symbolGraph);

    const reversedEdges = _.chain(symbolGraph.links)
      .map(({ id, source, target }) => ({ id, source: target, target: source }))
      .value();

    let graph = createVocabularyGraph(
      {
        nodes: symbolGraph.nodes,
        edges: reversedEdges,
      },
      words
    );

    const displayLeaves = () => {
      let leaves = graph.nodes().leaves();

      console.log(
        "Leaves :",
        _.chain(leaves)
          .map((x) => x.data())
          .map((x) => [x.id, x.type, x.keyword, x.word])
          .value()
      );
    };

    const removeNodes = (keywords: string[]) => {
      console.log(`Removing nodes with keywords ${keywords}`);
      _.each(keywords, (x) => {
        graph.remove(graph.elements(`node[keyword='${x}']`));
      });
      displayLeaves();
    };

    // Operations
    console.log(`Graph size: ${graph.nodes().size()}`);

    removeNodes([]);
    removeNodes(["Sun", "Sprout", "Say", "One", "Measurement", "Soil"]);
    removeNodes(["Ten", "Gates", "Shovel", "Day"]);
    removeNodes(["Brush-Stroke", "Interval"]);
    removeNodes(["Buddhist Temple", "Plot"]);
    removeNodes(["Time"]);
  };

  return (
    <Container>
      <Title
        my={15}
        order={6}
        sx={(theme) => ({
          color: theme.colors.violet[8],
          textDecoration: "underline",
        })}
      >
        Graph
      </Title>
      <SimpleGrid>
        <Group>
          <Button
            size="xs"
            variant="light"
            color="teal"
            leftIcon={<IconChartCircles />}
            onClick={graphCheck}
          >
            Graph Check
          </Button>
        </Group>
        {graph && <KanjiFlow graph={graph} useGlyphs />}
      </SimpleGrid>
    </Container>
  );
};

export default GraphLab;
