import {
  Box,
  Button,
  Container,
  Group,
  SimpleGrid,
  Title,
} from "@mantine/core";
import { IconRefresh } from "@tabler/icons";
import VocabularyBar from "components/vocabulary/VocabularyBar";
import WordCard from "components/vocabulary/WordCard";
import { DictionaryWord } from "framework/types/dictionary";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { db as kdb, Vocabulary } from "state/dexie/db";

const VocabularyLab = () => {
  const [words, setWords] = useState<DictionaryWord[]>([]);
  const [vocabulary, setVocabulary] = useState<Vocabulary[]>([]);

  useEffect(() => {
    (async () => {
      const vocabulary = await kdb.vocabulary
        .where("tags")
        .anyOf("n3")
        .toArray();

      setVocabulary(vocabulary);
    })();
  }, []);

  const refreshWords = useCallback(() => {
    (async () => {
      const n5Words = _.sampleSize(vocabulary, 10);
      const words = await Promise.all(
        _.chain(n5Words)
          .map("expression")
          .map(async (x) => {
            const result = await kdb.dictionary.get({ expression: x });
            return result ? (result as DictionaryWord) : result;
          })
          .value()
      );

      setWords(_.compact(words));
    })();
  }, [vocabulary]);

  useEffect(() => {
    refreshWords();
  }, [refreshWords]);

  return (
    <Container>
      <Title
        my={15}
        order={6}
        sx={(theme) => ({
          color: theme.colors.violet[8],
          textDecoration: "underline",
        })}
      >
        Vocabulary Widget
      </Title>
      <SimpleGrid my={15}>
        <Group>
          <Button
            onClick={refreshWords}
            leftIcon={<IconRefresh />}
            size="xs"
            variant="light"
          >
            Refresh Words
          </Button>
        </Group>
      </SimpleGrid>
      <SimpleGrid cols={3}>
        {words.map((word, idx) => {
          return (
            <Box key={idx} style={{ width: "100%" }}>
              <WordCard word={word} maxMeanings={5}>
                <VocabularyBar word={word} />
              </WordCard>
            </Box>
          );
        })}
      </SimpleGrid>
    </Container>
  );
};

export default VocabularyLab;
