import {
  Blockquote,
  Box,
  Button,
  Center,
  Container,
  Group,
  Stack,
  Text,
} from "@mantine/core";
import { IconCircleCheck, IconHelp, IconRefresh } from "@tabler/icons";
import TokenSentence from "components/sentence/TokenSentence";
import KanjiTile from "components/tile/KanjiTile";
import VocabularyBar from "components/vocabulary/VocabularyBar";
import WordCard from "components/vocabulary/WordCard";
import { jlptTags, kanji } from "framework/data/loader";
import { DictionaryWord } from "framework/types/dictionary";
import { useAtomValue } from "jotai";
import { extractKanji, extractKanjiReadings, hasKanji } from "libs/japanese";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { db, Dictionary, Sentence } from "state/dexie/db";
import knowledgeAtom from "state/jotai/knowledge";
import jlptLevelsAtom from "state/jotai/transient/jlpt-level";

const VocabularyGym = () => {
  const jlptLevels = useAtomValue(jlptLevelsAtom);
  const knowledge = useAtomValue(knowledgeAtom);

  const [searchParams, setSearchParams] = useSearchParams();
  const [reveal, setReveal] = useState(true);
  const [hint, showHint] = useState(false);

  const [meaning, setMeaning] = useState<string>("");
  const [sentence, setSentence] = useState<Sentence>();
  const [words, setWords] = useState<DictionaryWord[]>([]);
  const [jlptWords, setJLPTWords] = useState<DictionaryWord[]>([]);
  const [word, setWord] = useState<DictionaryWord>();
  const [kanjis, setKanjis] = useState<string[]>([]);

  const reading = useMemo(() => {
    return searchParams.get("reading");
  }, [searchParams]);

  // Setup all the words based on the levels selected
  useEffect(() => {
    (async () => {
      const allKanjis = _.chain(jlptLevels || ["n5"])
        .flatMap((x) => _.get(kanji, `jlptInclusive.${x}`, []))
        .uniq()
        .value();

      const vocabulary = await db.vocabulary
        .where("tags")
        .anyOf(jlptLevels || ["n5"])
        .toArray();

      const dictionaryWords = await Promise.all(
        _.chain(vocabulary)
          .map("reading")
          .filter(hasKanji)
          .filter((x) => {
            const wordKanjis = extractKanji(x);
            return _.chain(wordKanjis).difference(allKanjis).isEmpty().value();
          })
          .map((x) => {
            return db.dictionary
              .where({ reading: x })
              .and((x) => x.common)
              .toArray();
          })
          .value()
      );

      setWords(_.flatten(dictionaryWords) as DictionaryWord[]);
    })();
  }, [jlptLevels, knowledge?.words]);

  const wordAction = useCallback(
    (result: Dictionary) => {
      (async () => {
        const validWords = _.chain(words).map("expression").value();

        const vocabulary = await db.vocabulary.get({ reading: result.reading });
        setMeaning(vocabulary?.meaning || "");

        const sentenceResults = await db.sentences
          .where("words")
          .anyOf([result.expression])
          .filter((x) => _.size(_.difference(x.words, validWords)) < 2)
          .toArray();

        const sentenceResult = _.chain(sentenceResults)
          .shuffle()
          .head()
          .value();

        if (sentenceResult) {
          const sentenceWords = await getJLPTWords(
            sentenceResult.words,
            result.expression
          );
          setJLPTWords(sentenceWords as DictionaryWord[]);
        }

        setSentence(sentenceResult);
        setKanjis(extractKanjiReadings(result.reading));
        setWord(result as DictionaryWord);
        setSearchParams(`?reading=${result.reading}`);
      })();
    },
    [setSearchParams, words]
  );

  const showWord = useCallback(
    (reading: string) => {
      (async () => {
        setReveal(true);
        showHint(true);

        const result = await db.dictionary.get({ reading: reading });
        if (result) {
          wordAction(result);
        }
      })();
    },
    [wordAction]
  );

  const vendWord = async () => {
    const knowledgeWords = knowledge?.words || [];

    setReveal(false);
    showHint(false);

    const result = _.chain(words)
      .filter((x) => !knowledgeWords.includes(x.reading))
      .shuffle()
      .head()
      .value();

    if (result) {
      wordAction(result);
    }
  };

  useEffect(() => {
    if (!word && !_.isEmpty(words) && reading) {
      showWord(reading);
    }
  }, [word, words, showWord, reading]);

  const getJLPTWords = async (values: string[], exclude: string) => {
    const filtered = await Promise.all(
      _.chain(values)
        .filter((x) => x !== exclude)
        .map((x) =>
          db.vocabulary
            .where({ expression: x })
            .filter((x) => {
              return !_.isEmpty(_.intersection(x.tags, jlptTags.all));
            })
            .toArray()
        )
        .value()
    );

    const filteredDictionaryWords = await Promise.all(
      _.chain(filtered)
        .flatten()
        .map((x) => {
          return db.dictionary.get({ reading: x.reading });
        })
        .value()
    );

    return filteredDictionaryWords;
  };

  return (
    <Box style={{ width: "100%", minHeight: 500 }} mt={10}>
      <Stack spacing={10}>
        {!word && (
          <Center>
            <Blockquote color="violet">
              Ready to learn?
              <Text size="xs">
                Click the button below and lets get this learning started!
              </Text>
            </Blockquote>
          </Center>
        )}
        {word && (
          <Center>
            <Blockquote color="violet">
              Alright! Let's see if you can read this <b>word</b>!
              <Text size="xs">
                If you can't recognize the word or don't know the meaning, add
                it to your vocabulary list.
              </Text>
              <Text size="xs">
                If you are able to recall the word with ease, click the
                memorized button and I won't bother showing it to you again!
              </Text>
            </Blockquote>
          </Center>
        )}
        <Center>
          <Group>
            <Button
              onClick={vendWord}
              leftIcon={<IconRefresh />}
              size="xs"
              variant="light"
              color="green"
              loading={_.isEmpty(words)}
            >
              Vend Word
            </Button>
            {!reveal && (
              <Button
                onClick={() => setReveal(true)}
                leftIcon={<IconCircleCheck />}
                size="xs"
                variant="light"
                color="violet"
              >
                Did I get it right?
              </Button>
            )}
            {!hint && !reveal && sentence && (
              <Button
                onClick={() => showHint(true)}
                leftIcon={<IconHelp />}
                size="xs"
                variant="light"
                color="orange"
              >
                Give me a hint!
              </Button>
            )}
          </Group>
        </Center>
      </Stack>
      {word && (
        <Stack spacing={0}>
          <Container mt={20} style={{ width: "100%" }}>
            <Center>
              <TokenSentence
                style={{ fontSize: 30 }}
                sentence={word.expression}
                reading={word.reading}
                hideFurigana={!reveal}
              />
            </Center>
            {reveal && (
              <Center mt={5}>
                <Text
                  sx={(theme) => ({
                    fontSize: 12,
                    fontWeight: "bold",
                    color: theme.colors.gray[6],
                  })}
                >
                  {_.capitalize(meaning)}
                </Text>
              </Center>
            )}
            {sentence && (
              <>
                <Center mt={25}>
                  <TokenSentence
                    style={{ fontSize: 22 }}
                    sentence={sentence.expression}
                    reading={sentence.reading}
                    highlight={[word.expression]}
                    hide={reveal ? [] : [word.expression]}
                    hideFurigana={!hint && !reveal}
                  />
                </Center>
                {reveal && (
                  <Center mt={5}>
                    <Text
                      sx={(theme) => ({
                        fontSize: 12,
                        fontWeight: "bold",
                        color: theme.colors.gray[6],
                      })}
                    >
                      {sentence.meaning}
                    </Text>
                  </Center>
                )}
              </>
            )}
          </Container>
          {reveal && (
            <Container mb={25} style={{ width: "100%" }}>
              <Group mt={30} position="center">
                {kanjis.map((kanji, idx) => {
                  const [k, r] = kanji.split(":");
                  return <KanjiTile key={idx} kanji={k} reading={r} />;
                })}
              </Group>
              <Group mt={30} position="center">
                {_.chain([word])
                  .concat(jlptWords)
                  .compact()
                  .map((value, idx) => {
                    return (
                      <Box key={idx} style={{ width: 275 }}>
                        <WordCard word={value} maxMeanings={5}>
                          <VocabularyBar word={value} />
                        </WordCard>
                      </Box>
                    );
                  })
                  .value()}
              </Group>
            </Container>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default VocabularyGym;
