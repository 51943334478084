import { Center, Loader } from "@mantine/core";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSigninCheck } from "reactfire";

interface RequireAdminProps {
  children: JSX.Element;
  redirectTo: string;
}

const RequireAdmin = ({ children, redirectTo }: RequireAdminProps) => {
  const location = useLocation();
  const { status, data: signInCheckResult } = useSigninCheck({
    validateCustomClaims: (claims: any) => {
      return {
        hasRequiredClaims: claims.admin,
        errors: {},
      };
    },
  });

  if (status === "loading") {
    return (
      <Center style={{ width: "100%", height: "100vh" }}>
        <Loader size="xl" variant="bars" />
      </Center>
    );
  }

  if (signInCheckResult.hasRequiredClaims === true) {
    return children;
  } else {
    return <Navigate to={redirectTo} state={{ from: location }} replace />;
  }
};

export default RequireAdmin;
