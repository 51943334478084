import { ActionIcon } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { IconCirclePlus, IconDatabase } from "@tabler/icons";
import VocabularyWordForm from "components/vocabulary/VocabularyWordForm";
import { DictionaryWord } from "framework/types/dictionary";
import { useAtomValue } from "jotai";
import _ from "lodash";
import React, { useMemo } from "react";
import { useFirestore } from "reactfire";
import vocabularyListsAtom from "state/jotai/vocabulary-lists";

interface VocabularyActionIconProps {
  word: DictionaryWord;
}

const VocabularyActionIcon = ({ word }: VocabularyActionIconProps) => {
  const modals = useModals();
  const firestore = useFirestore();
  const vocabularyLists = useAtomValue(vocabularyListsAtom);

  const isIncluded = useMemo(() => {
    return _.chain(vocabularyLists)
      .map("words")
      .flatten()
      .includes(word.reading)
      .value();
  }, [vocabularyLists, word.reading]);

  // Modals
  const modalOptions = {
    size: 600,
    styles: {
      modal: {
        margin: 0,
        padding: `0 !important`,
      },
    },
    withCloseButton: false,
  };

  const openVocabularyModal = (entry: DictionaryWord) => {
    modals.openModal({
      ...modalOptions,
      id: "vocabulary-modal",
      children: <VocabularyWordForm word={entry} firestore={firestore} />,
    });
  };

  return (
    <ActionIcon
      color={isIncluded ? "orange" : "violet"}
      variant="light"
      title="Manage Vocabulary"
      onClick={() => openVocabularyModal(word)}
    >
      {!isIncluded && <IconCirclePlus size={20} />}
      {isIncluded && <IconDatabase size={20} />}
    </ActionIcon>
  );
};

export default VocabularyActionIcon;
