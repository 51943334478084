import { Container, Space, Title } from "@mantine/core";
import RandomText from "components/random/RandomText";
import React from "react";

const Metrics = () => {
  return (
    <Container>
      <Title order={2}>Dashboard Metrics</Title>
      <Space h={20} />
      <RandomText count={5} props={{ size: "xs" }} />
    </Container>
  );
};

export default Metrics;
