import { Button, Container, Group, SimpleGrid, Title } from "@mantine/core";
import { IconBoxModel } from "@tabler/icons";
import KanjiFlow from "components/d3/kanjiflow/KanjiFlow";
import { generateSymbolGraph, getSymbolData } from "libs/symbol";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useFirestore } from "reactfire";

const VizLab = () => {
  const firestore = useFirestore();
  const [graph, setGraph] = useState<any>(null);

  const genGraph = async () => {
    const symbolDataOne = await getSymbolData(firestore, "売");
    const symbolDataTwo = await getSymbolData(firestore, "続");
    const symbolDataThree = await getSymbolData(firestore, "読");
    const symbolGraph = await generateSymbolGraph(
      _.concat(symbolDataOne, symbolDataTwo, symbolDataThree)
    );

    console.log(JSON.stringify(symbolGraph, null, 2));
  };

  useEffect(() => {
    (async () => {
      const symbolDataOne = await getSymbolData(firestore, "売");
      const symbolDataTwo = await getSymbolData(firestore, "続");
      const symbolDataThree = await getSymbolData(firestore, "読");
      const symbolGraph = await generateSymbolGraph(
        _.concat(symbolDataOne, symbolDataTwo, symbolDataThree)
      );

      setGraph(symbolGraph);
    })();
  }, [firestore]);

  return (
    <Container>
      <Title
        my={15}
        order={6}
        sx={(theme) => ({
          color: theme.colors.violet[8],
          textDecoration: "underline",
        })}
      >
        Kanji Visualization
      </Title>
      <SimpleGrid spacing="xl">
        <Group>
          <Button
            onClick={genGraph}
            leftIcon={<IconBoxModel />}
            size="xs"
            variant="light"
          >
            Generate Graph Data
          </Button>
        </Group>
        {graph && <KanjiFlow graph={graph} />}
      </SimpleGrid>
    </Container>
  );
};

export default VizLab;
