import { useMantineTheme } from "@mantine/core";
import { useMemo } from "react";

const useVocabularyTagColors = () => {
  const theme = useMantineTheme();

  const colorScheme = useMemo(
    () => ({
      n5: theme.colors.violet[5],
      n4: theme.colors.grape[5],
      n3: theme.colors.teal[5],
      n2: theme.colors.orange[5],
      n1: theme.colors.red[5],
    }),
    [theme.colors]
  );

  return colorScheme;
};

export default useVocabularyTagColors;
