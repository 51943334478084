import {
  Avatar,
  Center,
  Container,
  createStyles,
  Grid,
  Navbar,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { getAuth } from "firebase/auth";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Profile from "./Profile";
import Settings from "./Settings";

const layoutStyles = createStyles((theme) => ({
  content: {},

  menuLink: {
    display: "block",
    textDecoration: "none",
    borderTopLeftRadius: theme.radius.sm,
    borderBottomLeftRadius: theme.radius.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    padding: `0 ${theme.spacing.lg}px`,
    fontWeight: 500,
    fontSize: 12,
    height: 40,
    fontFamily: "capitana",
    lineHeight: "40px",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[3]
          : theme.fn.rgba(theme.colors.violet[0], 0.3),
    },
  },

  menuLinkActive: {
    color: theme.colors.violet,
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,

    borderRightColor: "white",
    marginRight: -1,

    fontWeight: 700,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const links = [
  { label: "Profile", key: "profile" },
  { label: "Settings", key: "settings" },
];

const Layout = () => {
  const auth = getAuth();
  const { classes, cx } = layoutStyles();
  const [activeLink, setActiveLink] = useState("");
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const page =
      _.chain(pathname).split("/").compact().drop(1).head().value() ||
      _.chain(links).first().get("key").value();

    setActiveLink(page);
  }, [pathname]);

  return (
    <Container>
      <Container py="sm" style={{ minHeight: 100 }}>
        <Grid>
          <Grid.Col span={3}>
            <Center mt={5}>
              <Avatar
                src={auth.currentUser?.photoURL}
                radius="xl"
                size={120}
                alt="User avatar"
              />
            </Center>
          </Grid.Col>
          <Grid.Col span={9}>
            <SimpleGrid cols={1} spacing={5}>
              <Text
                style={{
                  fontSize: 30,
                  fontWeight: 800,
                }}
              >
                {auth.currentUser?.displayName}
              </Text>
              <Text
                style={{
                  fontSize: 12,
                }}
              >
                {auth.currentUser?.email}
              </Text>
            </SimpleGrid>
          </Grid.Col>
        </Grid>
      </Container>
      <Grid columns={12}>
        <Grid.Col span={3}>
          <Navbar p={0} height="calc(100vh - 250px)">
            <Navbar.Section grow mt="md">
              {links.map((link, idx) => {
                return (
                  <a
                    key={idx}
                    className={cx(classes.menuLink, {
                      [classes.menuLinkActive]: activeLink === link.key,
                    })}
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(`/user/${link.key}`);
                    }}
                    href="/"
                  >
                    {link.label}
                  </a>
                );
              })}
            </Navbar.Section>
          </Navbar>
        </Grid.Col>
        <Grid.Col span={9}>
          <Routes>
            <Route path="/" element={<Profile />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default Layout;
