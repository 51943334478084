import { Container, createStyles, SimpleGrid, Tabs, Text } from "@mantine/core";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DataLab from "./DataLab";
import GraphLab from "./GraphLab";
import KanjiVGLab from "./KanjiVGLab";
import SentencesLab from "./SentencesLab";
import SymbolFormLab from "./SymbolFormLab";
import TilesLab from "./TilesLab";
import VizLab from "./VizLab";
import VocabularyLab from "./VocabularyLab";

const appStyles = createStyles((theme) => ({
  tabs: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  tabsList: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
  },

  tabControl: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "capitana",
    height: 40,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[3]
          : theme.fn.rgba(theme.colors.violet[0], 0.3),
    },
  },

  tabActive: {
    fontWeight: 700,
    color: theme.colors.violet,

    borderTopLeftRadius: theme.radius.sm,
    borderTopRightRadius: theme.radius.sm,

    border: "1px solid",
    borderColor: `${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    borderBottomColor: "white",
    marginBottom: -1,

    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const Layout = () => {
  const { classes } = appStyles();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const tabs = useMemo(
    () => [
      "kanjivg",
      "symbol-form",
      "playground",
      "visualization",
      "vocabulary",
      "graph",
      "tiles",
      "sentences",
    ],
    []
  );
  const tabPage = _.chain(pathname).split("/").compact().drop(1).head().value();
  const tabIndex = _.indexOf(tabs, tabPage || tabs[0]);

  const [activeTab, setActiveTab] = useState(tabIndex);

  useEffect(() => {
    setActiveTab(tabIndex);
  }, [tabIndex]);

  const onChange = (active: number) => {
    navigate(`/lab/${tabs[active] || tabs[0]}`);
  };

  return (
    <Container>
      <Container py="sm" style={{ minHeight: 100 }}>
        <SimpleGrid cols={1} spacing={5}>
          <Text
            style={{
              fontSize: 30,
              fontWeight: 800,
            }}
          >
            Laboratory
          </Text>
          <Text
            style={{
              fontSize: 12,
            }}
          >
            Experiment with new widgets and designs
          </Text>
        </SimpleGrid>
      </Container>
      <Container>
        <Tabs
          active={activeTab}
          onTabChange={onChange}
          variant="unstyled"
          tabPadding="sm"
          classNames={{
            root: classes.tabs,
            tabsListWrapper: classes.tabsList,
            tabControl: classes.tabControl,
            tabActive: classes.tabActive,
          }}
        >
          <Tabs.Tab
            label="KanjiVG"
            title="KanjiVG Related Widgets"
            tabKey="kanjivg"
          >
            <KanjiVGLab />
          </Tabs.Tab>
          <Tabs.Tab
            label="Symbol Form"
            title="Symbol Form Widget"
            tabKey="symbol-form"
          >
            <SymbolFormLab />
          </Tabs.Tab>
          <Tabs.Tab
            label="Data Playground"
            title="Database Playground"
            tabKey="symbol-playground"
          >
            <DataLab />
          </Tabs.Tab>
          <Tabs.Tab
            label="Visualization"
            title="Visualization Playground"
            tabKey="visualization"
          >
            <VizLab />
          </Tabs.Tab>
          <Tabs.Tab
            label="Vocabulary"
            title="Vocabulary Playground"
            tabKey="vocabulary"
          >
            <VocabularyLab />
          </Tabs.Tab>
          <Tabs.Tab label="Graph" title="Graph Playground" tabKey="graph">
            <GraphLab />
          </Tabs.Tab>
          <Tabs.Tab label="Tiles" title="Tiles Playground" tabKey="tiles">
            <TilesLab />
          </Tabs.Tab>
          <Tabs.Tab
            label="Sentences"
            title="Sentences Playground"
            tabKey="sentences"
          >
            <SentencesLab />
          </Tabs.Tab>
        </Tabs>
      </Container>
    </Container>
  );
};

export default Layout;
