import { createStyles } from "@mantine/core";
import * as d3 from "d3";
import _ from "lodash";
import React, { createRef, RefObject, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { serializeSVG } from "utils/svg";

const DEFAULT_WIDTH = 100;
const DEFAULT_HEIGHT = 100;

interface KanjiVGStyleProps {
  ids?: Array<string>;
  invert: boolean;
}

const kanjiVGStyle = createStyles(
  (theme, { ids, invert }: KanjiVGStyleProps) => {
    const frameStroke = theme.fn.rgba(theme.colors.violet[2], 0.5);
    const highlightStroke = theme.fn.rgba(theme.colors.violet[8], 1);

    return {
      frame: {
        fill: "none",
        stroke: _.isEmpty(ids) && !invert ? highlightStroke : frameStroke,
        strokeWidth: 4,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
      highlight: {
        stroke: highlightStroke,
      },
    };
  }
);

interface KanjiVGProps {
  source: string;
  width?: number;
  height?: number;
  ids?: Array<string>;
  invert?: boolean;
}

const KanjiVG = ({ source, width, height, ids, invert }: KanjiVGProps) => {
  const ref: RefObject<any> = createRef<SVGAElement>();
  const [kanjiSVG, setKanjiSVG] = useState<string>("");
  const { classes } = kanjiVGStyle({ ids: ids, invert: invert || false });

  useEffect(() => {
    (async () => {
      if (source) {
        const parser = new DOMParser();
        const element = parser.parseFromString(source, "text/html");
        const svg = d3.select(element);

        // Remove the stroke element
        svg.select("svg>g:nth-child(2)").remove();

        // Nullify the styles
        const svgStrokePath = svg.select("svg>g:nth-child(1)");
        svgStrokePath.attr("style", "");

        // Apply the custom styling
        svgStrokePath.classed(classes.frame, true);

        // Apply the highlight styling
        _.chain(ids)
          .each((x) => {
            const id = x.split(":").join("\\:");
            svgStrokePath.select(`#${id}`).classed(classes.highlight, true);
          })
          .value();

        const svgSource = await serializeSVG(svg.select("svg").node());
        setKanjiSVG(svgSource);
      }
    })();
  }, [source, ids, classes]);

  return (
    <SVG
      ref={ref}
      width={width || DEFAULT_WIDTH}
      height={height || DEFAULT_HEIGHT}
      src={kanjiSVG}
    ></SVG>
  );
};

export default KanjiVG;
