import { AddSymbolProps, UpdateSymbolProps } from "database/models/symbol";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  Firestore,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";

const COLLECTION = "symbols";

const addSymbol = async (firestore: Firestore, data: AddSymbolProps) => {
  const docRef = await addDoc(collection(firestore, COLLECTION), {
    ...data,
    created: serverTimestamp(),
    modified: serverTimestamp(),
  });
  return docRef.id;
};

const updateSymbol = async (firestore: Firestore, data: UpdateSymbolProps) => {
  const docRef = doc(firestore, COLLECTION, data.id);
  await updateDoc(docRef, { ...data, modified: serverTimestamp() });
};

const deleteSymbol = async (firestore: Firestore, id: string) => {
  const docRef = doc(firestore, COLLECTION, id);
  await deleteDoc(docRef);
};

export { addSymbol, updateSymbol, deleteSymbol };
