import { atom } from "jotai";

const fuseIndex = atom<any>({});

const fuseIndexAtom = atom(
  (get) => get(fuseIndex),
  (get, set, newIndex: any) => {
    set(fuseIndex, newIndex);
  }
);

export default fuseIndexAtom;
