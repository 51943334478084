import { createStyles, SimpleGrid, Text } from "@mantine/core";
import React from "react";

const useStyles = createStyles((theme) => ({
  tile: {},
  kana: {
    fontSize: 70,
    fontWeight: 800,
    fontFamily: "a-otf-ud-shin-maru-go-pr6n",
    textShadow: theme.shadows.xs,
    color: theme.colors.gray[8],
    marginTop: -20,
  },
}));

interface KanaTileProps {
  kana: string;
}

const KanaTile = ({ kana }: KanaTileProps) => {
  const { classes } = useStyles();

  return (
    <SimpleGrid className={classes.tile} spacing={0}>
      <Text className={classes.kana}>{kana}</Text>
    </SimpleGrid>
  );
};

export default KanaTile;
