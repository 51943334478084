import * as d3 from "d3";
import { kanjivg } from "framework/data/loader";
import _ from "lodash";
import { useEffect, useState } from "react";
import { db as kdb } from "state/dexie/db";

const useKanjiVG = (character: string) => {
  const [status, setStatus] = useState("loading");
  const [source, setSource] = useState<string>("");

  useEffect(() => {
    (async () => {
      const kObject = await kdb.kanjis.get({ kanji: character });
      if (kObject) {
        setSource(kObject.svg);
        setStatus("done");
      } else {
        console.warn(`Loading the SVG image for ${character} remotely..`);
        const kanjivgData = _.get(kanjivg, character);
        const source = await d3.text(
          `${process.env.PUBLIC_URL}/data/kanjivg/${kanjivgData.kid}.svg`
        );
        setSource(source);
        setStatus("done");
      }
    })();
  }, [character, setSource, setStatus]);

  return { status, source };
};

export default useKanjiVG;
