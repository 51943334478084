import { Vocabulary } from "database/models/vocabulary";
import { collection, query } from "firebase/firestore";
import { fuseOptions } from "framework/config/fuse";
import Fuse from "fuse.js";
import useFirestoreKnowledge from "hooks/knowledge/use-firestore-knowledge";
import useFirestorePreferences from "hooks/preferences/use-firestore-preferences";
import useFirestoreVocabularyLists from "hooks/vocabulary/use-firestore-vocabulary-lists";
import { useSetAtom } from "jotai";
import { downloadZip } from "libs/storage";
import _ from "lodash";
import React, { useEffect } from "react";
import {
  useFirestore,
  useFirestoreCollectionData,
  useStorage,
} from "reactfire";
import { db as kdb } from "state/dexie/db";
import fuseAtom from "state/jotai/fuse";
import fuseIndexAtom from "state/jotai/fuse-index";
import kanjiIndexAtom from "state/jotai/kanji-index";
import keywordIndexAtom from "state/jotai/keyword-index";
import knowledgeAtom from "state/jotai/knowledge";
import preferencesAtom from "state/jotai/preferences";
import vocabularyListsAtom from "state/jotai/vocabulary-lists";

const Data = () => {
  const storage = useStorage();
  const firestore = useFirestore();

  // Setup Kanji Index
  const setKanjiIndex = useSetAtom(kanjiIndexAtom);
  const applicationKanjiCollection = collection(
    firestore,
    "application/data/kanji"
  );
  const { data: kanjis } = useFirestoreCollectionData(
    query(applicationKanjiCollection)
  );

  const setKeywordIndex = useSetAtom(keywordIndexAtom);
  const applicationKeywordCollection = collection(
    firestore,
    "application/data/keyword"
  );
  const { data: keywords } = useFirestoreCollectionData(
    query(applicationKeywordCollection)
  );

  useEffect(() => {
    setKanjiIndex(_.chain(kanjis).map("kanji").value());
  }, [kanjis, setKanjiIndex]);

  useEffect(() => {
    setKeywordIndex(_.chain(keywords).map("keyword").value());
  }, [keywords, setKeywordIndex]);

  // Setup kanjiVG store
  useEffect(() => {
    (async () => {
      const recordsSize = await kdb.kanjis.count();
      if (recordsSize === 0) {
        // Total records should be around 2411
        console.warn("Downloading the KanjiVG SVG files to local cache!");
        const json = await downloadZip(
          storage,
          `application/data/kanjivg/kanji-svg-path-data.zip`
        );
        kdb.kanjis.bulkPut(json);
      }
    })();
  }, [storage]);

  // Setup dictionary and search Index
  const setFuseIndex = useSetAtom(fuseIndexAtom);
  const setFuse = useSetAtom(fuseAtom);

  useEffect(() => {
    (async () => {
      const recordsSize = await kdb.dictionary.count();
      if (recordsSize === 0) {
        // Total records should be around 34169
        console.warn("Downloading the Dictionary to local cache!");
        const json = await downloadZip(
          storage,
          `application/data/jmdict/dictionary.zip`
        );
        kdb.dictionary.bulkPut(json);
      }

      // Create the fuse index
      const dictionaryWords = await kdb.dictionary.toArray();
      const fuseIndex = Fuse.createIndex(fuseOptions.keys, dictionaryWords);
      const fuseInstance = new Fuse(dictionaryWords, fuseOptions, fuseIndex);
      setFuseIndex(fuseIndex);
      setFuse(fuseInstance);
    })();
  }, [storage, setFuse, setFuseIndex]);

  // Setup vocabulary
  useEffect(() => {
    (async () => {
      const recordsSize = await kdb.vocabulary.count();
      if (recordsSize === 0) {
        // Total records should be around 16430
        console.warn("Downloading the Vocabulary to local cache!");
        const json = await downloadZip(
          storage,
          `application/data/vocabulary/vocabulary.zip`
        );
        kdb.vocabulary.bulkPut(json);
      }
    })();
  }, [storage]);

  // Setup sentences
  useEffect(() => {
    (async () => {
      const recordsSize = await kdb.sentences.count();
      if (recordsSize === 0) {
        // Total records should be around 16430
        console.warn("Downloading the Sentences to local cache!");
        const json = await downloadZip(
          storage,
          `application/data/sentences/sentences.zip`
        );
        kdb.sentences.bulkPut(json);
      }
    })();
  }, [storage]);

  // Setup Vocabulary lists
  const setVocabularyLists = useSetAtom(vocabularyListsAtom);
  const { data: vocabularyListsData } = useFirestoreVocabularyLists();

  useEffect(() => {
    if (!_.isUndefined(vocabularyListsData)) {
      setVocabularyLists(vocabularyListsData as Vocabulary[]);
    }
  }, [vocabularyListsData, setVocabularyLists]);

  // Setup Knowledge
  const setKnowledge = useSetAtom(knowledgeAtom);
  const { data: knowledgeData } = useFirestoreKnowledge();

  useEffect(() => {
    if (!_.isUndefined(knowledgeData)) {
      setKnowledge(knowledgeData);
    }
  }, [knowledgeData, setKnowledge]);

  // Setup Preferences
  const setPreferences = useSetAtom(preferencesAtom);
  const { data: preferencesData } = useFirestorePreferences();

  useEffect(() => {
    if (!_.isUndefined(preferencesData)) {
      setPreferences(preferencesData);
    }
  }, [preferencesData, setPreferences]);

  return <></>;
};

export default Data;
