import { Preferences } from "database/models/preferences";
import { doc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAuth, useFirestore, useFirestoreDocData } from "reactfire";

const useFirestorePreferences = () => {
  const { currentUser } = useAuth();
  const firestore = useFirestore();
  const [entries, setEntries] = useState<Preferences>();

  const ref = doc(firestore, `preferences`, `${currentUser?.uid}`);
  const { data } = useFirestoreDocData(ref, { idField: "id" });

  useEffect(() => {
    setEntries(data as Preferences);
  }, [data]);

  return { data: entries };
};

export default useFirestorePreferences;
