import {
  Box,
  Center,
  Container,
  createStyles,
  Pagination,
  SimpleGrid,
  Tabs,
} from "@mantine/core";
import KanjiTile from "components/tile/KanjiTile";
import VocabularyBar from "components/vocabulary/VocabularyBar";
import WordCard from "components/vocabulary/WordCard";
import { DictionaryWord } from "framework/types/dictionary";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { db } from "state/dexie/db";

const appStyles = createStyles((theme) => ({
  tabs: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  tabsList: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
  },

  tabControl: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "capitana",
    height: 40,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[3]
          : theme.fn.rgba(theme.colors.violet[0], 0.3),
    },
  },

  tabActive: {
    fontWeight: 700,
    color: theme.colors.violet,

    borderTopLeftRadius: theme.radius.sm,
    borderTopRightRadius: theme.radius.sm,

    border: "1px solid",
    borderColor: `${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    borderBottomColor: "white",
    marginBottom: -1,

    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const PAGE_SIZE = 12;

const Reading = () => {
  const { classes } = appStyles();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { reading } = useParams();
  const [words, setWords] = useState<DictionaryWord[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = useMemo(() => {
    const pageInt = _.toSafeInteger(searchParams.get("page"));
    return pageInt > 0 ? pageInt : 1;
  }, [searchParams]);

  const [activePage, setPage] = useState(pageNumber);

  useEffect(() => {
    setPage(pageNumber);
  }, [pageNumber]);

  const onPageChange = (pageIndex: number) => {
    setSearchParams(`?page=${pageIndex}`);
    setPage(pageIndex);
  };

  const totalPages = useMemo(() => {
    return _.chain(words).chunk(PAGE_SIZE).size().value();
  }, [words]);

  const displayWords = useMemo(() => {
    return _.chain(words)
      .chunk(PAGE_SIZE)
      .nth(activePage < totalPages ? activePage - 1 : totalPages - 1)
      .value();
  }, [activePage, totalPages, words]);

  useEffect(() => {
    (async () => {
      if (reading) {
        const results = await db.dictionary
          .where("gems")
          .anyOf(reading)
          .toArray();

        const commonWords = _.chain(results)
          .orderBy("common", ["desc"])
          .take(21)
          .value();
        setWords(commonWords as DictionaryWord[]);
      }
    })();
  }, [reading]);

  const tabs = useMemo(() => ["words"], []);
  const tabPage = _.chain(pathname).split("/").compact().last().value();
  const tabIndex = _.indexOf(tabs, tabPage || tabs[0]);
  const [activeTab, setActiveTab] = useState(tabIndex);

  useEffect(() => {
    setActiveTab(tabIndex);
  }, [tabIndex]);

  const onChange = (active: number) => {
    navigate(`/jisho/word/${reading}/${tabs[active] || tabs[0]}`);
  };

  const [kanji, yomi] = useMemo(() => {
    return _.chain(reading).split(":").value();
  }, [reading]);

  return (
    <Container>
      <Container py="sm" style={{ minHeight: 100 }}>
        <Center>
          <KanjiTile kanji={kanji} reading={yomi} />
        </Center>
      </Container>
      <Container my={20}>
        <Tabs
          active={activeTab}
          onTabChange={onChange}
          variant="unstyled"
          tabPadding="sm"
          classNames={{
            root: classes.tabs,
            tabsListWrapper: classes.tabsList,
            tabControl: classes.tabControl,
            tabActive: classes.tabActive,
          }}
        >
          <Tabs.Tab label="Words (Same Reading)" title="Words" tabKey="words">
            {totalPages !== 0 && (
              <Pagination
                mt={20}
                mb={30}
                withEdges
                noWrap
                color={"violet"}
                boundaries={5}
                position="center"
                page={activePage}
                onChange={onPageChange}
                total={totalPages}
                size="md"
                styles={{
                  item: {
                    fontSize: 12,
                  },
                  active: {
                    fontWeight: "bold",
                  },
                }}
              />
            )}
            <SimpleGrid cols={3}>
              {displayWords &&
                displayWords.map((word, idx) => {
                  return (
                    <Box key={idx} style={{ width: "100%" }}>
                      <WordCard word={word} maxMeanings={5}>
                        <VocabularyBar word={word} />
                      </WordCard>
                    </Box>
                  );
                })}
            </SimpleGrid>
          </Tabs.Tab>
        </Tabs>
      </Container>
    </Container>
  );
};

export default Reading;
