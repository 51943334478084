import { Knowledge } from "database/models/knowledge";
import { atom } from "jotai";

const knowledge = atom<Knowledge | undefined>(undefined);

const knowledgeAtom = atom(
  (get) => get(knowledge),
  (get, set, xs: Knowledge) => {
    set(knowledge, xs);
  }
);

export default knowledgeAtom;
