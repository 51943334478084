import {
  Container,
  createStyles,
  SimpleGrid,
  Stack,
  Tabs,
  Text,
} from "@mantine/core";
import { kanji } from "framework/data/loader";
import { useAtom } from "jotai";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import kanjiIndexAtom from "state/jotai/kanji-index";
import KanjiIndex from "./KanjiIndex";

const appStyles = createStyles((theme) => ({
  tabs: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  tabsList: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
  },

  tabControl: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "capitana",
    height: 40,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[3]
          : theme.fn.rgba(theme.colors.violet[0], 0.3),
    },
  },

  tabActive: {
    fontWeight: 700,
    color: theme.colors.violet,

    borderTopLeftRadius: theme.radius.sm,
    borderTopRightRadius: theme.radius.sm,

    border: "1px solid",
    borderColor: `${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    borderBottomColor: "white",
    marginBottom: -1,

    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const Layout = () => {
  const { classes } = appStyles();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const pageNumber = useMemo(() => {
    const pageInt = _.toSafeInteger(searchParams.get("page"));
    return pageInt > 0 ? pageInt : 1;
  }, [searchParams]);

  const tabs = useMemo(
    () => [
      "rrtk",
      "mastery",
      "top_777",
      "frequency",
      "jouyou",
      "tobira_pre",
      "tobira",
      "n5",
      "n4",
      "n3",
      "n2",
      "n1",
    ],
    []
  );
  const tabPage = _.chain(pathname).split("/").compact().drop(1).head().value();
  const tabIndex = _.indexOf(tabs, tabPage || tabs[0]);

  const [activeTab, setActiveTab] = useState(tabIndex);
  const [kanjiIndex] = useAtom(kanjiIndexAtom);

  useEffect(() => {
    setActiveTab(tabIndex);
  }, [tabIndex]);

  const onChange = (active: number) => {
    navigate({
      pathname: `/kanji/${tabs[active] || tabs[0]}`,
      search: "?page=1",
    });
  };

  const indexLabel = (label: string, kanjis: string[]) => {
    const allKanjis = _.size(kanjis);
    const unIndexedKanjis = _.chain(kanjis)
      .filter((x) => !kanjiIndex.includes(x))
      .size()
      .value();

    return (
      <Stack spacing={0}>
        <Text size="xs">{label}</Text>
        <Text style={{ fontSize: 9 }}>
          {allKanjis}
          {unIndexedKanjis > 0 && (
            <Text
              component="span"
              color="red"
              style={{ fontSize: 8, marginLeft: 5 }}
            >
              ({unIndexedKanjis})
            </Text>
          )}
        </Text>
      </Stack>
    );
  };

  return (
    <Container>
      <Container py="sm" style={{ minHeight: 100 }}>
        <SimpleGrid cols={1} spacing={5}>
          <Text
            style={{
              fontSize: 30,
              fontWeight: 800,
            }}
          >
            Kanji Index
          </Text>
          <Text
            style={{
              fontSize: 12,
            }}
          >
            Kanji index organized by various categories
          </Text>
        </SimpleGrid>
      </Container>
      <Container>
        <Tabs
          active={activeTab}
          onTabChange={onChange}
          variant="unstyled"
          tabPadding="xs"
          classNames={{
            root: classes.tabs,
            tabsListWrapper: classes.tabsList,
            tabControl: classes.tabControl,
            tabActive: classes.tabActive,
          }}
        >
          <Tabs.Tab
            label={indexLabel(`RRTK`, kanji.rrtk)}
            title="RRTK Kanji"
            tabKey="rrtk"
          >
            <KanjiIndex kanjis={kanji.rrtk} page={pageNumber} />
          </Tabs.Tab>
          <Tabs.Tab
            label={indexLabel(`Mastery`, kanji.mastery)}
            title="Kanji to Mastery"
            tabKey="Mastery"
          >
            <KanjiIndex kanjis={kanji.mastery} page={pageNumber} />
          </Tabs.Tab>
          <Tabs.Tab
            label={indexLabel(`Top 777`, kanji.top_777)}
            title="Top 777"
            tabKey="top_777"
          >
            <KanjiIndex kanjis={kanji.top_777} page={pageNumber} />
          </Tabs.Tab>
          <Tabs.Tab
            label={indexLabel(`Frequency`, kanji.frequency)}
            title="Kanji by Frequency"
            tabKey="frequency"
          >
            <KanjiIndex kanjis={kanji.frequency} page={pageNumber} />
          </Tabs.Tab>
          <Tabs.Tab
            label={indexLabel(`Jouyou`, kanji.jouyou)}
            title="Jouyou Kanji"
            tabKey="jouyou"
          >
            <KanjiIndex kanjis={kanji.jouyou} page={pageNumber} />
          </Tabs.Tab>
          <Tabs.Tab
            label={indexLabel(`Tobira Pre`, kanji.tobira_pre)}
            title="Tobira Pre-requisites Kanji"
            tabKey="tobira_pre"
          >
            <KanjiIndex kanjis={kanji.tobira_pre} page={pageNumber} />
          </Tabs.Tab>
          <Tabs.Tab
            label={indexLabel(`Tobira`, kanji.tobira)}
            title="Tobira Kanji"
            tabKey="tobira"
          >
            <KanjiIndex kanjis={kanji.tobira} page={pageNumber} />
          </Tabs.Tab>
          {[5, 4, 3, 2, 1].map((x, idx) => {
            const kanjis = _.get(kanji, `jlpt.n${x}`);

            return (
              <Tabs.Tab
                key={idx}
                label={indexLabel(`N${x}`, kanjis)}
                title={`N${x} Kanji`}
                tabKey={`n${x}`}
              >
                <KanjiIndex kanjis={kanjis} page={pageNumber} />
              </Tabs.Tab>
            );
          })}
        </Tabs>
      </Container>
    </Container>
  );
};

export default Layout;
