// Vocabulary graph model

import cy from "cytoscape";
import dagre from "cytoscape-dagre";
import { GraphData } from "framework/types/graph";
import { extractKanjis } from "libs/japanese";
import _ from "lodash";
const { nanoid } = require("nanoid");

// Enable dagre
cy.use(dagre);

const createVocabularyGraph = (
  { nodes, edges }: GraphData,
  words: string[]
) => {
  const graph = cy();
  const characterNodesByElement = _.keyBy(nodes, "element");

  const characterNodes = _.chain(nodes)
    .map((x) => {
      return { ...x, type: "symbol" };
    })
    .value();

  const wordNodes = _.chain(words)
    .map((x) => {
      return { id: nanoid(12), word: x, type: "word" };
    })
    .value();

  const newEdges = _.chain(wordNodes)
    .map((x) => ({ ...x, kanjis: extractKanjis([x.word]) }))
    .flatMap((x) => {
      const inIds = _.chain(x.kanjis)
        .map((x) => characterNodesByElement[x])
        .compact() // In case there are no symbols for the specified kanji
        .map((x) => x.id)
        .value();

      return _.chain(inIds)
        .map((inId) => ({
          id: nanoid(12),
          target: inId,
          source: x.id,
        }))
        .value();
    })
    .value();

  const combinedNodes = [...characterNodes, ...wordNodes];
  const combinedEdges = [...edges, ...newEdges];

  _.each(combinedNodes, (node) => graph.add({ group: "nodes", data: node }));
  _.each(combinedEdges, (edge) => graph.add({ group: "edges", data: edge }));

  // Set the layout for this graph
  graph.layout({ name: "dagre" }).run();

  return graph;
};

export { createVocabularyGraph };
