import { Blockquote, Box, Center, Container, Stack, Text } from "@mantine/core";
import SymbolGym from "components/gym/SymbolGym";
import WordGym from "components/gym/WordGym";
import { Core } from "cytoscape";
import { DictionaryWord } from "framework/types/dictionary";
import { useAtomValue } from "jotai";
import { createVocabularyGraph } from "libs/graph/vocabulary.model";
import { extractKanjis } from "libs/japanese";
import { generateSymbolGraph, getSymbolDataRecursive } from "libs/symbol";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useFirestore } from "reactfire";
import knowledgeAtom from "state/jotai/knowledge";

interface VocabularyGymProps {
  words: DictionaryWord[];
}

const VocabularyGym = ({ words }: VocabularyGymProps) => {
  const firestore = useFirestore();
  const knowledge = useAtomValue(knowledgeAtom);

  const [graph, setGraph] = useState<Core>();

  useEffect(() => {
    (async () => {
      const readings = _.chain(words).map("reading").value();
      const knownReadings = knowledge?.words || [];
      const unknownReadings = _.difference(readings, knownReadings);
      const wordKanjis = extractKanjis(unknownReadings);

      const symbols = await getSymbolDataRecursive(firestore, wordKanjis);
      const symbolGraph = await generateSymbolGraph(symbols);

      const reversedEdges = _.chain(symbolGraph.links)
        .map(({ id, source, target }) => ({
          id,
          source: target,
          target: source,
        }))
        .value();

      const graph = createVocabularyGraph(
        {
          nodes: symbolGraph.nodes,
          edges: reversedEdges,
        },
        unknownReadings
      );

      // Scratch the graph of there is data
      graph.scratch("data", _.size(words) > 0);

      setGraph(graph);
    })();
  }, [firestore, words, knowledge]);

  const nextLeaf = useMemo(() => {
    if (graph) {
      _.each(knowledge?.symbols || [], (x) => {
        graph.remove(graph.elements(`node[id='${x}']`));
      });

      let leaves = graph.nodes().leaves();

      const result = _.chain(leaves)
        .map((x) => x.data())
        .orderBy("type", ["desc"])
        .first()
        .value();

      return !_.isUndefined(result)
        ? result
        : graph.scratch("data") // When the graph has been loaded
        ? null
        : undefined;
    }
  }, [graph, knowledge]);

  const Component = useMemo(() => {
    if (nextLeaf) {
      if (nextLeaf.type === "word") {
        return <WordGym reading={nextLeaf.word} />;
      }

      return <SymbolGym symbol={nextLeaf} />;
    }
  }, [nextLeaf]);

  const status = useMemo(() => {
    if (_.isEmpty(words)) {
      return "add";
    } else {
      if (_.isUndefined(nextLeaf)) {
        return "loading";
      }

      if (graph && _.isNull(nextLeaf)) {
        return "complete";
      }

      if (nextLeaf) {
        return "learning";
      }
    }
  }, [words, graph, nextLeaf]);

  return (
    <Box style={{ width: "100%", minHeight: 500 }} mt={25}>
      {status === "learning" && (
        <Stack spacing={0}>
          <Center>
            <Blockquote color="violet">
              Alright! Let's learn this <b>{nextLeaf.type}</b> next!
              <Text size="xs">
                Click around, explore, make up stories and when you think you
                have memorized this shape, mark it as learned!
              </Text>
            </Blockquote>
          </Center>
          <Container mt={25} style={{ width: "100%" }}>
            {nextLeaf && Component && <>{Component}</>}
          </Container>
        </Stack>
      )}
      {status === "complete" && (
        <Stack spacing={0}>
          <Center>
            <Blockquote color="violet">
              Congratulations! 💃
              <Text size="xs">
                Looks like you demolished your vocabulary list so go on and read
                some Japanese!
              </Text>
            </Blockquote>
          </Center>
        </Stack>
      )}
      {status === "loading" && (
        <Stack spacing={0}>
          <Center>
            <Blockquote color="violet">
              🧠 Thinking..
              <Text size="xs">
                Let me check on your progress and surface the next item you need
                to learn!
              </Text>
            </Blockquote>
          </Center>
        </Stack>
      )}
      {status === "add" && (
        <Stack spacing={0}>
          <Center>
            <Blockquote color="violet">
              Words! you got none... 😢
              <Text size="xs">
                Go on then, search for something and make it part of your
                vocabulary.
              </Text>
            </Blockquote>
          </Center>
        </Stack>
      )}
    </Box>
  );
};

export default VocabularyGym;
