import { Container, Space, Title } from "@mantine/core";
import RandomText from "components/random/RandomText";
import React from "react";

const Overview = () => {
  return (
    <Container>
      <Title order={2}>Dashboard Overview</Title>
      <Space h={20} />
      <RandomText count={4} props={{ size: "xs" }} />
    </Container>
  );
};

export default Overview;
