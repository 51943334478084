import {
  Anchor,
  Box,
  Center,
  Container,
  ScrollArea,
  SimpleGrid,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useFocusTrap, useMergedRef } from "@mantine/hooks";
import { IconSearch } from "@tabler/icons";
import VocabularyBar from "components/vocabulary/VocabularyBar";
import WordCard from "components/vocabulary/WordCard";
import { DictionaryWord } from "framework/types/dictionary";
import { useAtomValue } from "jotai";
import { searchTranslation } from "libs/japanese";
import _ from "lodash";
import React, { useCallback, useRef, useState } from "react";
import fuseAtom from "state/jotai/fuse";

const SearchBox = () => {
  const inputRef = useRef<any>();
  const focusRef = useFocusTrap();
  const mergedRef = useMergedRef(inputRef, focusRef);

  const [words, setWords] = useState<DictionaryWord[]>([]);
  const fuse = useAtomValue(fuseAtom);

  const form = useForm({
    initialValues: {
      query: "",
    },
  });

  const search = useCallback(
    (value) => {
      (async () => {
        inputRef.current.select();

        if (fuse.search) {
          const searchString = searchTranslation(value);
          console.debug("Searching for string ", searchString);
          const fuseResults = fuse.search(searchString);
          const results = _.chain(fuseResults)
            .orderBy((x) => x.item.common, ["desc"])
            .map((x) => x.item as DictionaryWord)
            .take(15)
            .value();

          setWords(results);
        }
      })();
    },
    [fuse]
  );

  return (
    <ScrollArea
      style={{ height: "100vh" }}
      offsetScrollbars
      scrollbarSize={4}
      scrollHideDelay={0}
    >
      <Container py="sm">
        <SimpleGrid cols={1} spacing={5}>
          <Text
            align="center"
            style={{
              fontSize: 30,
              fontWeight: 800,
            }}
          >
            Jisho
          </Text>
          <Text
            align="center"
            style={{
              fontSize: 12,
            }}
          >
            Search for words using the dictionary
          </Text>
          <form onSubmit={form.onSubmit(({ query }) => search(query))}>
            <TextInput
              ref={mergedRef}
              mt={10}
              size="lg"
              styles={{
                input: {
                  textAlign: "center",
                },
              }}
              {...form.getInputProps("query")}
            />
          </form>
        </SimpleGrid>
        {!_.isEmpty(words) && (
          <SimpleGrid cols={2} my={50} p={0}>
            {words.map((word, idx) => {
              return (
                <Box key={idx} style={{ width: "100%" }}>
                  <WordCard word={word} maxMeanings={5}>
                    <VocabularyBar word={word} />
                  </WordCard>
                </Box>
              );
            })}
          </SimpleGrid>
        )}
        {_.isEmpty(words) && (
          <Container py={100}>
            <SimpleGrid mt={150}>
              <Center>
                <IconSearch size={75} color="gray" />
              </Center>
              <Title align="center" order={4}>
                Search Dictionary
              </Title>
              <Text
                align="center"
                size="sm"
                mt={-10}
                color="#909090"
                style={{
                  fontFamily: "uddigikyokasho-pro",
                  fontWeight: "bold",
                }}
              >
                Type your query in english, かな or 漢字
              </Text>
            </SimpleGrid>
          </Container>
        )}
        <SimpleGrid mt={50} mb={25}>
          <Center>
            <Text size="xs" align="center" color="gray" style={{ width: 600 }}>
              This site uses the
              <Anchor
                mx={5}
                size="xs"
                href="http://www.edrdg.org/wiki/index.php/JMdict-EDICT_Dictionary_Project"
              >
                JMdict/EDICT
              </Anchor>
              and
              <Anchor
                mx={5}
                size="xs"
                href="http://www.edrdg.org/wiki/index.php/KANJIDIC_Project"
              >
                KANJIDIC
              </Anchor>
              dictionary files. These files are the property of the
              <Anchor mx={5} size="xs" href="http://www.edrdg.org/">
                Electronic Dictionary Research and Development Group
              </Anchor>
              , and are used in conformance with the Group's
              <Anchor
                mx={5}
                size="xs"
                href="http://www.edrdg.org/edrdg/licence.html"
              >
                licence
              </Anchor>
            </Text>
          </Center>
        </SimpleGrid>
      </Container>
    </ScrollArea>
  );
};

export default SearchBox;
