import { Box, Center, createStyles, Grid, SimpleGrid } from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons";
import { kanjivg } from "framework/data/loader";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import KanjiVG from "./KanjiVG";

const kanjiVGSelectStyle = createStyles((theme) => ({
  tile: {
    minHeight: 60,
    minWidth: 60,
    padding: "6px",
    borderRadius: theme.radius.md,
    "&:hover": {
      cursor: "pointer",
      background: theme.fn.rgba(theme.colors.violet[0], 0.75),
    },
  },

  group: {
    border: "1px solid",
    borderColor: theme.fn.rgba(theme.colors.violet[4], 0.5),
  },

  active: {
    background: theme.fn.rgba(theme.colors.violet[1], 0.5),
  },

  disabled: {
    background: theme.fn.rgba(theme.colors.red[1], 0.4),
    "&:hover": {
      cursor: "not-allowed",
      background: theme.fn.rgba(theme.colors.red[1], 0.4),
    },
  },

  icon: {
    marginTop: -15,
    marginBottom: -10,
    color: theme.colors.green[8],
  },

  shape: {
    marginLeft: 5,
    boxShadow: theme.shadows.xs,
    borderRadius: theme.radius.md,
    background: theme.fn.rgba(theme.colors.violet[1], 0.25),
  },
}));

interface KanjiVGSelectProps {
  source: string;
  character: string;
  values?: Array<string>;
  disabled?: boolean;
  disabledValues?: Array<string>;
  onChange?: (values: Array<string>) => void;
}

const KanjiVGSelect = ({
  source,
  character,
  values,
  disabled,
  disabledValues,
  onChange,
}: KanjiVGSelectProps) => {
  const [selectedValues, setValues] = useState<Array<string>>([]);
  const { classes, cx } = kanjiVGSelectStyle();

  useEffect(() => {
    if (values) {
      setValues(values);
    }
  }, [values]);

  const kids: Array<string> = useMemo(() => {
    const paths = _.get(kanjivg, `${character}.paths`);

    return _.chain(paths)
      .groupBy("group")
      .mapValues((x: any) => _.map(x, "id"))
      .toPairs()
      .flattenDeep()
      .value();
  }, [character]);

  const onClick = (kid: string, add: boolean) => {
    const newValues = add
      ? _.concat(selectedValues, kid)
      : _.filter(selectedValues, (x) => x !== kid);
    setValues(_.uniq(newValues));
    onChange && onChange(_.uniq(newValues));
  };

  return (
    <Box>
      <Grid>
        <Grid.Col span={10}>
          <SimpleGrid
            cols={9}
            spacing="md"
            breakpoints={[
              { maxWidth: 980, cols: 8, spacing: "sm" },
              { maxWidth: 880, cols: 7, spacing: "sm" },
              { maxWidth: 800, cols: 6, spacing: "sm" },
            ]}
          >
            {kids.map((kid, idx) => {
              const fragmentSelected = selectedValues.includes(kid);
              const fragmentDisabled =
                disabledValues?.includes(kid) || disabled;
              const isGroup = _.chain(kid)
                .split("-")
                .last()
                .startsWith("g")
                .value();

              return (
                <Box
                  key={idx}
                  className={cx(classes.tile, {
                    [classes.active]: fragmentSelected,
                    [classes.disabled]: fragmentDisabled,
                    [classes.group]: isGroup,
                  })}
                  onClick={() =>
                    !fragmentDisabled ? onClick(kid, !fragmentSelected) : null
                  }
                >
                  <Center>
                    <KanjiVG
                      width={60}
                      height={60}
                      key={idx}
                      source={source}
                      ids={[kid]}
                    />
                  </Center>
                  {fragmentSelected && (
                    <Box className={classes.icon}>
                      <IconCircleCheck size={15} />
                    </Box>
                  )}
                </Box>
              );
            })}
          </SimpleGrid>
        </Grid.Col>
        <Grid.Col span={2}>
          <Center className={classes.shape}>
            <KanjiVG
              width={150}
              height={150}
              source={source}
              ids={selectedValues}
              invert
            />
          </Center>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default KanjiVGSelect;
