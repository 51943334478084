import { createStyles, Text } from "@mantine/core";
import usePos from "hooks/markup/use-pos";
import { hasFurigana } from "libs/token";
import _ from "lodash";
import WithoutRuby from "./ruby/WithoutRuby";
import WithRuby from "./ruby/WithRuby";
import { PosMarkerProps } from "./types";

const useStyles = createStyles((theme) => ({
  text: {
    textShadow: theme.shadows.xs,
    paddingTop: 2,
    paddingBottom: 2,
  },

  highlight: {
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: theme.radius.md,
    backgroundColor: theme.fn.rgba(theme.colors.yellow[1], 0.75),
  },
}));

const PosMarker = ({
  token,
  hide,
  show,
  cloze,
  highlight,
  hideFurigana,
  ...props
}: PosMarkerProps) => {
  const { classes, cx } = useStyles();
  const { color: posColor, pos } = usePos();

  const color = _.get(posColor, _.get(token.pos, "0"));
  const underline = _.keys(pos).includes(_.get(token.pos, "0"));
  const shouldHighlight = highlight?.includes(token.base_form) || false;
  const shouldShow = show?.includes(token.base_form) || false;
  const shouldHide = hide?.includes(token.base_form) || false;
  const shouldCloze = cloze?.includes(token.base_form) || false;

  const withRuby = _.some([
    hideFurigana && shouldShow && hasFurigana(token),
    !hideFurigana && !shouldHide && hasFurigana(token),
  ]);

  const display = shouldCloze ? "cloze" : withRuby ? "ruby" : "noruby";
  return (
    <Text
      component="span"
      className={cx(classes.text, { [classes.highlight]: shouldHighlight })}
    >
      {display === "cloze" && (
        <Text
          {...props}
          variant="gradient"
          gradient={{ from: "indigo", to: "cyan", deg: 45 }}
          sx={(theme) => ({
            fontWeight: "bold",
          })}
        >
          [[ ... ]]
        </Text>
      )}

      {display === "ruby" && (
        <WithRuby
          token={token}
          color={color}
          underline={underline}
          {...props}
        />
      )}

      {display === "noruby" && (
        <WithoutRuby
          token={token}
          color={color}
          underline={underline}
          {...props}
        />
      )}
    </Text>
  );
};

export default PosMarker;
