import {
  Alert,
  Button,
  Container,
  Group,
  Space,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import {
  IconCircleCheck,
  IconCloudDownload,
  IconEraser,
  IconSpeedboat,
} from "@tabler/icons";
import { resetKnowledge } from "database/actions/knowledge";
import dayjs from "dayjs";
import { getAuth } from "firebase/auth";
import { collection, getDocs } from "firebase/firestore";
import fileDownload from "js-file-download";
import _ from "lodash";
import React from "react";
import { useFirestore } from "reactfire";

const Settings = () => {
  const auth = getAuth();
  const firestore = useFirestore();
  const modals = useModals();

  const resetKnowledgeConfirmation = () =>
    modals.openConfirmModal({
      title: "Reset Knowledge",
      centered: true,
      children: (
        <Text size="xs">
          Are you sure you want to reset your <b>Knowledge data</b>? <br />
          <Text component="span" style={{ fontSize: 11 }}>
            This action is not reversible, please proceed with caution.
          </Text>
        </Text>
      ),
      labels: {
        confirm: "Reset Knowledge",
        cancel: "Cancel",
      },
      confirmProps: { color: "red", style: { fontSize: 12 } },
      cancelProps: { style: { fontSize: 12 } },
      onConfirm: () => reset(),
    });

  const reset = async () => {
    if (auth.currentUser) {
      await resetKnowledge(firestore, auth.currentUser);
      showNotification({
        styles: {
          title: {
            fontWeight: "bold",
          },
          description: {
            fontSize: 12,
          },
        },
        color: "green",
        icon: <IconCircleCheck />,
        title: "Knowledge Reset",
        message: "All of your knowledge has been successfully reset!",
      });
    }
  };

  const downloadSymbols = async () => {
    const symbolsCollection = collection(firestore, "symbols");
    const querySnapshot = await getDocs(symbolsCollection);
    const symbols = _.chain(querySnapshot.docs)
      .map((x) => x.data())
      .value();

    const blob = new Blob([JSON.stringify(symbols, null, 2)], {
      type: "application/json;charset=utf-8",
    });

    fileDownload(blob, `symbols-${dayjs().toISOString()}.json`);
  };

  return (
    <Container>
      <Title order={2}>User Settings</Title>
      <Space h={20} />
      <Alert
        icon={<IconSpeedboat size={16} />}
        title="Performance"
        color="pink"
        variant="light"
      >
        <Text size="xs">
          Consider downloading data to your browser to speed up rendering of SVG
          assets.
        </Text>
      </Alert>

      <Stack mt={50}>
        <Title order={5}>Reset Knowledge 💣</Title>
        <Text size="xs">
          Reset all the words, symbols and keywords you have learned so far,
          this action is irreversible.
        </Text>
        <Group>
          <Button
            size="xs"
            color="red"
            variant="filled"
            onClick={resetKnowledgeConfirmation}
            leftIcon={<IconEraser />}
          >
            Reset Knowledge
          </Button>
        </Group>
      </Stack>

      <Stack mt={50}>
        <Title order={5}>
          Download Symbols
          <IconCloudDownload style={{ marginLeft: 10 }} size={18} />
        </Title>
        <Text size="xs">Download all the symbols from the application.</Text>
        <Group>
          <Button
            size="xs"
            color="blue"
            variant="filled"
            onClick={downloadSymbols}
            leftIcon={<IconCloudDownload />}
          >
            Download Symbols
          </Button>
        </Group>
      </Stack>
    </Container>
  );
};

export default Settings;
