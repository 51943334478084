import {
  Anchor,
  Box,
  Container,
  createStyles,
  Divider,
  Grid,
  Group,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { IconArrowLeft, IconFingerprint } from "@tabler/icons";
import KeywordKnowledgeIcon from "components/knowledge/KeywordKnowledgeIcon";
import _ from "lodash";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFireStoreKeywordData from "../hooks/use-firestore-keyword-data";
import KanjiSymbolCard from "./KanjiSymbolCard";

const useStyles = createStyles((theme) => ({
  keywordLogo: {
    textAlign: "center",
    verticalAlign: "top",
    marginTop: 20,
    marginBottom: 20,
  },
  keywordCaption: {
    fontSize: 12,
    fontWeight: 800,
    color: "#909090",
  },
  keyword: {
    fontSize: 40,
    fontWeight: 800,
  },
  title: {
    color: "#656565",
    fontSize: 14,
    fontWeight: 800,
  },
  caption: {
    fontSize: 11,
    color: "#A0A0A0",
  },
}));

const Keyword = () => {
  const { classes } = useStyles();

  const navigate = useNavigate();
  const { keyword } = useParams();

  const { symbolsData, subSymbolsData } = useFireStoreKeywordData(
    keyword || ""
  );

  return (
    <Container>
      <SimpleGrid cols={1} my={10} mx={10}>
        <Grid>
          <Grid.Col span={3}>
            <Text className={classes.keywordLogo}>
              <IconFingerprint color="gray" size={150} />
            </Text>
          </Grid.Col>
          <Grid.Col span={9}>
            <SimpleGrid mt={20} spacing={0}>
              <Anchor onClick={() => navigate(-1)} size="xs" mb={10}>
                <Group spacing={5}>
                  <IconArrowLeft size={16} />
                  Back
                </Group>
              </Anchor>
              <Text className={classes.keyword}>{keyword}</Text>
              <Text className={classes.keywordCaption}>Keyword</Text>
              {keyword && (
                <Group mt={25}>
                  <KeywordKnowledgeIcon keyword={keyword} />
                </Group>
              )}
            </SimpleGrid>
          </Grid.Col>
        </Grid>
        <Divider my={10} mx={10} />
        <Container style={{ width: "100%" }}>
          {!_.isEmpty(symbolsData) && (
            <Box>
              <SimpleGrid cols={1} spacing={0} mb={20}>
                <Text className={classes.title}>Symbol</Text>
                <Text className={classes.caption}>Symbol Data</Text>
              </SimpleGrid>
              <SimpleGrid cols={5}>
                {symbolsData.map((x, idx) => {
                  return <KanjiSymbolCard key={idx} data={x as any} />;
                })}
              </SimpleGrid>
            </Box>
          )}

          {!_.isEmpty(subSymbolsData) && (
            <Box mt={20}>
              <SimpleGrid cols={1} spacing={0} mb={20}>
                <Text className={classes.title}>Usage and Variants</Text>
                <Text className={classes.caption}>
                  This element is also a part of all of these symbols (kanji)
                </Text>
              </SimpleGrid>
              <SimpleGrid cols={5}>
                {subSymbolsData.map((x, idx) => {
                  return <KanjiSymbolCard key={idx} data={x as any} />;
                })}
              </SimpleGrid>
            </Box>
          )}
        </Container>
      </SimpleGrid>
    </Container>
  );
};

export default Keyword;
