import { Container, useMantineTheme } from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import * as d3 from "d3";
import _ from "lodash";
import React, { createRef, RefObject, useEffect } from "react";
import "./kanji-flow.scss";

interface KanjiFlowProps {
  graph: Graph;
  useGlyphs?: boolean;
}

const KanjiFlow = ({ graph, useGlyphs }: KanjiFlowProps) => {
  const theme = useMantineTheme();
  const ref: RefObject<any> = createRef<SVGGElement>();
  const {
    ref: flowRef,
    width: flowWidth,
    height: flowHeight,
  } = useElementSize();

  useEffect(() => {
    const svgRef = ref.current;
    const { nodes, links } = _.cloneDeep(graph);

    const drag = (simulation: any) => {
      function dragstarted(event: any) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        event.subject.fx = event.subject.x;
        event.subject.fy = event.subject.y;
      }

      function dragged(event: any) {
        event.subject.fx = event.x;
        event.subject.fy = event.y;
      }

      function dragended(event: any) {
        if (!event.active) simulation.alphaTarget(0);
        event.subject.fx = null;
        event.subject.fy = null;
      }

      return d3
        .drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
    };

    const simulation = d3
      .forceSimulation<SimNode, SimLink>(nodes)
      .force(
        "link",
        d3
          .forceLink<SimNode, SimLink>(links)
          .id((d) => d.id)
          .distance(150)
      )
      .force("collide", d3.forceCollide(50))
      .force("charge", d3.forceManyBody().strength(25))
      .force("center", d3.forceCenter(flowWidth / 2, flowHeight / 2));

    const svg = d3
      .select(ref.current)
      .append("svg")
      .attr("width", flowWidth)
      .attr("height", flowHeight);

    const link = svg
      .append("g")
      .selectAll("line")
      .data(links)
      .enter()
      .append("line")
      .attr("stroke", theme.colors.violet[3])
      .attr("stroke-opacity", 0.8)
      .attr("stroke-width", (d) => 2)
      .attr("class", "link")
      .attr("marker-end", (d) => "url(#triangle)");

    const kanjiNode = svg
      .selectAll("g.kanji-node")
      .data(nodes)
      .enter()
      .append("g")
      .attr("class", "kanji-node")
      .attr("stroke", theme.colors.gray[5])
      .attr("stroke-opacity", 0.6);

    const kanjiCircle = kanjiNode
      .append("circle")
      .attr("stroke", (d: any) => theme.colors.violet[3])
      .attr("stroke-width", (d: any) => 3)
      .attr("r", 30)
      .attr("cx", (d) => d.x || null)
      .attr("cy", (d) => d.y || null)
      .attr("fill", (d: any) => {
        return theme.colors.violet[0];
      });

    const kanjiText = kanjiNode
      .append("g")
      .attr("class", "kanji-node-label")
      .html((d: any, idx, elements) => {
        const assignedText = d.element;

        if (assignedText && !useGlyphs) {
          return `
          <text 
            style="font-family: uddigikyokasho-pro; font-size: 30px;" 
            dx="-15" dy="10">
              ${assignedText}
          </text>`;
        } else {
          return d.glyph;
        }
      });

    const kanjiKeyword = kanjiNode
      .append("g")
      .attr("class", "kanji-keyword-label")
      .html((d: any, idx, elements) => {
        const assignedKeyword = d.keyword;

        return `
          <text 
            style="font-family: capitana; font-size: 11px; font-weight: bold;" 
            dy="50" dominant-baseline="middle" text-anchor="middle">
              ${assignedKeyword}
          </text>`;
      });

    kanjiNode.call(drag(simulation) as any);

    simulation.on("tick", () => {
      kanjiCircle.attr("cx", (d: any) => d.x).attr("cy", (d: any) => d.y);

      kanjiKeyword
        .selectChild("text")
        .attr("x", (d: any) => d.x)
        .attr("y", (d: any) => d.y);

      kanjiText
        .selectChild("text")
        .attr("x", (d: any) => d.x)
        .attr("y", (d: any) => d.y);

      kanjiText
        .selectChild("svg")
        .attr("height", 40)
        .attr("x", (d: any) => d.x - 55)
        .attr("y", (d: any) => d.y - 20);

      link
        .attr("x1", (d: any) => d.source.x)
        .attr("y1", (d: any) => d.source.y)
        .attr("x2", (d: any) => d.target.x)
        .attr("y2", (d: any) => d.target.y);
    });

    return () => {
      d3.select(svgRef).select("svg").remove();
    };
  }, [ref, theme, flowHeight, flowWidth, graph, useGlyphs]);

  return (
    <Container px={0} ref={flowRef} className="kanji-flow-viewer">
      <svg className="kanji-flow">
        <defs>
          <marker
            id="triangle"
            refX="40"
            refY="6"
            markerWidth="30"
            markerHeight="30"
            markerUnits="userSpaceOnUse"
            orient="auto"
          >
            <path
              d="M 0 0 12 6 0 12 3 6"
              style={{ fill: theme.colors.violet[5] }}
            ></path>
          </marker>
        </defs>
        <g ref={ref}></g>
      </svg>
    </Container>
  );
};

export default KanjiFlow;
