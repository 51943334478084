import { Box, Center, Loader, TextProps } from "@mantine/core";
import SentenceMarkup from "components/markup/sentence/SentenceMarkup";
import { TokenizeResult } from "framework/types/sentence";
import { tokenize } from "libs/sentence";
import _ from "lodash";
import React, { useEffect, useState } from "react";

interface TokenSentenceProps extends TextProps<"div"> {
  sentence: string;
  reading?: string;
  hide?: string[];
  show?: string[];
  cloze?: string[];
  highlight?: string[];
  hideFurigana?: boolean;
}

const TokenSentence = ({
  sentence,
  reading,
  hide,
  show,
  cloze,
  highlight,
  hideFurigana,
  ...props
}: TokenSentenceProps) => {
  const [tokenizeResult, setTokenizeResult] = useState<TokenizeResult>();

  useEffect(() => {
    (async () => {
      setTokenizeResult(undefined);
      const tokens = await tokenize(sentence);
      await new Promise((r) => setTimeout(r, 250));
      setTokenizeResult(tokens);
    })();
  }, [sentence, setTokenizeResult]);

  return (
    <>
      {_.isUndefined(tokenizeResult) && (
        <Box style={{ width: "75%" }} mt={5}>
          <Center>
            <Loader color="violet" variant="bars" />
          </Center>
        </Box>
      )}
      {tokenizeResult && (
        <SentenceMarkup
          sentence={tokenizeResult.sentence}
          reading={reading}
          tokens={tokenizeResult.data.tokens}
          hide={hide}
          show={show}
          cloze={cloze}
          highlight={highlight}
          hideFurigana={hideFurigana}
          {...props}
        />
      )}
    </>
  );
};

export default TokenSentence;
