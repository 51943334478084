import {
  ActionIcon,
  Badge,
  Card,
  Container,
  createStyles,
  Divider,
  Group,
  Text,
} from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons";
import VocabularyTags from "components/vocabulary/VocabularyTags";
import { Vocabulary } from "database/models/vocabulary";
import { useAtomValue } from "jotai";
import _ from "lodash";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import knowledgeAtom from "state/jotai/knowledge";
import { formatTimestamp } from "utils/date";

const useStyles = createStyles((theme) => ({
  card: {
    border: "1px solid",
    borderColor: theme.colors.violet[1],
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
  },
  description: {
    fontSize: 11,
    minHeight: 30,
    color: theme.colors.gray[8],
  },
  date: {
    marginTop: 10,
    fontSize: 10,
    color: theme.colors.gray[6],
  },
  actions: {
    background: theme.fn.rgba(theme.colors.violet[1], 0.2),
  },
}));

interface VocabularyListCardProps {
  vocabulary: Vocabulary;
  editVocabulary: (x: Vocabulary) => void;
  deleteVocabulary: (x: Vocabulary) => void;
}

const VocabularyListCard = ({
  vocabulary,
  editVocabulary,
  deleteVocabulary,
}: VocabularyListCardProps) => {
  const { classes } = useStyles();
  const knowledge = useAtomValue(knowledgeAtom);
  const size = useMemo(() => _.size(vocabulary.words), [vocabulary]);
  const pendingSize = useMemo(() => {
    return _.chain(vocabulary.words)
      .difference(knowledge?.words || [])
      .size()
      .value();
  }, [vocabulary, knowledge?.words]);

  return (
    <Container style={{ width: "100%" }} fluid>
      <Card shadow="sm" p="lg" className={classes.card}>
        <Card.Section p="sm">
          <Text
            component={Link}
            to={`/vocabulary/${vocabulary.id}`}
            className={classes.title}
          >
            {vocabulary.title}
          </Text>
          <Text lineClamp={2} className={classes.description} size="sm">
            {vocabulary.description}
          </Text>
          <Text className={classes.date} size="xs">
            {formatTimestamp(vocabulary.created?.toDate())}
          </Text>
        </Card.Section>
        <Card.Section>
          <VocabularyTags readings={vocabulary.words} radius={0} />
        </Card.Section>
        <Card.Section>
          <Divider></Divider>
        </Card.Section>
        <Card.Section p={5} className={classes.actions}>
          <Group position="apart">
            <ActionIcon
              color="red"
              variant="hover"
              onClick={() => deleteVocabulary(vocabulary)}
            >
              <IconTrash size={15} />
            </ActionIcon>
            <Group>
              <Badge size="xs" variant="light" color="violet">
                {size} Word{size === 1 ? "" : "s"}
              </Badge>
              {pendingSize > 0 && (
                <Badge size="xs" variant="light" color="green">
                  {pendingSize} Pending
                </Badge>
              )}
            </Group>
            <ActionIcon
              color="violet"
              variant="hover"
              onClick={() => editVocabulary(vocabulary)}
            >
              <IconEdit size={15} />
            </ActionIcon>
          </Group>
        </Card.Section>
      </Card>
    </Container>
  );
};

export default VocabularyListCard;
