import { Center, Container, Loader } from "@mantine/core";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { fetchAndActivate, getRemoteConfig } from "firebase/remote-config";
import { getStorage } from "firebase/storage";
import React from "react";
import {
  AnalyticsProvider,
  AuthProvider,
  FirestoreProvider,
  FunctionsProvider,
  RemoteConfigProvider,
  StorageProvider,
  useFirebaseApp,
  useInitPerformance,
  useInitRemoteConfig,
} from "reactfire";

interface FirebaseProps {
  children: JSX.Element;
}

const Firebase = ({ children }: FirebaseProps) => {
  // Parent component contains a FirebaseAppProvider
  const app = useFirebaseApp();

  // Initialize the firebase components
  const auth = getAuth(app);
  const db = getFirestore(app);
  const functions = getFunctions(app);
  const storage = getStorage(app);
  const analytics = getAnalytics(app);

  const { status, data: remoteConfigInstance } = useInitRemoteConfig(
    async (firebaseApp) => {
      const remoteConfig = getRemoteConfig(firebaseApp);
      remoteConfig.settings = {
        minimumFetchIntervalMillis: 10000,
        fetchTimeoutMillis: 10000,
      };

      await fetchAndActivate(remoteConfig);
      return remoteConfig;
    }
  );

  useInitPerformance(async (firebaseApp) => {
    const { getPerformance } = await import("firebase/performance");
    return getPerformance(firebaseApp);
  });

  if (status === "loading") {
    return (
      <Container style={{ height: "100vh", width: "100%" }}>
        <Center>
          <Loader size="xl" variant="bars" />
        </Center>
      </Container>
    );
  }

  return (
    <AnalyticsProvider sdk={analytics}>
      <AuthProvider sdk={auth}>
        <RemoteConfigProvider sdk={remoteConfigInstance}>
          <StorageProvider sdk={storage}>
            <FunctionsProvider sdk={functions}>
              <FirestoreProvider sdk={db}>{children}</FirestoreProvider>
            </FunctionsProvider>
          </StorageProvider>
        </RemoteConfigProvider>
      </AuthProvider>
    </AnalyticsProvider>
  );
};

export default Firebase;
