import {
  ActionIcon,
  Box,
  Card,
  Center,
  Group,
  SimpleGrid,
  Text,
  Tooltip,
} from "@mantine/core";
import { useModals } from "@mantine/modals";
import { IconEdit, IconTrash } from "@tabler/icons";
import KanjiVG from "components/kanjivg/KanjiVG";
import SymbolKnowledgeIcon from "components/knowledge/SymbolKnowledgeIcon";
import SymbolForm from "components/symbol/forms/SymbolForm";
import { deleteSymbol, updateSymbol } from "database/actions/symbol";
import { Symbol, UpdateSymbolProps } from "database/models/symbol";
import useKanjiVG from "hooks/kanjivg/use-kanjivg";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useFirestore } from "reactfire";
import useFireStoreKanjiOnlyData from "../hooks/use-firestore-kanji-only-data";

interface KanjiSymbolCardProps {
  data: Symbol;
  variant?: boolean;
}

const KanjiSymbolCard = ({ data, variant }: KanjiSymbolCardProps) => {
  const firestore = useFirestore();
  const modals = useModals();
  const { status, source } = useKanjiVG(data.kanji);
  const { symbolsData } = useFireStoreKanjiOnlyData(data.kanji);

  const onSubmit = async (data: UpdateSymbolProps) => {
    const filtered: any = _.omitBy(data, _.isUndefined);
    await updateSymbol(firestore, filtered);
  };

  // Modals
  const modalOptions = {
    size: 900,
    styles: {
      modal: {
        margin: 0,
        padding: `0 !important`,
      },
    },
    withCloseButton: false,
  };

  const editSymbolModal = () => {
    modals.openModal({
      ...modalOptions,
      id: "edit-symbol-modal",
      children: (
        <>
          <SymbolForm
            {...data}
            onSubmit={(x) => {
              modals.closeModal("edit-symbol-modal");
              onSubmit(x);
            }}
            segmentsDisabled={true ? _.isEmpty(data.segments) : false}
            onCancel={() => modals.closeModal("edit-symbol-modal", true)}
          />
        </>
      ),
    });
  };

  const deleteSymbolConfirmation = () =>
    modals.openConfirmModal({
      title: "Delete Symbol",
      centered: true,
      children: (
        <Text size="xs">
          Are you sure you want to delete symbol <b>{data.keyword}</b>?
        </Text>
      ),
      labels: {
        confirm: "Delete Symbol",
        cancel: "Cancel",
      },
      confirmProps: { color: "red", style: { fontSize: 12 } },
      cancelProps: { style: { fontSize: 12 } },
      onConfirm: () => deleteSymbol(firestore, data.id),
    });

  var keyword = data.keyword;
  var story = "";
  if (variant === true) {
    const variantSymbol = symbolsData[0] as Symbol;
    keyword = variantSymbol?.keyword || data.keyword;
    story = variantSymbol?.story || "";
  }

  return (
    <Card withBorder radius="md">
      <Card.Section
        sx={(theme) => ({
          background: theme.fn.rgba(theme.colors.violet[1], 0.25),
        })}
      >
        <Center>
          <Text
            component={Link}
            to={`/kanji/keyword/${keyword}`}
            px={10}
            pt={10}
            style={{
              color: "#707070",
              fontWeight: 700,
              fontSize: 12,
              textAlign: "center",
            }}
          >
            {keyword}
          </Text>
        </Center>
        <Group position="center" spacing="xs">
          {data.element && (
            <Text
              component={Link}
              to={`/kanji/info/${data.element}`}
              pb={10}
              style={{
                color: "#707070",
                fontWeight: 700,
                fontFamily: "uddigikyokasho-pro",
              }}
            >
              {data.element}
            </Text>
          )}
          <Text
            component={Link}
            to={`/kanji/info/${data.kanji}`}
            pb={10}
            style={{
              color: "#909090",
              fontWeight: 700,
              fontFamily: "uddigikyokasho-pro",
            }}
          >
            [{data.kanji}]
          </Text>
        </Group>
      </Card.Section>
      <Card.Section>
        <Center
          sx={(theme) => ({
            borderTop: `1px solid ${theme.colors.violet[1]}`,
          })}
        >
          {variant && (
            <Tooltip
              wrapLines
              width={200}
              withArrow
              arrowSize={5}
              position="bottom"
              color="violet"
              transition="fade"
              transitionDuration={200}
              label={<Text style={{ fontSize: 11 }}>{story}</Text>}
            >
              <SimpleGrid cols={1}>
                {status === "done" && (
                  <KanjiVG
                    width={125}
                    height={125}
                    source={source}
                    ids={data.segments}
                  />
                )}
              </SimpleGrid>
            </Tooltip>
          )}
          {!variant && (
            <SimpleGrid cols={1}>
              {status === "done" && (
                <KanjiVG
                  width={125}
                  height={125}
                  source={source}
                  ids={data.segments}
                />
              )}
            </SimpleGrid>
          )}
        </Center>
      </Card.Section>
      <Card.Section
        sx={(theme) => ({
          background: theme.fn.rgba(theme.colors.violet[0], 0.25),
        })}
      >
        <Box
          sx={(theme) => ({
            padding: 10,
            borderTop: `1px solid ${theme.colors.violet[1]}`,
          })}
        >
          <Group position="apart">
            <ActionIcon
              color="red"
              variant="hover"
              onClick={deleteSymbolConfirmation}
            >
              <IconTrash size={15} />
            </ActionIcon>
            <SymbolKnowledgeIcon symbol={data} />
            <ActionIcon
              color="violet"
              variant="hover"
              onClick={editSymbolModal}
            >
              <IconEdit size={15} />
            </ActionIcon>
          </Group>
        </Box>
      </Card.Section>
    </Card>
  );
};

export default KanjiSymbolCard;
