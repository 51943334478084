import { Badge, Center, createStyles, SimpleGrid, Text } from "@mantine/core";
import kanjiDB from "data/kanji/all.json";
import { kanji as kanjiData } from "framework/data/loader";
import _ from "lodash";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  tile: {
    minWidth: 100,
    maxWidth: 120,
    height: 165,
    borderRadius: theme.radius.md,
    border: "1px solid",
    borderColor: theme.fn.rgba(theme.colors.violet[2], 0.5),
    boxShadow: theme.shadows.xs,
  },
  reading: {
    height: 25,
    fontSize: 14,
    fontWeight: 800,
    paddingTop: 5,
    textAlign: "center",
    fontFamily: "a-otf-ud-shin-maru-go-pr6n",
    textShadow: theme.shadows.xs,
    color: theme.colors.gray[9],
    borderBottom: "1px solid",
    background: theme.fn.rgba(theme.colors.violet[1], 0.25),
    borderColor: theme.fn.rgba(theme.colors.violet[2], 0.5),
    zIndex: 1,
  },
  noReading: {
    height: 25,
  },
  kanji: {
    fontSize: 70,
    fontWeight: 800,
    textAlign: "center",
    fontFamily: "a-otf-ud-shin-maru-go-pr6n",
    textShadow: theme.shadows.xs,
    marginTop: -20,
    color: theme.colors.gray[8],
    background: "white",
  },
  highlighted: {
    color: theme.colors.violet[9],
    textShadow: `2px 2px 8px ${theme.fn.rgba(theme.colors.violet[2], 0.75)}`,
  },
  tag: {
    height: 20,
    paddingBottom: 5,
    background: "white",
  },
  keyword: {
    fontSize: 11,
    fontWeight: "bold",
    textAlign: "center",
    padding: 3,
    marginTop: -15,
    color: theme.colors.violet[8],
    textShadow: theme.shadows.xs,
    borderTop: "1px solid",
    borderColor: theme.fn.rgba(theme.colors.violet[2], 0.5),
    borderBottomLeftRadius: theme.radius.md,
    borderBottomRightRadius: theme.radius.md,
    background: theme.fn.rgba(theme.colors.violet[2], 0.3),
  },
}));

interface KanjiTileProps {
  kanji: string;
  reading?: string;
  highlight?: boolean;
}

const KanjiTile = ({ kanji: kanjiX, reading, highlight }: KanjiTileProps) => {
  const { classes, cx } = useStyles();
  const kanji = useMemo(
    () => _.chain(kanjiX).split("").head().value(),
    [kanjiX]
  );
  const keyword = useMemo(() => {
    return _.chain(kanjiDB)
      .get(
        `${kanji}.rtk_keyword`,
        _.chain(kanjiDB).get(`${kanji}.meanings.0`).value()
      )
      .value();
  }, [kanji]);

  const tag = useMemo(() => {
    return _.chain(kanjiData.jlpt)
      .pickBy((xs) => xs.includes(kanji))
      .keys()
      .head()
      .value();
  }, [kanji]);

  return (
    <SimpleGrid className={classes.tile} spacing={0}>
      {reading && (
        <Text
          component={Link}
          to={`/jisho/reading/${kanji}:${reading}`}
          className={classes.reading}
        >
          {reading}
        </Text>
      )}
      {!reading && <div className={classes.noReading} />}
      <Text
        component={Link}
        to={`/kanji/info/${kanji}`}
        className={cx(classes.kanji, { [classes.highlighted]: highlight })}
      >
        {kanji}
      </Text>
      <Center className={classes.tag}>
        {tag && (
          <Badge mt={-25} size="xs" radius="sm" variant="dot">
            {tag}
          </Badge>
        )}
      </Center>
      <Text
        lineClamp={2}
        component={Link}
        to={`/kanji/keyword/${keyword}`}
        className={classes.keyword}
      >
        {keyword || "😢🙏"}
      </Text>
    </SimpleGrid>
  );
};

export default KanjiTile;
