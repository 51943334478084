import { atom } from "jotai";

const fuseInstance = atom<any>({});

const fuseAtom = atom(
  (get) => get(fuseInstance),
  (get, set, newInstance: any) => {
    set(fuseInstance, newInstance);
  }
);

export default fuseAtom;
