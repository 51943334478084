import { Knowledge } from "database/models/knowledge";
import { doc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAuth, useFirestore, useFirestoreDocData } from "reactfire";

const useFirestoreKnowledge = () => {
  const { currentUser } = useAuth();
  const firestore = useFirestore();
  const [entries, setEntries] = useState<Knowledge>();

  const ref = doc(firestore, `knowledge`, `${currentUser?.uid}`);
  const { data } = useFirestoreDocData(ref, { idField: "id" });

  useEffect(() => {
    setEntries(data as Knowledge);
  }, [data]);

  return { data: entries };
};

export default useFirestoreKnowledge;
