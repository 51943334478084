import { logEvent } from "firebase/analytics";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAnalytics } from "reactfire";

export function PageViewLogger() {
  const analytics = useAnalytics();
  const location = useLocation();

  // By passing `location.pathname` to the second argument of `useEffect`,
  // we only log on first render and when the `pathname` changes
  useEffect(() => {
    const page_path = location.pathname + location.search;
    logEvent(analytics, "page-view", { path_name: page_path });
  }, [location.pathname, location.search, analytics]);

  return null;
}
