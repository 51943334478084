import { collection, DocumentData, query, where } from "firebase/firestore";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

const useFireStoreKanjiData = (kanji: string | undefined) => {
  const [symbolsData, setSymbolsData] = useState<DocumentData[]>([]);
  const [subSymbolsData, setSubSymbolsData] = useState<DocumentData[]>([]);
  const [variantsData, setVariantsData] = useState<DocumentData[]>([]);

  const firestore = useFirestore();
  const symbolsCollection = collection(firestore, "symbols");
  const kanjiSymbolsQuery = query(
    symbolsCollection,
    where("kanji", "==", kanji)
  );

  const kanjiElementsQuery = query(
    symbolsCollection,
    where("element", "==", kanji)
  );

  const { data: symbols } = useFirestoreCollectionData(kanjiSymbolsQuery, {
    idField: "id",
  });

  const { data: elementSymbols } = useFirestoreCollectionData(
    kanjiElementsQuery,
    { idField: "id" }
  );

  useEffect(() => {
    setSymbolsData(
      _.chain(symbols)
        .filter((x) => _.isEmpty(x.segments))
        .value()
    );

    setSubSymbolsData(
      _.chain(symbols)
        .filter((x) => !_.isEmpty(x.segments))
        .value()
    );
  }, [symbols]);

  useEffect(() => {
    setVariantsData(
      _.chain(elementSymbols)
        .filter((x) => !_.isEmpty(x.segments))
        .value()
    );
  }, [elementSymbols]);

  return { symbolsData, subSymbolsData, variantsData };
};

export default useFireStoreKanjiData;
