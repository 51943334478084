import { Container, Space, Title } from "@mantine/core";
import RandomText from "components/random/RandomText";
import React from "react";

const Information = () => {
  return (
    <Container>
      <Title order={2}>Profile Information</Title>
      <Space h={20} />
      <RandomText count={5} props={{ size: "xs" }} />
    </Container>
  );
};

export default Information;
