import {
  DefaultMantineColor,
  Progress,
  ProgressProps,
  useMantineTheme,
} from "@mantine/core";
import useVocabularyTagColors from "hooks/tags/use-vocabulary-tag-colors";
import { getWordsTags } from "libs/vocabulary";
import _ from "lodash";
import React, { useEffect, useState } from "react";

interface VocabularyTagsProps extends ProgressProps {
  readings: string[];
}

type ProgressSection = {
  value: number;
  color: DefaultMantineColor;
  label?: string | undefined;
};

const VocabularyTags = ({ readings, ...props }: VocabularyTagsProps) => {
  const theme = useMantineTheme();
  const tagColors = useVocabularyTagColors();
  const [tagCounts, setTagCounts] = useState<Array<ProgressSection>>([]);

  useEffect(() => {
    (async () => {
      const tags = await getWordsTags(readings);
      const jlptTags = _.chain(tags).pick(_.keys(tagColors)).value();

      const total = _.chain(jlptTags).values().sum().value();
      let sections: ProgressSection[] = _.chain(jlptTags)
        .map((value, key) => {
          return {
            value: (value / total) * 100,
            color: _.get(tagColors, key),
            label: _.toUpper(key),
          };
        })
        .value();

      if (_.isEmpty(sections)) {
        sections = [
          {
            value: 100,
            color: theme.colors.gray[6],
            label: "No JLPT Words",
          },
        ];
      }

      setTagCounts(sections);
    })();
  }, [readings, tagColors, theme.colors]);

  return (
    <Progress
      {...props}
      size={13}
      sections={tagCounts}
      styles={{
        root: {
          padding: 2,
        },
        label: {
          color: "white",
          fontWeight: "normal",
          fontSize: 9,
        },
      }}
    />
  );
};

export default VocabularyTags;
