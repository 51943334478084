import { Box, createStyles, SimpleGrid, Text } from "@mantine/core";
import _ from "lodash";
import useFireStoreKanjiData from "../hooks/use-firestore-kanji-data";
import KanjiSymbolCard from "./KanjiSymbolCard";

const useStyles = createStyles((theme) => ({
  title: {
    color: "#656565",
    fontSize: 14,
    fontWeight: 800,
  },
  caption: {
    fontSize: 11,
    color: "#A0A0A0",
  },
}));

interface KanjiSymbolProps {
  kanji: string;
}

const KanjiSymbols = ({ kanji }: KanjiSymbolProps) => {
  const { classes } = useStyles();
  const { symbolsData, subSymbolsData, variantsData } =
    useFireStoreKanjiData(kanji);

  return (
    <SimpleGrid my={10} mx={10}>
      {!_.isEmpty(symbolsData) && (
        <Box mt={10}>
          <SimpleGrid cols={1} spacing={0} mb={20}>
            <Text className={classes.title}>Symbol</Text>
            <Text className={classes.caption}>Symbol Data</Text>
          </SimpleGrid>
          <SimpleGrid cols={5}>
            {symbolsData.map((x, idx) => {
              return <KanjiSymbolCard key={idx} data={x as any} />;
            })}
          </SimpleGrid>
        </Box>
      )}

      {!_.isEmpty(subSymbolsData) && (
        <Box mt={10}>
          <SimpleGrid cols={1} spacing={0} mb={20}>
            <Text className={classes.title}>Components</Text>
            <Text className={classes.caption}>
              Sub components that make up this Symbol
            </Text>
          </SimpleGrid>
          <SimpleGrid cols={5}>
            {subSymbolsData.map((x, idx) => {
              return <KanjiSymbolCard key={idx} data={x as any} />;
            })}
          </SimpleGrid>
        </Box>
      )}

      {!_.isEmpty(variantsData) && (
        <Box mt={10}>
          <SimpleGrid cols={1} spacing={0} mb={20}>
            <Text className={classes.title}>Usage and Variants</Text>
            <Text className={classes.caption}>
              This element is also a part of all of these symbols (kanji)
            </Text>
          </SimpleGrid>
          <SimpleGrid cols={5}>
            {variantsData.map((x, idx) => {
              return <KanjiSymbolCard key={idx} data={x as any} variant />;
            })}
          </SimpleGrid>
        </Box>
      )}
    </SimpleGrid>
  );
};

export default KanjiSymbols;
