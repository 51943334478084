import { Container, Pagination, SimpleGrid } from "@mantine/core";
import KanjiTile from "components/tile/KanjiTile";
import { useAtom } from "jotai";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import kanjiIndexAtom from "state/jotai/kanji-index";

const PAGE_SIZE = 35;
interface VocabularyKanjiIndexProps {
  gems: string[];
  page: number;
}

const VocabularyKanjiIndex = ({ gems, page }: VocabularyKanjiIndexProps) => {
  const [kanjiIndex] = useAtom(kanjiIndexAtom);
  const [, setSearchParams] = useSearchParams();
  const [activePage, setPage] = useState(page);

  useEffect(() => {
    setPage(page);
  }, [page]);

  const onPageChange = (pageIndex: number) => {
    setSearchParams(`?page=${pageIndex}`);
    setPage(pageIndex);
  };

  const totalPages = useMemo(() => {
    return _.chain(gems).chunk(PAGE_SIZE).size().value();
  }, [gems]);

  const displayGems = useMemo(() => {
    return _.chain(gems)
      .chunk(PAGE_SIZE)
      .nth(activePage < totalPages ? activePage - 1 : totalPages - 1)
      .value();
  }, [activePage, totalPages, gems]);

  return (
    <Container mt={10}>
      {totalPages !== 0 && (
        <Pagination
          mb={30}
          withEdges
          noWrap
          color={"violet"}
          boundaries={5}
          position="center"
          page={activePage}
          onChange={onPageChange}
          total={totalPages}
          size="md"
          styles={{
            item: {
              fontSize: 12,
            },
            active: {
              fontWeight: "bold",
            },
          }}
        />
      )}
      <SimpleGrid cols={7}>
        {displayGems &&
          displayGems.map((x, idx) => {
            const [kanji, reading] = x.split(":");
            return (
              <KanjiTile
                key={idx}
                kanji={kanji}
                reading={reading}
                highlight={kanjiIndex.includes(kanji)}
              />
            );
          })}
      </SimpleGrid>
    </Container>
  );
};

export default VocabularyKanjiIndex;
