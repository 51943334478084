import {
  Box,
  createStyles,
  Grid,
  Group,
  ScrollArea,
  SimpleGrid,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { IconCheck, IconPlus, IconPuzzle } from "@tabler/icons";
import {
  addVocabularyWord,
  removeVocabularyWord,
} from "database/actions/vocabulary";
import { Vocabulary } from "database/models/vocabulary";
import { Firestore } from "firebase/firestore";
import { DictionaryWord } from "framework/types/dictionary";
import { useAtomValue } from "jotai";
import _ from "lodash";
import React from "react";
import vocabularyListsAtom from "state/jotai/vocabulary-lists";
import WordCard from "./WordCard";

const useStyles = createStyles((theme) => ({
  widget: {
    border: `1px solid ${theme.colors.violet[2]}`,
  },

  form: {
    padding: theme.spacing.xs,
  },

  header: {
    padding: theme.spacing.sm,
    background: theme.fn.rgba(theme.colors.violet[1], 0.25),
  },

  item: {
    cursor: "pointer",
    marginBottom: 5,
    border: "1px solid",
    borderColor: "transparent",
    borderRadius: theme.radius.md,
    "&:hover": {
      background: theme.fn.rgba(theme.colors.violet[1], 0.3),
    },
  },

  itemSelected: {
    borderColor: theme.fn.rgba(theme.colors.green[6], 0.35),
    background: theme.fn.rgba(theme.colors.green[1], 0.3),
    "&:hover": {
      background: theme.fn.rgba(theme.colors.green[2], 0.3),
    },
  },

  actionIcon: {
    cursor: "pointer",
    marginTop: 2,
  },

  icon: {
    height: 20,
  },
}));

interface VocabularyWordFormProps {
  word: DictionaryWord;
  firestore: Firestore;
}

const VocabularyWordForm = ({ word, firestore }: VocabularyWordFormProps) => {
  const { classes, cx } = useStyles();
  const vocabularyLists = useAtomValue(vocabularyListsAtom);

  const toggleVocabularyWord = (vocabulary: Vocabulary) => {
    if (vocabulary.words.includes(word.reading)) {
      removeVocabularyWord(firestore, {
        id: vocabulary.id,
        word: word.reading,
      });
    } else {
      addVocabularyWord(firestore, {
        id: vocabulary.id,
        word: word.reading,
      });
    }
  };

  return (
    <SimpleGrid spacing={0} cols={1} className={classes.widget}>
      <Box className={classes.header}>
        <Group>
          <IconPuzzle size={38} style={{ color: "gray" }} />
          <Box>
            <Title order={5}>Vocabulary Word</Title>
            <Text style={{ fontSize: 11, color: "gray" }}>
              Add or remove word from your vocabulary lists
            </Text>
          </Box>
        </Group>
      </Box>
      <Box p={10} pb={0}>
        <WordCard word={word} maxMeanings={5} noLink />
      </Box>
      <Box className={classes.form}>
        <ScrollArea
          style={{ height: 350 }}
          offsetScrollbars
          scrollbarSize={1}
          scrollHideDelay={0}
        >
          {_.chain(vocabularyLists)
            .orderBy((x) => x.title, ["asc"])
            .value()
            .map((x, idx) => {
              const selected = x.words.includes(word.reading);
              return (
                <Grid
                  className={cx(classes.item, {
                    [classes.itemSelected]: selected,
                  })}
                  key={idx}
                  columns={12}
                  mt={5}
                  mx={5}
                  onClick={() => toggleVocabularyWord(x)}
                >
                  <Grid.Col span={11}>
                    <Text size="xs" lineClamp={1} p={2} mt={2}>
                      {x.title}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={1}>
                    {selected && (
                      <ThemeIcon
                        className={classes.actionIcon}
                        color="green"
                        size={24}
                        radius="xl"
                      >
                        <IconCheck className={classes.icon} />
                      </ThemeIcon>
                    )}
                    {!selected && (
                      <ThemeIcon
                        className={classes.actionIcon}
                        color="violet"
                        size={24}
                        radius="xl"
                      >
                        <IconPlus className={classes.icon} />
                      </ThemeIcon>
                    )}
                  </Grid.Col>
                </Grid>
              );
            })}
        </ScrollArea>
      </Box>
    </SimpleGrid>
  );
};

export default VocabularyWordForm;
