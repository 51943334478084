import { Container, Group, Pagination } from "@mantine/core";
import KanjiTile from "components/tile/KanjiTile";
import { useAtom } from "jotai";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import kanjiIndexAtom from "state/jotai/kanji-index";

interface KanjiIndexProps {
  kanjis: string[];
  page: number;
}

const PAGE_SIZE = 50;

const KanjiIndex = ({ kanjis, page }: KanjiIndexProps) => {
  const [kanjiIndex] = useAtom(kanjiIndexAtom);
  const [, setSearchParams] = useSearchParams();
  const [activePage, setPage] = useState(page);

  useEffect(() => {
    setPage(page);
  }, [page]);

  const onPageChange = (pageIndex: number) => {
    setSearchParams(`?page=${pageIndex}`);
    setPage(pageIndex);
  };

  const totalPages = useMemo(() => {
    return _.chain(kanjis).chunk(PAGE_SIZE).size().value();
  }, [kanjis]);

  const displayKanjis = useMemo(() => {
    return _.chain(kanjis)
      .chunk(PAGE_SIZE)
      .nth(activePage < totalPages ? activePage - 1 : totalPages - 1)
      .value();
  }, [activePage, totalPages, kanjis]);

  return (
    <Container mt={20}>
      <Pagination
        mb={30}
        withEdges
        noWrap
        color={"violet"}
        boundaries={5}
        position="center"
        page={activePage}
        onChange={onPageChange}
        total={totalPages}
        size="md"
        styles={{
          item: {
            fontSize: 12,
          },
          active: {
            fontWeight: "bold",
          },
        }}
      />
      <Group spacing="sm">
        {displayKanjis.map((x, idx) => {
          return (
            <KanjiTile key={idx} kanji={x} highlight={kanjiIndex.includes(x)} />
          );
        })}
      </Group>
    </Container>
  );
};

export default KanjiIndex;
