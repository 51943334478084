import { PageViewLogger } from "components/analytics/PageViewLogger";
import Firebase from "components/firebase/Firebase";
import RequireAuth from "components/routing/RequireAuth";
import { App as SplashApp } from "features/splash/components";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout";

const App = () => {
  return (
    <Firebase>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SplashApp />} />
          <Route
            path="/*"
            element={
              <RequireAuth redirectTo="/">
                <Layout />
              </RequireAuth>
            }
          />
        </Routes>
        <PageViewLogger />
      </BrowserRouter>
    </Firebase>
  );
};

export default App;
