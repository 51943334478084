import {
  Button,
  Center,
  Container,
  createStyles,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { IconArrowRight, IconBrandGoogle } from "@tabler/icons";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import React from "react";
import { Link } from "react-router-dom";
import { useSigninCheck } from "reactfire";

const heroStyles = createStyles((theme) => ({
  content: {
    height: 600,
  },
}));

const infoStyles = createStyles((theme) => ({
  content: {
    height: 100,
  },
}));

const Splash = () => {
  const { classes: hero } = heroStyles();
  const { classes: info } = infoStyles();

  const auth = getAuth();
  const { data: signInCheckResult } = useSigninCheck();

  const provider = new GoogleAuthProvider();

  const signIn = async () => {
    await signInWithPopup(auth, provider);
  };

  const Hero = (
    <Container>
      <Center className={hero.content}>
        <SimpleGrid cols={1} spacing="xs">
          <Text
            align="center"
            variant="gradient"
            gradient={{ from: "indigo", to: "cyan", deg: 45 }}
            style={{
              fontFamily: "tbudrgothic-std",
              fontSize: 100,
              fontWeight: "bold",
            }}
          >
            漢字様
          </Text>
        </SimpleGrid>
      </Center>
    </Container>
  );

  const Info = (
    <Container>
      <Center className={info.content}>
        <SimpleGrid cols={1} spacing="xl">
          <Text
            style={{
              fontFamily: "greycliff-cf",
              fontSize: 40,
              fontWeight: "bold",
            }}
          >
            Welcome to Kanji Sama! 👋
          </Text>
          {signInCheckResult?.signedIn && (
            <Center>
              <Button
                leftIcon={<IconArrowRight />}
                size="lg"
                variant="gradient"
                gradient={{ from: "indigo", to: "cyan" }}
                component={Link}
                to="/dashboard"
              >
                Let's go!
              </Button>
            </Center>
          )}
          {!signInCheckResult?.signedIn && (
            <Center>
              <Button
                leftIcon={<IconBrandGoogle />}
                size="md"
                style={{
                  fontFamily: "tbudrgothic-std",
                }}
                onClick={signIn}
              >
                グーグルでログイン
              </Button>
            </Center>
          )}
        </SimpleGrid>
      </Center>
    </Container>
  );

  return (
    <Container>
      {Hero}
      {Info}
    </Container>
  );
};

export default Splash;
