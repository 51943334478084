import { Token } from "framework/types/sentence";
import { fit } from "furigana";
import { Furigana } from "gem-furigana";
import _ from "lodash";
import { isKana, toHiragana } from "wanakana";
import { hasKanji } from "./japanese";

const hasFurigana = (token: Token) => {
  const hasReading = !_.isUndefined(token.reading);
  const hasKana = isKana(token.reading);
  return hasReading && hasKana && hasKanji(token.surface);
};

const getFurigana = (token: Token) => {
  return new Furigana(fit(token.surface, toHiragana(token.reading)));
};

export { hasFurigana, getFurigana };
