import { Container, Grid, Title } from "@mantine/core";
import WordTile from "components/vocabulary/WordTile";
import React from "react";

const TilesLab = () => {
  const words = ["会話の組", "犯罪者", "一面", "切っ掛け", "決まり", "人込み"];

  return (
    <Container>
      <Title
        my={15}
        order={6}
        sx={(theme) => ({
          color: theme.colors.violet[8],
          textDecoration: "underline",
        })}
      >
        Word Tiles
      </Title>
      <Grid>
        {words.map((x, idx) => (
          <Grid.Col key={idx}>
            <WordTile expression={x} />
          </Grid.Col>
        ))}
      </Grid>
    </Container>
  );
};

export default TilesLab;
