import {
  Box,
  Button,
  Center,
  Container,
  createStyles,
  Divider,
  Group,
  Highlight,
  SimpleGrid,
  Text,
  Title,
} from "@mantine/core";
import { IconLanguage, IconRefresh } from "@tabler/icons";
import SentenceMarkup from "components/markup/sentence/SentenceMarkup";
import TokenSentence from "components/sentence/TokenSentence";
import { TokenizeResult } from "framework/types/sentence";
import { massifSentences } from "libs/massif";
import { tokenize } from "libs/sentence";
import { translate, TranslationResult } from "libs/translate";
import _ from "lodash";
import { useCallback, useState } from "react";
import { db, Sentence } from "state/dexie/db";

const massifQuery = "解答";

const useStyles = createStyles((theme) => ({
  sentence: {
    fontSize: 20,
    fontWeight: 800,
    fontFamily: "a-otf-ud-shin-maru-go-pr6n",
    color: theme.colors.gray[7],
    textAlign: "center",
  },
  meaning: {
    fontSize: 12,
    fontWeight: 800,
    color: theme.colors.gray[7],
    textAlign: "center",
  },
}));

const SentencesLab = () => {
  const { classes } = useStyles();
  const [sentences, setSentences] = useState<Sentence[]>([]);
  const [tokenizeResult, setTokenizeResult] = useState<TokenizeResult>();
  const [tokenizeResults, setTokenizeResults] = useState<
    [TokenizeResult, TranslationResult][]
  >([]);

  const refreshSentences = useCallback(() => {
    (async () => {
      const vocabulary = await db.vocabulary
        .where("tags")
        .anyOf(["n4", "n5"])
        .toArray();

      const words = _.chain(vocabulary).map("expression").value();

      const results = await db.sentences
        .where("type")
        .equalsIgnoreCase("cloze")
        .and((x) => !_.isEmpty(x.words))
        .and((x) => _.isEmpty(_.difference(x.words, words)))
        .toArray();

      setSentences(_.chain(results).shuffle().take(10).value());
    })();
  }, [setSentences]);

  const tokenizeSentence = async () => {
    const sentence =
      "約束を忘れないでください。右のポケットにハンカチが入っています。";
    const tokens = await tokenize(sentence);
    setTokenizeResult(tokens);
  };

  const searchMassif = async () => {
    const massifResults = await massifSentences(massifQuery);
    const selectedSentences = _.take(massifResults, 10);
    const tokenizedSentences = await Promise.all(
      _.map(selectedSentences, (x) =>
        Promise.all([tokenize(x.text), translate(x.text)])
      )
    );
    setTokenizeResults(tokenizedSentences);
  };

  return (
    <Container>
      <Title
        my={15}
        order={6}
        sx={(theme) => ({
          color: theme.colors.violet[8],
          textDecoration: "underline",
        })}
      >
        Sentences Query
      </Title>
      <SimpleGrid my={15}>
        <Group>
          <Button
            onClick={refreshSentences}
            leftIcon={<IconRefresh />}
            size="xs"
            variant="light"
          >
            Refresh Sentences
          </Button>
          <Button
            onClick={tokenizeSentence}
            leftIcon={<IconRefresh />}
            size="xs"
            variant="light"
            color="green"
          >
            Tokenize Sentence
          </Button>
          <Button
            onClick={searchMassif}
            leftIcon={<IconLanguage />}
            size="xs"
            variant="light"
            color="violet"
          >
            Massif Sentence
          </Button>
        </Group>
      </SimpleGrid>
      <SimpleGrid my={30}>
        <Box>
          {tokenizeResult && (
            <>
              <SentenceMarkup
                sentence={tokenizeResult.sentence}
                tokens={tokenizeResult.data.tokens}
                style={{ fontSize: 20 }}
                highlight={["忘れる", "約束"]}
                show={["入る"]}
                cloze={["右"]}
                hide={["約束"]}
                hideFurigana={false}
              />
              <Divider my={25} />
            </>
          )}
        </Box>
        {tokenizeResults && (
          <>
            {tokenizeResults.map(([x, y], idx) => {
              const translation = _.chain(y.data.translations)
                .first()
                .get("text")
                .value();

              return (
                <Box key={idx}>
                  <Center>
                    <SentenceMarkup
                      sentence={x.sentence}
                      tokens={x.data.tokens}
                      style={{ fontSize: 20 }}
                      highlight={[massifQuery]}
                      hideFurigana={false}
                    />
                  </Center>
                  <Center>
                    <Text className={classes.meaning}>{translation}</Text>
                  </Center>
                </Box>
              );
            })}
          </>
        )}
        {sentences.map((x, idx) => {
          return (
            <Box key={idx}>
              <Center>
                <TokenSentence
                  style={{ fontSize: 20 }}
                  sentence={x.expression}
                  reading={x.reading}
                />
              </Center>
              <Highlight
                mt={5}
                highlight={x.words}
                className={classes.sentence}
              >
                {x.expression}
              </Highlight>
              <Text className={classes.meaning}>{x.meaning}</Text>
            </Box>
          );
        })}
      </SimpleGrid>
    </Container>
  );
};

export default SentencesLab;
