import { collection, DocumentData, query, where } from "firebase/firestore";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useAuth, useFirestore, useFirestoreCollectionData } from "reactfire";

const useFirestoreVocabularyLists = () => {
  const auth = useAuth();
  const firestore = useFirestore();
  const [entries, setEntries] = useState<DocumentData[]>();

  const vocabularyCollection = collection(firestore, "vocabulary");
  const vocabularyQuery = query(
    vocabularyCollection,
    where(`roles.${auth.currentUser?.uid}`, "==", "owner")
  );

  const { data } = useFirestoreCollectionData(vocabularyQuery, {
    idField: "id",
  });

  useEffect(() => {
    setEntries(_.chain(data).value());
  }, [data]);

  return { data: entries };
};

export default useFirestoreVocabularyLists;
