import { Center, Container, Loader, SimpleGrid, Text } from "@mantine/core";
import React from "react";

interface LoadingScreenProps {
  height?: string | number;
  width?: string | number;
}

const LoadingScreen = ({ height, width }: LoadingScreenProps) => {
  return (
    <Container>
      <Center style={{ height: height || 300, width: width || "100%" }}>
        <SimpleGrid cols={1}>
          <Center>
            <Loader size="xl" variant="bars" />
          </Center>
          <Text>Loading, please wait...</Text>
        </SimpleGrid>
      </Center>
    </Container>
  );
};

export default LoadingScreen;
