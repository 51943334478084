import {
  ActionIcon,
  Box,
  Container,
  Pagination,
  SimpleGrid,
} from "@mantine/core";
import { IconTrash } from "@tabler/icons";
import VocabularyBar from "components/vocabulary/VocabularyBar";
import WordCard from "components/vocabulary/WordCard";
import { removeVocabularyWord } from "database/actions/vocabulary";
import { Vocabulary } from "database/models/vocabulary";
import { DictionaryWord } from "framework/types/dictionary";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useFirestore } from "reactfire";

const PAGE_SIZE = 12;

interface VocabularyWordsProps {
  vocabulary: Vocabulary;
  words: DictionaryWord[];
  page: number;
}

const VocabularyWords = ({ words, vocabulary, page }: VocabularyWordsProps) => {
  const firestore = useFirestore();
  const [, setSearchParams] = useSearchParams();
  const [activePage, setPage] = useState(page);

  useEffect(() => {
    setPage(page);
  }, [page]);

  const onPageChange = (pageIndex: number) => {
    setSearchParams(`?page=${pageIndex}`);
    setPage(pageIndex);
  };

  const totalPages = useMemo(() => {
    return _.chain(words).chunk(PAGE_SIZE).size().value();
  }, [words]);

  const displayWords = useMemo(() => {
    return _.chain(words)
      .chunk(PAGE_SIZE)
      .nth(activePage < totalPages ? activePage - 1 : totalPages - 1)
      .value();
  }, [activePage, totalPages, words]);

  return (
    <Container mt={20}>
      {totalPages !== 0 && (
        <Pagination
          mb={30}
          withEdges
          noWrap
          color={"violet"}
          boundaries={5}
          position="center"
          page={activePage}
          onChange={onPageChange}
          total={totalPages}
          size="md"
          styles={{
            item: {
              fontSize: 12,
            },
            active: {
              fontWeight: "bold",
            },
          }}
        />
      )}
      <SimpleGrid cols={3} mt={10}>
        {displayWords &&
          displayWords.map((x, idx) => (
            <Box key={idx} style={{ width: "100%" }}>
              <WordCard word={x} maxMeanings={5}>
                <VocabularyBar word={x} noAction>
                  <ActionIcon
                    color="red"
                    variant="hover"
                    onClick={() =>
                      removeVocabularyWord(firestore, {
                        id: vocabulary.id,
                        word: x.reading,
                      })
                    }
                  >
                    <IconTrash size={18} />
                  </ActionIcon>
                </VocabularyBar>
              </WordCard>
            </Box>
          ))}
      </SimpleGrid>
    </Container>
  );
};

export default VocabularyWords;
