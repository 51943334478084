import React from "react";
import { Route, Routes } from "react-router-dom";
import Kanji from "./Kanji";
import Keyword from "./Keyword";
import Layout from "./Layout";

const App = () => {
  return (
    <Routes>
      <Route path="/*" element={<Layout />} />
      <Route path="/info/:kanji/*" element={<Kanji />} />
      <Route path="/keyword/:keyword/*" element={<Keyword />} />
    </Routes>
  );
};

export default App;
