import {
  Box,
  Button,
  createStyles,
  Group,
  SimpleGrid,
  Text,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconPuzzle } from "@tabler/icons";
import _ from "lodash";
import React from "react";

const MAX_TITLE = 64;
const MAX_DESCRIPTION = 256;

const useStyles = createStyles((theme) => ({
  widget: {
    border: `1px solid ${theme.colors.violet[2]}`,
  },

  form: {
    padding: theme.spacing.md,
  },

  header: {
    padding: theme.spacing.sm,
    background: theme.fn.rgba(theme.colors.violet[1], 0.25),
  },
}));

interface VocabularyFormProps {
  id?: string;
  title?: string;
  description?: string;
  words?: string[];
  onSubmit: (value: any) => void;
  onCancel?: () => void;
}

const VocabularyForm = ({
  id,
  title,
  description,
  words,
  onSubmit,
  onCancel,
}: VocabularyFormProps) => {
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      id: id || undefined,
      title: title || "",
      description: description || "",
      words: words || [],
    },
    validate: {
      title: (value) =>
        _.isEmpty(_.trim(value))
          ? "Please enter a title"
          : _.size(value) > MAX_TITLE
          ? `Title cannot exceed ${MAX_TITLE} characters`
          : null,
      description: (value) =>
        _.isEmpty(_.trim(value))
          ? "Please enter a title"
          : _.size(value) > MAX_DESCRIPTION
          ? `Description cannot exceed ${MAX_DESCRIPTION} characters`
          : null,
    },
  });

  return (
    <SimpleGrid spacing={0} cols={1} className={classes.widget}>
      <Box className={classes.header}>
        <Group>
          <IconPuzzle size={38} style={{ color: "gray" }} />
          <Box>
            <Title order={5}>Vocabulary</Title>
            <Text style={{ fontSize: 11, color: "gray" }}>
              Manage Vocabulary List
            </Text>
          </Box>
        </Group>
      </Box>
      <Box className={classes.form}>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <TextInput
            size="xs"
            my={10}
            maxLength={MAX_TITLE}
            description={`Add a title for this Vocabulary List (Max ${MAX_TITLE} characters)`}
            placeholder="Ex. Words from 鬼滅の刃"
            label="Title"
            {...form.getInputProps("title")}
          ></TextInput>
          <Textarea
            size="xs"
            my={10}
            autosize
            minRows={2}
            maxLength={MAX_DESCRIPTION}
            description={`Add a decription for this Vocabulary List (Max ${MAX_DESCRIPTION} characters)`}
            placeholder="Ex. Words I want to learn from 鬼滅の刃"
            label="Description"
            {...form.getInputProps("description")}
          ></Textarea>

          <Group position="left" mt="md" pt={10} pb={5}>
            <Button color="violet" variant="outline" size="xs" type="submit">
              {_.isUndefined(form.values.id)
                ? "Create Vocabulary List"
                : "Edit Vocabulary List"}
            </Button>
            {!_.isUndefined(onCancel) && (
              <Button
                color="red"
                variant="outline"
                size="xs"
                onClick={onCancel}
              >
                Cancel
              </Button>
            )}
          </Group>
        </form>
      </Box>
    </SimpleGrid>
  );
};

export default VocabularyForm;
