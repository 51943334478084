import { Button, Container, Divider, Title } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { IconCirclePlus } from "@tabler/icons";
import SymbolForm from "components/symbol/forms/SymbolForm";
import { addSymbol } from "database/actions/symbol";
import { AddSymbolProps } from "database/models/symbol";
import _ from "lodash";
import React from "react";
import { useFirestore } from "reactfire";

const SymbolFormLab = () => {
  const modals = useModals();
  const firestore = useFirestore();

  const onSubmit = async (data: AddSymbolProps) => {
    const filtered: any = _.omitBy(data, _.isUndefined);
    await addSymbol(firestore, filtered);
  };

  // Modals
  const modalOptions = {
    size: 900,
    styles: {
      modal: {
        margin: 0,
        padding: `0 !important`,
      },
    },
    withCloseButton: false,
  };

  const openComponentModal = () => {
    modals.openModal({
      ...modalOptions,
      id: "add-component-modal",
      children: (
        <SymbolForm
          kanji="様"
          onSubmit={(data) => {
            modals.closeModal("add-component-modal");
            onSubmit(data);
          }}
          onCancel={() => modals.closeModal("add-component-modal", true)}
        />
      ),
    });
  };

  return (
    <Container>
      <Title
        my={15}
        order={6}
        sx={(theme) => ({
          color: theme.colors.violet[8],
          textDecoration: "underline",
        })}
      >
        Symbol Form
      </Title>
      <SymbolForm kanji="贅" onSubmit={onSubmit} />
      <Divider my={20} />
      <Title
        my={15}
        order={6}
        sx={(theme) => ({
          color: theme.colors.violet[8],
          textDecoration: "underline",
        })}
      >
        Symbol Form Modal
      </Title>

      <Button
        leftIcon={<IconCirclePlus />}
        size="xs"
        variant="light"
        onClick={openComponentModal}
      >
        Symbol
      </Button>
      <Divider my={20} />
    </Container>
  );
};

export default SymbolFormLab;
